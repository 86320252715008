import {SingleDataSetLineChart} from "./SingleLineChart";
import {CLOSE_ICON} from "../../Icons/FontAwesomeIcons";
import {Alert} from "@mantine/core";
import moment from "moment";
import {SingleDataSetBarChart} from "./SingleBarChart";
import {SingleDataSetBarLineChart} from "./SingleBarChartPlusLineChart";
import {calcNetProfit} from "../../Utils/Figures";

const convertDateToTotalPointMapToNivoDataSet = (calendarPeriod, dateToTotalPointMap) =>{
    /*
        label: "Last 30 days, by day",
                                    value: "last_30_days_by_day"
                                },
                                {
                                    label: "Last 3 months, by week",
                                    value: "last_3_months_by_week"
                                },
                                {
                                    label: "Last 12 months, by month",
                                    value: "last_12_months_by_month"
     */
    const dataArray = []
    if (dateToTotalPointMap) {
        for (let date of Object.keys(dateToTotalPointMap)) {
            const unformattedDate = date.split(" ")[0]

            let momentFormatted = moment(unformattedDate).format("D/M").toString()
            switch (calendarPeriod) {
                case "this_month_by_day":
                    momentFormatted = moment(unformattedDate).format("D/M").toString()
                    break;
                case "this_quarter_by_week":
                    momentFormatted = moment(unformattedDate).format("D/M").toString()
                    break;
                case "last_30_days_by_day":
                    momentFormatted = moment(unformattedDate).format("D/M").toString()
                    break;
                case "last_3_months_by_week":
                    momentFormatted = moment(unformattedDate).format("D/M").toString()
                    break;
                case "last_12_months_by_month":
                    //momentFormatted = moment(unformattedDate).format("D/M").toString()
                    momentFormatted = moment(unformattedDate).format("M/YY").toString()
                    break;
                case "this_year_by_month":
                    //momentFormatted = moment(unformattedDate).format("D/M").toString()
                    momentFormatted = moment(unformattedDate).format("M/YY").toString()
                    break;
            }

            dataArray.push({
                date: momentFormatted,
                actual_date: moment(unformattedDate),
                ...dateToTotalPointMap[date],
                TotalNetProfits: calcNetProfit(dateToTotalPointMap[date]),
            })
        }
    }
    return dataArray
}

export const SingleBarChartImpl = ({graphType, dataPoints, onDateSelect, settlementsOnly, height, dateToTotalPointMap: dateToStatistics, calendarPeriod}) => {
    const data = convertDateToTotalPointMapToNivoDataSet(calendarPeriod, dateToStatistics)

    return (
        <div style={{height: height}}>
            <SingleDataSetBarLineChart
                calendarPeriod={calendarPeriod}
                graphType={graphType}
                dataPoints={dataPoints}
                onDateSelect={onDateSelect}
                data={data}
                settlementsOnly={settlementsOnly}/>
        </div>
    )
}
