import {LoadingOverlay} from "@mantine/core";
import {Button, Text} from "@nextui-org/react";
import {
    ARROW_LEFT_ICON,
    ARROW_RIGHT_ICON_XS,
    CHECK_CIRCLE,
    CLOSE_CIRCLE_ICON, CLOSE_ICON
} from "../../../../Icons/FontAwesomeIcons";
import {InitialSelectPlan} from "../../../Modals/UpgradeAccount/InitialSelectPlan";
import {useContext, useState} from "react";
import {AppContext} from "../../../../Contexts/App";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../../Interceptors/Request";
import {useQueryClient} from "@tanstack/react-query";

export const SubscriptionSetup = () => {
    const {clearAppContext, subscription} = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const queryClient = useQueryClient()
    const handleError = (response) => {
        let message = "Unable to perform action";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const continueOnboarding = () => {
        setIsSaving(true)
        request({
            url: `/user/orchestrators/update-onboarding`,
            method: "POST",
            data: {
                status: "importing",
                state: ""
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['currentUser'] }).finally(() => {})
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    return (
        <>
            <div
                className="h-full flex items-center justify-center w-3/4 mx-auto"
                style={{minHeight: "100vh"}}>
                <LoadingOverlay visible={isSaving} />
                <form data-rewardful="true">

                </form>
                <div className="my-10 flex items-center items-stretch justify-center h-full w-full">
                    <div>
                        <img src="/assets/shopflow_logo.png" width={"100px"} className={"mb-4 block mx-auto"}/>
                        <Button
                            auto={true}
                            light
                            color={"default"}
                            icon={ARROW_LEFT_ICON}
                            onClick={() => clearAppContext()}
                            className={"block mx-auto mb-4"}
                        >
                            Logout
                        </Button>
                        <div className={"w-full text-center mb-10"}>
                            <Text className={"font-bold text-3xl"}>1. Choose a plan</Text>
                            <Text className={"text-md opacity-50"}>Each plan starts with a 14-day free trial. Cancel at any time.</Text>
                        </div>
                        <div className={"bg-white rounded-xl p-10 shadow"}>
                            {(!subscription || subscription && subscription.plan === "inactive") && (
                                <InitialSelectPlan />
                            )}
                            {!(!subscription || subscription && subscription.plan === "inactive") && (
                                <div>
                                    <div className={"p-20 text-center w-full"}>
                                        <div className={"mr-2 text-5xl text-green-500"}>{CHECK_CIRCLE}</div>
                                        <div className={"mt-4 text-3xl"}>
                                            Success!
                                        </div>
                                        <div className={"mt-4 opacity-50 text-sm"}>
                                            Your subscription is setup. Click "continue" to move to the next setup.
                                        </div>
                                    </div>
                                    <Button
                                        style={{width: "100%"}}
                                        size={"xl"}
                                        onClick={() => continueOnboarding()}
                                        color={"success"}
                                        type={"submit"}
                                        iconRight={ARROW_RIGHT_ICON_XS}>
                                        Continue
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}