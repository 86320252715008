import {useContext, useState} from "react";
import {useForm} from "@mantine/form";
import {LoadingOverlay, PasswordInput, TextInput} from "@mantine/core";
import {Button, Text} from "@nextui-org/react";
import {AppContext} from "../../../../Contexts/App";
import {
    ARROW_LEFT_ICON,
    ARROW_RIGHT_ICON_XS,
    CHECK_CIRCLE, CLOSE_CIRCLE_ICON,
    CLOSE_ICON,
    EMAIL_ICON,
    PASSWORD_ICON
} from "../../../../Icons/FontAwesomeIcons";
import {showNotification} from "@mantine/notifications";
import {requestEmpty} from "../../../../Interceptors/Request";
import {useQueryClient} from "@tanstack/react-query";

export const SetupLoginDetails = () => {
    const queryClient = useQueryClient()
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const {clearAppContext} = useContext(AppContext)

    const form = useForm({
        initialValues: {
            email: "",
            password: "",
            confirm_password: ""
        },

        validate: {
            email: (value) => (value && value !== "" ? null : "Please enter an email address."),
            password: (value) => (value && value !== "" ? null : "Please enter a password."),
            confirm_password: (value) => (value && value !== "" && value === form.values.password ? null : "Must match password entered above.")
        },
    });

    const handleError = (response) => {
        let message = "Unable to login";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const save = () => {
        setIsLoggingIn(true)
        requestEmpty({
            url: `/user/orchestrators/setup-account-details`,
            method: "POST",
            data: {
                email: form.values.email,
                password: form.values.password,
            }
        })
            .then((response) => {
                if (response && response.status === 200) {
                    const url = window.location.pathname
                    const callback = function () {
                        if (typeof(url) != 'undefined') {
                            window.location = url;
                        }
                    };
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-11360270010/RG4RCOjUj-gYELrt_6gq',
                        'transaction_id': '',
                        'event_callback': callback
                    });
                    showNotification({
                        title: "Great job!",
                        message: "You can now log in using the details you entered.",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    queryClient.invalidateQueries({queryKey: ['currentUser']}).finally(() => {
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoggingIn(false))
    }

    return (
        <>
            <LoadingOverlay visible={isLoggingIn}/>
            <form onSubmit={form.onSubmit((values) => save())}>
                <div
                    className="h-full flex items-center justify-center w-3/4 mx-auto"
                    style={{minHeight: "100vh"}}>
                    <div className="my-10 flex items-center items-stretch justify-center h-full w-full">
                        <div>
                            <img src="/assets/shopflow_logo.png" width={"100px"} className={"mb-4 block mx-auto"}/>
                            <Button
                                auto={true}
                                light
                                color={"default"}
                                icon={ARROW_LEFT_ICON}
                                onClick={() => clearAppContext()}
                                className={"block mx-auto mb-4"}
                            >
                                Back to login
                            </Button>
                            <div className={"w-full text-center mb-10"}>
                                <Text className={"font-bold text-3xl"}>Let's setup your login details</Text>
                                <Text className={"opacity-50 mt-2"}>You'll use these credentials every time you want to sign in.</Text>
                            </div>
                            <div className="bg-white shadow-3xl rounded-xl border w-full" style={{minWidth: "500px"}}>
                                <div className="p-10">
                                    <TextInput
                                        radius={"md"}
                                        icon={EMAIL_ICON}
                                        size={"xl"}
                                        label={"Email Address"}
                                        placeholder={"charlie@shop-flow.com"}
                                        {...form.getInputProps('email')}
                                    />
                                    <PasswordInput
                                        className={"mt-4"}
                                        radius={"md"}
                                        size={"xl"}
                                        icon={PASSWORD_ICON}
                                        label={"Password"}
                                        placeholder={"C!D<SD37"}
                                        {...form.getInputProps('password')}
                                    />
                                    <PasswordInput
                                        className={"mt-4"}
                                        radius={"md"}
                                        size={"xl"}
                                        icon={PASSWORD_ICON}
                                        label={"Confirm Password"}
                                        placeholder={"C!D<SD37"}
                                        {...form.getInputProps('confirm_password')}
                                    />
                                </div>
                                <Button
                                    style={{width: "100%"}}
                                    disabled={isLoggingIn}
                                    size={"xl"}
                                    color={"primary"}
                                    type={"submit"}
                                    iconRight={ARROW_RIGHT_ICON_XS}>
                                    Next
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}