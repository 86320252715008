import {useBulkRangeStats} from "../../../Hooks/Statistics";
import {useContext, useEffect, useState} from "react";
import {
    getCurrentMonthStartDate,
    getEndOfLastWeek,
    getEndOfYesterday, getMonthStartDate, getMonthAgoStartDate,
    getPreviousMonthDate, getStartOfLastWeek,
    getStartOfToday, getStartOfWeek,
    getStartOfYesterday,
    getTodayPlusOne,
    getYesterday, getThreeMonthsAgoDate, getYearAgoDate, getCurrentQuarterStartDate, getCurrentYearStartDate
} from "../../../Utils/Dates";
import {Checkbox, LoadingOverlay, MultiSelect, NumberInput, Select, Tabs} from "@mantine/core";
import {ProductList} from "../../Common/ProductList";
import {Button, Text} from "@nextui-org/react";
import {
    ARROW_RIGHT_ICON_XS, CLOCK_ICON,
    INFO_CIRCLE_ICON,
    LINK_ICON,
    SEARCH_ICON,
    SETTINGS_ICON, STORE_ICON
} from "../../../Icons/FontAwesomeIcons";
import {AppContext} from "../../../Contexts/App";
import {Link, useNavigate} from "react-router-dom";
import {useSettingItemsCache} from "../../../Hooks/SettingItems";
import {useTiktokShops} from "../../../Hooks/TiktokShops";
import {calendarOptions} from "../../../Constants/Constants";

export const orderStatuses =  [
    "UNPAID",
    "ON_HOLD",
    "AWAITING_SHIPMENT",
    "AWAITING_COLLECTION",
    "PARTIALLY_SHIPPING",
    "IN_TRANSIT",
    "DELIVERED",
    "COMPLETED",
    "CANCELLED"
]

const defaultDates = [
    {
        label: "Today",
        from: getStartOfToday(),
        to: getTodayPlusOne(),
        compareWith: 1
    },
    {
        label: "Yesterday",
        from: getStartOfYesterday(),
        to: getEndOfYesterday()
    },
    {
        label: "This Week",
        from: getStartOfWeek(),
        to: getTodayPlusOne(),
    },
    {
        label: "Last Month",
        from: getMonthAgoStartDate(),
        to: getMonthStartDate(),
    },
    {
        label: "This Month",
        from: getCurrentMonthStartDate(),
        to: getTodayPlusOne(),
        compareWith: 3,
    }
]

export const DashboardTilesSection = () => {
    const {settingItems} = useSettingItemsCache(["reduce_shipping_fee_by_percent", "subtract_shipping_in_gross_profits"])
    const {calendarPeriod, setCalendarPeriod, settlementsOnly, includeShippingAsCost, setIncludeShippingAsCost, shippingPercentage, setShippingPercentage} = useContext(AppContext)
    const {shops, isLoading: isLoadingShops} = useTiktokShops()
    const [selectedShopIds, setSelectedSelectedShopIds] = useState(null)
    const [from, setFrom] = useState(getPreviousMonthDate())
    const [to, setTo] = useState(getTodayPlusOne())

    const [dates, setDates] = useState(defaultDates)
    const {rawData, isLoading} = useBulkRangeStats(selectedShopIds, dates, orderStatuses, includeShippingAsCost, shippingPercentage)

    const navigate = useNavigate()

    const calculatePercentage = (base, comparison) => {
        if (base === 0 || comparison === 0) {
            return "0.00"
        }
        return (base / comparison * 100).toFixed(2)
    }

    const getPercentageChange = (compareIndex, currentIndex) => {
        const comparison = rawData[compareIndex].TotalSales - rawData[compareIndex].TotalSalesCancelled
        const base = rawData[currentIndex].TotalSales - rawData[currentIndex].TotalSalesCancelled

        if (comparison === 0 || base === 0) {
            return (
                <Text className={"opacity-25 text-xs"}>0.00 %</Text>
            )
        }

        const percentage = Math.abs(((comparison - base) / comparison) * 100);

        return (
            <>
                {base > comparison && (
                    <Text className={"opacity-50 text-xs"} color={"green"}>Up {percentage.toFixed(2)} %</Text>
                )}
                {base < comparison && (
                    <Text className={"opacity-50 text-xs"} color={"red"}>Down {percentage.toFixed(2)} %</Text>
                )}
            </>
        )
    }

    useEffect(() => {
        if (settingItems) {
            if (settingItems.subtract_shipping_in_gross_profits) {
                const value = settingItems.subtract_shipping_in_gross_profits.value === "false" ? false : true
                setIncludeShippingAsCost(value)
            }

            if (settingItems.reduce_shipping_fee_by_percent) {
                const value = Number(settingItems.reduce_shipping_fee_by_percent.value)
                setShippingPercentage(value)
            }
        }
    }, [settingItems])

    useEffect(() => {
        switch (calendarPeriod) {
            case "this_month_by_day":
                setFrom(getCurrentMonthStartDate())
                setTo(getTodayPlusOne())
                break
            case "this_quarter_by_week":
                setFrom(getCurrentQuarterStartDate())
                setTo(getTodayPlusOne())
                break
            case "last_30_days_by_day":
                setFrom(getPreviousMonthDate())
                setTo(getTodayPlusOne())
                break
            case "last_3_months_by_week":
                setFrom(getThreeMonthsAgoDate())
                setTo(getTodayPlusOne())
                break
            case "last_12_months_by_month":
                setFrom(getYearAgoDate())
                setTo(getTodayPlusOne())
                break
            case "this_year_by_month":
                setFrom(getCurrentYearStartDate())
                setTo(getTodayPlusOne())
                break
        }
    }, [calendarPeriod])

    return (
        <>
            <div className={"bg-white p-4 shadow-3xl border"}>
                <Text className={"mb-2 opacity-50 text-xs font-bold"}>
                    <span className={"mr-2"}>{SEARCH_ICON}</span>
                    REFINE
                </Text>
                <div className={"flex flex-wrap"}>
                    <div className={"lg:w-1/4 w-full lg:pl-4 pl-0"}>
                        <MultiSelect
                            placeholder={shops && shops.length === 1 ? shops[0].shop_name : "Select shop(s)"}
                            label={"Shops"}
                            radius={"md"}
                            icon={STORE_ICON}
                            disabled={shops && shops.length === 1 ? true : false}
                            value={selectedShopIds}
                            onChange={(values) => setSelectedSelectedShopIds(values)}
                            data={shops.map((shop) => ({label: shop.shop_name, value: shop.id}))} />
                    </div>
                    <div className={"lg:w-1/3 w-full lg:pl-4 pl-0"}>
                        {/*<MultiSelect*/}
                        {/*    placeholder={"Select order statuses"}*/}
                        {/*    label={"Status"}*/}
                        {/*    radius={"md"}*/}
                        {/*    icon={TICKET_ICON}*/}
                        {/*    clearable={true}*/}
                        {/*    value={selectedOrderStatuses}*/}
                        {/*    onChange={(values) => setSelectedOrderStatuses(values)}*/}
                        {/*    data={orderStatuses.map((item) => ({value: item, label: item.replaceAll("_", " ")}))} />*/}
                    </div>
                </div>
            </div>
            <div className={"bg-white shadow"}>
                <LoadingOverlay visible={isLoading} />
                <div className={"flex flex-wrap border-t"}>
                    <>
                        {defaultDates.map((dateItem, index) => (
                            <div key={index} className={"lg:w-1/5 w-full flex items-center"}>
                                <div className={"w-full pr-4 shadow pl-4 py-10"}>
                                    <div className={"border-b mb-4 flex"}>
                                        <div className={"lg:w-1/2 w-full flex items-center"}>
                                            <Text className={"opacity-50 text-uppercase"}>{dateItem.label}</Text>
                                        </div>
                                        <div className={"lg:w-1/2 w-full flex justify-end items-center"}>
                                            {rawData && rawData[index] && (
                                                <>
                                                    {dateItem.compareWith && (
                                                        <>
                                                            {getPercentageChange(dateItem.compareWith, index)}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className={"flex items-center w-full h-full"}>
                                        <div className={"w-full"}>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/3 flex items-center"}>
                                                    <Text b className={"text-sm"}>Net Sales</Text>
                                                </div>
                                                <div className={"w-2/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>£ {(rawData[index].TotalSales).toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/2 flex items-center"}>
                                                    <Text className={"text-xs"}>Sales</Text>
                                                </div>
                                                <div className={"w-1/2 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-xs"}>£ {(rawData[index].TotalSales + rawData[index].TotalSalesCancelled).toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/2 flex items-center"}>
                                                    <Text className={"text-xs"}>Cancelled</Text>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"ml-2 opacity-50 text-xs"}>({calculatePercentage(rawData[index].TotalSalesCancelled, (rawData[index].TotalSales + rawData[index].TotalSalesCancelled))}%)</Text>
                                                    )}
                                                </div>
                                                <div className={"w-1/2 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-xs"} color={"red"}>£ -{rawData[index].TotalSalesCancelled.toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-xs"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/3 flex items-center"}>
                                                    <Text b className={"text-sm"}>Net Units</Text>
                                                </div>
                                                <div className={"w-2/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>{rawData[index].TotalUnits - rawData[index].TotalUnitsCancelled}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>0</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/2 flex items-center"}>
                                                    <Text className={"text-xs"}>Units</Text>
                                                </div>
                                                <div className={"w-1/2 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-xs"}>{rawData[index].TotalUnits}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-xs"}>0</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/2 flex items-center"}>
                                                    <Text className={"text-xs"}>Cancelled</Text>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"ml-2 opacity-50 text-xs"}>({calculatePercentage(rawData[index].TotalUnitsCancelled, rawData[index].TotalUnits)}%)</Text>
                                                    )}
                                                </div>
                                                <div className={"w-1/2 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-xs"} color={"red"}>-{rawData[index].TotalUnitsCancelled}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>0</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/2"}>
                                                    <Text b className={"text-sm"}>Shipping</Text>
                                                    <br />
                                                    <Link className={"text-xs"} to={"/settings?tab=shipping"}><span className={"mr-1"}>{SETTINGS_ICON}</span> Configure</Link>
                                                </div>
                                                <div className={"w-1/2 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>£ {(rawData[index].TotalShippingFee).toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-2/3"}>
                                                    <Text b className={"text-sm"}>Affiliates</Text>
                                                    {!settlementsOnly && (
                                                        <div className={""}>
                                                            <Link className={"text-xs"} to={"/settings?tab=tiktok_affiliate_commissions"}><span className={"mr-1"}>{SETTINGS_ICON}</span> Configure</Link>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={"w-1/3 flex items-center justify-end"}>
                                                    <div>
                                                        {rawData && rawData[index] && (
                                                            <Text className={"opacity-75 text-sm text-right"}>£ {rawData[index].TotalAffiliateCommission.toFixed(2)}</Text>
                                                        )}
                                                        {(!rawData || rawData && !rawData[index]) && (
                                                            <Text className={"opacity-75 text-sm text-right"}>£ 0.00</Text>
                                                        )}
                                                        {!settlementsOnly && (
                                                            <div className={""}>
                                                                <Text className={"text-xs"} color={"orange"}>~ estimated</Text>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-2/3"}>
                                                    <Text b className={"text-sm"}>Platform Fee's</Text>
                                                    <br />
                                                    <Link className={"text-xs"} to={"/settings?tab=tiktok_platform_fees"}><span className={"mr-1"}>{SETTINGS_ICON}</span> Configure</Link>
                                                </div>
                                                <div className={"w-1/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>£ {rawData[index].TotalPlatformFee.toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>

                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/3 flex items-center"}>
                                                    <Text b className={"text-sm"}>VAT</Text>
                                                </div>
                                                <div className={"w-2/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>£ {(rawData[index].TotalVAT - (rawData[index].TotalShippingFee / 6) + (rawData[index].TotalPlatformFee / 6) + (rawData[index].TotalAffiliateCommission / 6)).toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/2 flex items-center"}>
                                                    <Text className={"text-xs"}>Sales</Text>
                                                </div>
                                                <div className={"w-1/2 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-xs"}>£ {(rawData[index].TotalVAT).toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-xs"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/2 flex flex-wrap items-center gap-4"}>
                                                    <Text className={"text-xs"}>Purchases</Text>
                                                    <Text className={"text-xs"} color={"orange"}>~ estimated</Text>
                                                </div>
                                                <div className={"w-1/2 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-xs"} color={"red"}>£ -{((rawData[index].TotalShippingFee / 6) + (rawData[index].TotalPlatformFee / 6) + (rawData[index].TotalAffiliateCommission / 6)).toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-xs"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>

                                            {/*<div className={"flex flex-wrap border-b pb-2 mb-2"}>*/}
                                            {/*    <div className={"w-1/2"}>*/}
                                            {/*        <Text b className={"text-sm"}>VAT</Text>*/}
                                            {/*        <br />*/}
                                            {/*        <Link className={"text-xs"} to={"/settings?tab=vat"}><span className={"mr-1"}>{SETTINGS_ICON}</span> Configure</Link>*/}
                                            {/*    </div>*/}
                                            {/*    <div className={"w-1/2 flex items-center justify-end"}>*/}
                                            {/*        {rawData && rawData[index] && (*/}
                                            {/*            <>*/}
                                            {/*                <Text className={"opacity-75 text-sm"}>£ {(rawData[index].TotalVAT).toFixed(2)}</Text>*/}
                                            {/*            </>*/}
                                            {/*        )}*/}
                                            {/*        {(!rawData || rawData && !rawData[index]) && (*/}
                                            {/*            <Text className={"opacity-75 text-sm"}>£ 0.00</Text>*/}
                                            {/*        )}*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className={"flex flex-wrap pb-2 mb-2"}>
                                                <div className={"w-1/3"}>
                                                    <Text b className={"text-sm"}>COGS</Text>
                                                </div>
                                                <div className={"w-2/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>£ {rawData[index].TotalCogs.toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/3 flex items-center"}>
                                                    <Text b className={"text-sm"}>
                                                        Gross Profit
                                                        <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                                    </Text>
                                                </div>
                                                <div className={"w-2/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>£ {rawData[index].TotalGrossProfits.toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/3 flex items-center"}>
                                                    <Text b
                                                          className={"text-sm cursor-pointer text-blue-500"}>
                                                        Expenses
                                                    </Text>
                                                </div>
                                                <div className={"w-2/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <>
                                                            <Text className={"opacity-75 text-sm"}>£ {(rawData[index].TotalExpenses).toFixed(2)}</Text>
                                                        </>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/3 flex items-center"}>
                                                    <Text b className={"text-sm"}>
                                                        Net Profit
                                                        <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                                    </Text>
                                                </div>
                                                <div className={"w-2/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>£ {rawData[index].TotalNetProfits.toFixed(2)}</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/3 flex items-center"}>
                                                    <Text b className={"text-sm"}>
                                                        Margin
                                                        <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                                    </Text>
                                                </div>
                                                <div className={"w-2/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>{((rawData[index].TotalNetProfits / (rawData[index].TotalSales ? rawData[index].TotalSales : 1)) * 100).toFixed(2)} %</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>0%</Text>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                                <div className={"w-1/3 flex items-center"}>
                                                    <Text b className={"text-sm"}>
                                                        ROI
                                                        <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                                    </Text>
                                                </div>
                                                <div className={"w-2/3 flex items-center justify-end"}>
                                                    {rawData && rawData[index] && (
                                                        <Text className={"opacity-75 text-sm"}>{rawData[index].TotalRoi.toFixed(2)} %</Text>
                                                    )}
                                                    {(!rawData || rawData && !rawData[index]) && (
                                                        <Text className={"opacity-75 text-sm"}>0%</Text>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </>
                </div>
            </div>
            <Tabs
                keepMounted={false}
                color="dark"
                radius={"md"}
                variant={"pills"}
                defaultValue="products"
            >
                <Tabs.List
                    className={"bg-white shadow-3xl border py-2 pl-2"}>
                    {/*<Text className={"opacity-50 text-center mr-2 flex justify-center items-center"}>*/}
                    {/*    <span className={"mr-2"}>{CLOCK_ICON}</span>*/}
                    {/*    All Time*/}
                    {/*</Text>*/}
                    <Tabs.Tab value="products" className="text-md"><span className={"mr-2"}>📦</span> Active Products</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel
                    value="products"
                    className={"bg-white"}>
                    <div className={"bg-white p-4 shadow-3xl border"}>
                        <Text className={"mb-2 opacity-50 text-xs font-bold"}>
                            <span className={"mr-2"}>{SEARCH_ICON}</span>
                            REFINE
                        </Text>
                        <div className={"flex flex-wrap"}>
                            <div className={"lg:w-1/5 w-full"}>
                                <Select
                                    label={"Calendar Period"}
                                    radius={"md"}
                                    icon={CLOCK_ICON}
                                    value={calendarPeriod}
                                    onChange={(period) => {
                                        setCalendarPeriod(period)
                                    }}
                                    data={calendarOptions} />
                            </div>
                        </div>
                    </div>
                    <ProductList
                        activeOnly={true}
                        showChart={false}
                        from={from}
                        to={to}
                        selectedShopIds={selectedShopIds}
                    />
                </Tabs.Panel>
            </Tabs>
        </>
    )
}