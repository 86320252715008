import {LoadingOverlay, MultiSelect, Tabs} from "@mantine/core";
import {Button, Text} from "@nextui-org/react";
import {
    CLOCK_ICON,
    DATABASE_ICON, PLUS_ICON,
    REFRESH_ICON,
    SEARCH_ICON,
    STORE_ICON,
    TICKET_ICON
} from "../../../Icons/FontAwesomeIcons";
import {ProductList} from "../../Common/ProductList";
import {ExpensesList} from "./ExpensesList";
import {ExpensesHistoryList} from "./ExpensesHistoryList";
import {CreateExpense} from "../../Modals/Expenses/CreateExpense";
import {UpdateExpense} from "../../Modals/Expenses/UpdateExpense";
import {useState} from "react";

export const Expenses = () => {
    const [openCreateExpense, setOpenCreateExpense] = useState(false)
    const [openUpdateExpense, setOpenUpdateExpense] = useState(false)
    const [updatingExpense, setUpdatingExpense] = useState(null)

    return (
        <>
            {openCreateExpense && (
                <CreateExpense open={openCreateExpense} setOpen={setOpenCreateExpense} />
            )}
            {openUpdateExpense && updatingExpense && (
                <UpdateExpense open={openUpdateExpense} setOpen={setOpenUpdateExpense} expense={updatingExpense} />
            )}
            <div className={"bg-white p-4 shadow-3xl border-b border-t flex"}>
                <div className={"lg:w-1/2 w-full"}>
                    <div className={"flex items-center gap-4"}>
                        <div className={"text-xl font-bold"}>
                            <span className={"mr-2"}>{TICKET_ICON}</span>
                            Expenses
                        </div>
                    </div>
                    <Text className={"text-xs opacity-50"}>
                        Manage in-direct costs associated with your shop.
                    </Text>
                </div>
                <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                    <Button
                        auto={true}
                        color={"success"}
                        icon={PLUS_ICON}
                        disabled={openCreateExpense}
                        onClick={() => setOpenCreateExpense(true)}
                    >Add Expense</Button>
                </div>
            </div>
            <Tabs
                keepMounted={false}
                color="dark"
                radius={"md"}
                variant={"pills"}
                defaultValue="setup">
                <Tabs.List className={"bg-white shadow-3xl border py-2 pl-2"}>
                    <Tabs.Tab value="setup" className="text-md"><span className={"mr-2"}>📅️</span> Setup Expenses</Tabs.Tab>
                    <Tabs.Tab value="history" className="text-md"><span className={"mr-2"}>📚</span> Expense History</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel
                    value="setup">
                    <ExpensesList
                        setUpdatingExpense={setUpdatingExpense}
                        setOpenCreateExpense={setOpenCreateExpense}
                        setOpenUpdateExpense={setOpenUpdateExpense}
                    />
                </Tabs.Panel>

                <Tabs.Panel
                    value="history">
                    <ExpensesHistoryList
                        setUpdatingExpense={setUpdatingExpense}
                        setOpenCreateExpense={setOpenCreateExpense}
                        setOpenUpdateExpense={setOpenUpdateExpense}
                    />
                </Tabs.Panel>
            </Tabs>
        </>
    )
}