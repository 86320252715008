import {Alert, LoadingOverlay} from "@mantine/core";
import {
    ALERT_ICON,
    ARROW_RIGHT_ICON_XS,
    CHECK_CIRCLE,
    CHECKMARK_ICON,
    CLOSE_ICON,
    LINK_ICON
} from "../../Icons/FontAwesomeIcons";
import {Button, Text} from "@nextui-org/react";
import {useNotifications} from "../../Hooks/Notifications";
import {useNavigate} from "react-router-dom";
import {showNotification} from "@mantine/notifications";
import {request} from "../../Interceptors/Request";
import {useContext, useState} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {AppContext} from "../../Contexts/App";
import moment from "moment/moment";

export const NewNotifications = () => {
    const queryClient = useQueryClient()
    const {clearAppContext} = useContext(AppContext)
    const {notifications, isLoading: isLoadingNotifications} = useNotifications(false)
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)

    const handleError = (response) => {
        let message = "Unable to acknowledge notification";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const acknowledge = (notificationId) => {
        setIsSaving(true)
        request({
            url: `/notifications/orchestrators/acknowledge?notificationId=${notificationId}`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['getNotifications'] }).finally(() => {})
                    showNotification({
                        title: "Acknowledged!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    return (
        <>
            <LoadingOverlay visible={isSaving || isLoadingNotifications} />
            {notifications && notifications.length > 0 && (
                <>
                    <Alert
                        className={"shadow"}
                        color="orange"
                    >
                        <div className={"flex items-center w-full pr-10"}>
                            <div>
                                <div className={"flex"}>
                                    <div className={"text-yellow-500 mr-4"}>{ALERT_ICON}</div>
                                    <div>
                                        <Text>You have {notifications.length} new notifications, please read them and acknowledge them to dismiss.</Text>
                                        <Button
                                            className={"mt-4"}
                                            auto={true}
                                            color={"warning"}
                                            onClick={() => {
                                                navigate("/notifications?exclude_acknowledged=true")
                                            }}
                                        >
                                            Notifications
                                            <span className={"ml-2"}>{ARROW_RIGHT_ICON_XS}</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Alert>
                </>
            )}
        </>
    )
}