import {useContext} from "react";
import {AppContext} from "../Contexts/App";
import {useQuery} from "@tanstack/react-query";
import {request} from "../Interceptors/Request";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";

export const useSettingItems = (keys) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['getSettingItemsByKeys', user, keys],
        () => request({
            url: `/setting-items/orchestrators/retrieve-bulk?keys=${keys.join(",")}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: 1}
    )

    return {
        settingItems: data ? data.data : [],
        isLoading,
        error
    }
}

export const useSettingItemsCache = (keys) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['getSettingItemsByKeysCache', user, keys],
        () => request({
            url: `/setting-items/orchestrators/retrieve-bulk?keys=${keys.join(",")}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )

    return {
        settingItems: data ? data.data : [],
        isLoading,
        error
    }
}