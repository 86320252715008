import {showNotification} from "@mantine/notifications";
import {CHECK_CIRCLE, CLOSE_ICON, QUESTION_MARK_ICON} from "../../Icons/FontAwesomeIcons";
import {request} from "../../Interceptors/Request";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../Contexts/App";
import {useQueryClient} from "@tanstack/react-query";
import {Switch, Tooltip} from "@nextui-org/react";
import {LoadingOverlay, Text} from "@mantine/core";
import {useCurrentUser} from "../../Hooks/User";

export const SettledModeToggle = () => {
    const queryClient = useQueryClient()
    const {isLoading: isLoadingUser, userAccount} = useCurrentUser()
    const {user, clearAppContext, settlementsOnly: settlementMode} = useContext(AppContext)
    const [updating, setUpdating] = useState(false)

    const handleError = (response) => {
        let message = "Unable to switch mode";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const updateSettledMode = (newValue) => {
        setUpdating(true)
        request({
            url: `/user/orchestrators/update-settled-mode?value=${newValue}`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    localStorage.setItem("SHOPA-settled-mode", newValue)
                    setTimeout(() => {
                        window.location.reload()
                        setUpdating(false)
                    }, 2000)
                    return
                }

                handleError(response)
                setUpdating(false)
            })
            .catch((error) => {
                if (error) handleError()
                setUpdating(false)
            })
    }

    return (
        <>
            <LoadingOverlay visible={updating || isLoadingUser} />
            <div>
                <Tooltip placement="right" color="primary"
                         content={"Use this 'switch' to toggle between completed/settled order data, and real-time order data."}>
                    <Text className={"mb-2 opacity-50 text-xs"}>
                        <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                        What is this?
                    </Text>
                </Tooltip>
                <div className={(settlementMode ? "bg-green-50 " : "bg-blue-50 ") + "flex items-center p-4 rounded-xl shadow"}>
                    <Switch
                        disabled={updating}
                        size={"lg"}
                        label={"test"}
                        shadow
                        color="success"
                        checked={settlementMode}
                        onChange={(value) => {
                            updateSettledMode(value.target.checked)
                        }}/>
                    {settlementMode && (
                        <div>
                            <Text className={"ml-4 text-xs opacity-25"}>CURRENT</Text>
                            <Text className={"ml-4 text-sm opacity-50 font-bold"}>Completed Orders</Text>
                        </div>
                    )}
                    {!settlementMode && (
                        <div>
                            <Text className={"ml-4 text-xs opacity-25"}>CURRENT</Text>
                            <Text className={"ml-4 text-sm opacity-50 font-bold"}>Live Orders</Text>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}