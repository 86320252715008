import {Button, Divider, Table} from "@nextui-org/react";
import {
    CHECK_CIRCLE,
    CHECKMARK_ICON,
    CLOSE_CIRCLE_ICON, CLOSE_ICON,
    EDIT_ICON_SMALL, INFO_CIRCLE_ICON, INFO_ICON,
    LINK_ICON,
    PLUS_ICON, TRASH_ICON, WARNING_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Alert, LoadingOverlay, Modal, NumberInput, Text} from "@mantine/core";
import {useCogsByProductId, useCogsValidate, usePresentCogsByProductId} from "../../../Hooks/TiktokProductCogs";
import {useContext, useEffect, useState} from "react";
import {CreateEditCostOfGoods, CreateEditShippingChargesV2} from "./CreateEditShippingChargesV2";
import moment from "moment/moment";
import {request} from "../../../Interceptors/Request";
import {showNotification} from "@mantine/notifications";
import {useQueryClient} from "@tanstack/react-query";
import {AppContext} from "../../../Contexts/App";
import {
    usePresentShippingChargesByProductId,
    useShippingChargesByProductId,
    useShippingChargesValidate
} from "../../../Hooks/TiktokProductShippingCharge";

const explainData = (data) =>  {
    if (!data || data && data.length === 0) {
        return []
    }
    // Sort the data by the "from" date
    data.sort((a, b) => new Date(a.from) - new Date(b.from));

    let explanations = [];
    let presentData;

    for (let item of data) {
        if (item.present) {
            presentData = item;
            break;
        }
    }

    let currentDate = new Date(data[0].from);
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let startDate = new Date(item.from);
        let endDate = item.to ? new Date(item.to) : null;

        if (item.present && i == 0) {
            // Skip the "onwards" explanation for the first record if it's the "present" record
            currentDate = endDate ? new Date(endDate) : new Date(startDate);
            currentDate.setDate(currentDate.getDate() + 1);
            continue;
        }

        // Check for gaps between the previous date and the current start date
        if (currentDate < startDate) {
            let gapEndDate = new Date(startDate);
            gapEndDate.setDate(gapEndDate.getDate() - 1);

            if (presentData) {
                explanations.push(
                    (
                        <>
                            Between <b>{currentDate.toISOString().split('T')[0]} and {gapEndDate.toISOString().split('T')[0]}</b>, the total shipping fee per unit is <b>£{presentData.total_shipping_charge.toFixed(2)}</b>.
                        </>
                    )
                );
            }
        }

        if (item) {
            explanations.push(
                (
                    <>
                        From <b>{startDate.toISOString().split('T')[0]}</b> <b>{endDate ? ('to ' + endDate.toISOString().split('T')[0]) : 'onwards'}</b>, the total shipping fee per unit is <b>£{item.total_shipping_charge.toFixed(2)}</b>.
                    </>
                )
            );
        }

        currentDate = endDate ? new Date(endDate) : new Date(startDate);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    if (currentDate) {
        if (presentData) {
            explanations.push((
                <>
                    From <b>{currentDate.toISOString().split('T')[0]}</b> onwards, the total shipping fee per unit is <b>£{presentData.total_shipping_charge.toFixed(2)}</b>.
                </>
            ));
        }
    }

    return explanations;
}

export const EditShippingChargesByProductIdV2 = ({disableHeader, product}) => {
    const {clearAppContext} = useContext(AppContext)
    const queryClient = useQueryClient()
    const {shippingCharges, isLoading: isLoadingShippingCharges} = useShippingChargesByProductId(product)
    const {issues, isLoading: isLoadingValidation} = useShippingChargesValidate(product)
    const [showNewShippingCharges, setShowNewShippingCharges] = useState(false)
    const [editingShippingCharges, setEditingShippingCharges] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)

    const handleError = (response) => {
        let message = "Unable to delete shipping charge";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const deleteShippingCharge = (costOfGoodsId) => {
        setIsDeleting(true)
        request({
            url: `/tiktok-shipping-charges/orchestrators/delete?shippingChargeId=${costOfGoodsId}`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['listPresentShippingChargesByProductId'] }).finally(() => {})
                    queryClient.invalidateQueries({queryKey: ['listTikTokProducts']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listTikTokProductStats']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listTikTokProductDetailedStatsByProductId']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listAllStats']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listCogsByProductId']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['getTiktokProduct']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['validateShippingChargesByProductId']}).finally(() => {
                    })
                    showNotification({
                        title: "Deleted!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsDeleting(false))
    }

    return (
        <>
            {product && (
                <>
                    {showNewShippingCharges && (
                        <Modal zIndex={999}
                               opened={true}
                               closeButton
                               fullScreen={true}
                               onClose={() => setShowNewShippingCharges(false)}
                        >
                            <Modal.Body>
                                {(!shippingCharges || (shippingCharges && shippingCharges.length === 0)) && (
                                    <div>
                                        <div className={"mb-2 font-medium text-black text-3xl"}>
                                            <span className={"mr-4 opacity-50"}>{PLUS_ICON}</span>
                                            First, let's create a default <b>Shipping Fee</b> record
                                        </div>
                                        <div className={"mb-10 opacity-50 text-black text-xl"}>
                                            Once we have your "default" shipping figures, you will be able to add back-dated shipping adjustment records to ensure profit figures are accurately reported.
                                        </div>
                                    </div>
                                )}

                                {((shippingCharges && shippingCharges.length > 0)) && (
                                    <div>
                                        <div className={"mb-10 font-medium text-black text-3xl"}>
                                            <span className={"mr-4 opacity-50"}>{PLUS_ICON}</span>
                                            Create Shipping Charge
                                        </div>
                                        <div className={"mb-10 opacity-50 text-black text-xl"}>
                                            First select a date range for which this cost of goods record should be used, then define your cost elements, and we'll do the rest.
                                        </div>
                                    </div>
                                )}

                                <CreateEditShippingChargesV2
                                    live={(!shippingCharges || (shippingCharges && shippingCharges.length === 0))}
                                    isNew={true}
                                    editingCog={true}
                                    productId={product ? product.id : null}
                                    shippingCharge={null}
                                    onDelete={() => {
                                        setShowNewShippingCharges(false)
                                    }}
                                    onSave={() => {
                                        setShowNewShippingCharges(false)
                                    }}
                                />
                            </Modal.Body>
                        </Modal>
                    )}
                    {editingShippingCharges && editingShippingCharges.id && (
                        <Modal zIndex={999}
                               opened={true}
                               closeButton
                               fullScreen={true}
                               onClose={() => setEditingShippingCharges(false)}
                        >
                            <Modal.Body>
                                <div className={"mb-10 font-medium text-black text-3xl"}>
                                    <span className={"mr-4 opacity-50"}>{PLUS_ICON}</span>
                                    Edit Shipping Charge
                                </div>
                                <CreateEditShippingChargesV2
                                    isNew={false}
                                    productId={product ? product.id : null}
                                    key={editingShippingCharges.id}
                                    onSave={() => {
                                        setEditingShippingCharges(null)
                                    }}
                                    shippingCharge={{
                                        id: editingShippingCharges.id,
                                        from: new Date(editingShippingCharges.from),
                                        to: editingShippingCharges.to ? new Date(editingShippingCharges.to) : null,
                                        present: editingShippingCharges.present,
                                        total_shipping_charge: editingShippingCharges.total_shipping_charge,
                                    }} />
                            </Modal.Body>
                        </Modal>
                    )}
                    <LoadingOverlay visible={isLoadingValidation || isLoadingShippingCharges || isDeleting}/>
                    {product && product.auto_calc_shipping && (
                        <div className={"py-24 px-4 bg-green-50 flex items-center justify-center rounded-xl "}>
                            <div>
                                <span className={"text-green-500 mb-4 w-full justify-center flex text-3xl"}>{CHECK_CIRCLE}</span>
                                <Text className={"font-bold mb-4 w-full flex justify-center text-3xl"}>Provided by TikTok</Text>
                                <Text>This product's shipping information is provided by TikTok at order time, so we don't need to collect this information from you.</Text>
                            </div>
                        </div>
                    )}
                    {product &&
                        !(product.auto_calc_shipping) && (
                            <>
                                {(!isLoadingShippingCharges && !shippingCharges) || (shippingCharges && shippingCharges.length === 0) && (
                                    <div className={"m-4 rounded-xl py-24 px-4 bg-yellow-50 flex items-center justify-center"}>
                                        <div className={"w-full"}>
                                            <span className={"text-yellow-500 mb-4 w-full justify-center flex text-3xl"}>{WARNING_ICON}</span>
                                            <Text className={"font-bold mb-4 w-full flex justify-center text-3xl"}>Missing Shipping Charge Information</Text>
                                            <Text className={"w-full justify-center flex"}>Enter your shipping charges details so we're able to accurately report profit figures.</Text>
                                            <div className={"mt-8 w-full flex items-center justify-center"}>
                                                <Button
                                                    color={"primary"}
                                                    icon={PLUS_ICON}
                                                    onClick={() => setShowNewShippingCharges(true)}
                                                >
                                                    Get Started
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {shippingCharges && shippingCharges.length > 0 && (
                                    <>
                                        <Alert color={"blue"} icon={INFO_CIRCLE_ICON} title={"Here's how you've configured your shipping fee records:"}>

                                            <ul>
                                                {explainData(shippingCharges).map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </Alert>
                                        {!isLoadingValidation && issues && issues.length > 0 && (
                                            <div className={"bg-red-50 p-4 m-4 mb-0 rounded-xl"}>
                                <span className={"text-red-500"}>
                                    <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                                    Your shipping charges might not be setup correctly.
                                </span>
                                                <ul>
                                                    {issues.map((issue, index) => (
                                                        <ol key={index}>
                                                            {issue}
                                                        </ol>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                        {shippingCharges && shippingCharges.length > 0 && (
                                            <div className={"-mx-4"}>
                                                <Table
                                                    lined
                                                    headerLined
                                                    aria-label="Example table with static content"
                                                    css={{
                                                        height: "auto",
                                                        width: "100% !important",
                                                        marginTop: "0px",
                                                        borderRadius: "0px",
                                                        margin: "0px !important",
                                                        padding: "0px !important"
                                                    }}
                                                >
                                                    <Table.Header>
                                                        <Table.Column width="25px">
                                                            <div className={"pl-4"}>ID</div>
                                                        </Table.Column>
                                                        <Table.Column width="300px">
                                                            TYPE
                                                        </Table.Column>
                                                        <Table.Column width="100px">
                                                            DATE RANGE
                                                        </Table.Column>
                                                        <Table.Column width="100px">COST PER UNIT</Table.Column>
                                                        <Table.Column width="100px">ACTIONS</Table.Column>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {shippingCharges.map((shippingCharge, index) => (
                                                            <Table.Row key={shippingCharge.id}>
                                                                <Table.Cell>
                                               <span className={"opacity-50 ml-4"}>
                                                   {shippingCharge.id}
                                               </span>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {shippingCharge.present && (
                                                                        <div className={"w-full flex"}>
                                                                            <div className={"w-4 h-4 mr-4 rounded-full bg-green-500"}></div>
                                                                            <div style={{width: "300px"}}>
                                                                                <Text className={"text-sm font-bold"}>
                                                                                    Default
                                                                                </Text>
                                                                                <Text className={"mt-2 text-xs opacity-50 whitespace-pre-line"}>
                                                                                    We'll use this as your default shipping fee per unit for this product.
                                                                                </Text>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {!shippingCharge.present && (
                                                                        <div className={"w-full flex"}>
                                                                            <div className={"w-4 h-4 mr-4 rounded-full bg-gray-500"}></div>
                                                                            <div>
                                                                                <Text className={"text-sm font-bold"}>
                                                                                    Date-based
                                                                                </Text>
                                                                                <Text className={"mt-2 text-xs opacity-50 whitespace-pre-line"}>
                                                                                    From <>
                                                  <span className={"font-bold"}>
                                                  {moment(new Date(shippingCharge.from)).format("YYYY-DD-MM")}
                                              </span>
                                                                                    <span className={"mx-1 opacity-50"}>to</span>
                                                                                    <span className={"font-bold"}>
                                                                                {moment(new Date(shippingCharge.to)).format("YYYY-DD-MM")}
                                                                            </span>
                                                                                </> we'll use this shipping fee record.
                                                                                </Text>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {shippingCharge.present && (
                                                                        <>
                                                            <span className={"font-bold text-blue-500"}>
                                                          ~
                                                        </span>
                                                                            <span className={"mx-4 text-black opacity-50"}>
                                                          -
                                                        </span>
                                                                            <span className={"font-bold text-blue-500"}>
                                                          ~
                                                        </span>
                                                                        </>
                                                                    )}
                                                                    {!shippingCharge.present && (
                                                                        <>
                                                  <span>
                                                  {moment(new Date(shippingCharge.from)).format("YYYY-DD-MM")}
                                              </span>
                                                                            <span className={"mx-2 opacity-50"}>to</span>
                                                                            <>
                                                                                {moment(new Date(shippingCharge.to)).format("YYYY-DD-MM")}
                                                                            </>
                                                                        </>
                                                                    )}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <b>£ {shippingCharge.total_shipping_charge.toFixed(2)}</b>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <div className={"flex gap-2"}>
                                                                        <Button color={"primary"} icon={EDIT_ICON_SMALL} auto={true} flat onClick={() => setEditingShippingCharges(shippingCharge)}/>
                                                                        {!shippingCharge.present && (
                                                                            <Button color={"error"} icon={TRASH_ICON} auto={true} flat
                                                                                    onClick={() => {
                                                                                        deleteShippingCharge(shippingCharge.id)
                                                                                        setEditingShippingCharges(null)
                                                                                    }}/>
                                                                        )}
                                                                    </div>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        )}
                                        <Button
                                            color={"gray"}
                                            icon={PLUS_ICON}
                                            shadow
                                            size={"lg"}
                                            style={{width: "100%", borderRadius: "0px"}}
                                            onClick={() => setShowNewShippingCharges(true)}
                                        >
                                            <div>
                                                <Text>New Date Range: Shipping Charge Record</Text>
                                            </div>
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                </>
            )}
        </>
    )
}