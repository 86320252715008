export const VATSetup = () => {
    return (
        <>
            <div>
                <div className={"text-3xl font-bold"}>Setup VAT/Tax</div>
                <div className={"opacity-50 text-md"}>How to setup VAT calculation</div>

                <div className={"mt-8 mb-8 opacity-50 text-md"}>
                 Follow the interactive tutorial below to show you how to setup VAT calculation for both settled, and unsettled orders.
                </div>

                <div
                    style={{position: "relative", paddingBottom: "calc(73.13953488372093% + 41px)", height: "0", width: "100%"}}>
                    <iframe src="https://demo.arcade.software/k9kI731jMz1RST2tuC13?embed" frameBorder="0" loading="lazy"
                            webkitallowfullscreen mozallowfullscreen allowFullScreen
                            style={{position: "absolute", top: "0", left:" 0", width: "100%", height: "100%",colorScheme: "light"}}
                            title="ShopFlow"></iframe>
                </div>
            </div>
        </>
    )
}