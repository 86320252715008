export const PlatformFee = () => {
    return (
        <>
            <div>
                <div className={"text-3xl font-bold"}>Setup Platform Fee</div>
                <div className={"opacity-50 text-md"}>Learn all about how we record platform fee's</div>

                <div className={"mt-8 mb-8 opacity-50 text-md"}>
                 For "unsettled" orders we have to figure out your platform fee because TikTok does not provide us with that data for unsettled orders. The interactive tutorial below will show you how to setup platform fee records so that we can report platform fee figures for unsettled orders (in "live mode").
                </div>

                <div className={"font-bold mt-8 mb-8 opacity-50 text-md"}>
                    * In "completed orders" mode, all platform fee figures are 100% accurate as we receive that data from TikTok for settled orders.
                </div>

                <div
                    style={{position: "relative", paddingBottom: "calc(73.13953488372093% + 41px)", height: "0", width: "100%"}}>
                    <iframe src="https://demo.arcade.software/n5O3QYusGjR0HESQIRX3?embed" frameBorder="0" loading="lazy"
                            webkitallowfullscreen mozallowfullscreen allowFullScreen
                            style={{position: "absolute", top: "0", left:" 0", width: "100%", height: "100%",colorScheme: "light"}}
                            title="ShopFlow"></iframe>
                </div>
            </div>
        </>
    )
}