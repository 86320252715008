
export const calcNetProfit = (dataSet) => {
    const totalSales = dataSet.TotalSales ? dataSet.TotalSales : 0
    const totalSalesVAT = dataSet.TotalVAT ? dataSet.TotalVAT : 0
    const totalShippingFee = dataSet.TotalShippingFee ? dataSet.TotalShippingFee : 0
    const costOfGoods = dataSet.TotalCogs ? dataSet.TotalCogs : 0
    const affiliateCommissions = dataSet.TotalAffiliateCommission ? dataSet.TotalAffiliateCommission : 0;
    const platformFee = dataSet.TotalPlatformFee ? dataSet.TotalPlatformFee : 0;
    const purchasesVAT = totalSalesVAT > 0 ? (totalShippingFee / 6) + (affiliateCommissions / 6) + (platformFee / 6) : 0
    const totalVAT = totalSalesVAT - purchasesVAT
    return (totalSales - affiliateCommissions - platformFee - totalVAT - totalShippingFee - costOfGoods)
}

export const calcMargin = (netProfit, netSales, totalCancelledSales) => {
    if (netProfit === 0 || netSales === 0) {
        return 0
    }

    return (netProfit / netSales) * 100
}

export const cutString = (str, amt) => {
    // Check if string is at least 16 characters long
    if(str.length < amt) {
        return str
    }

    return str.slice(0, amt) + "...";
}
export const getCogsByProduct = (product) => {
    let total = 0;
    if (product && product.edges && product.edges.product_cost_of_goods_ranges) {
        for (const cog of product.edges.product_cost_of_goods_ranges) {
            total = cog.total_cost_of_goods + total
        }
    }
    return total;
}

export const getShippingChargesByProduct = (product) => {
    let total = 0;
    if (product && product.edges && product.edges.product_shipping_charge_ranges) {
        for (const shippingCharge of product.edges.product_shipping_charge_ranges) {
            total = shippingCharge.total_shipping_charge + total
        }
    }
    return total;
}