import {Button, Pagination, Text} from "@nextui-org/react";
import {
    ARROW_LEFT_ICON, ARROW_RIGHT_ICON_XS, CHECK_CIRCLE,
    CLOCK_ICON, CLOSE_CIRCLE_ICON,
    DATABASE_ICON,
    EDIT_ICON_SMALL, INFO_CIRCLE_ICON,
    LINK_ICON, PIE_CHART_ICON,
    PLUS_ICON,
    REFRESH_ICON_SPIN, SETTINGS_ICON, SHOPPING_CART_ICON, UP_ARROW_ICON, WARNING_ICON
} from "../../../Icons/FontAwesomeIcons";
import {useNavigate, useParams} from "react-router-dom";
import {useTikTokDetailedProductStats, useTiktokProduct, useTikTokProductStats} from "../../../Hooks/TiktokProducts";
import {useContext, useEffect, useState} from "react";
import {
    getCurrentMonthStartDate, getCurrentQuarterStartDate, getCurrentYearStartDate,
    getPreviousMonthDate,
    getThreeMonthsAgoDate,
    getTodayPlusOne,
    getYearAgoDate
} from "../../../Utils/Dates";
import {Anchor, Checkbox, LoadingOverlay, NumberInput, Select} from "@mantine/core";
import {calcMargin, calcNetProfit, getCogsByProduct, getShippingChargesByProduct} from "../../../Utils/Figures";
import {SingleLineChartImpl} from "../../Charts/SingleLineChartImpl";
import {EditCostOfGoodsByProductId} from "../../Common/CostOfGoods/EditCostOfGoodsByProductId";
import {
    useAggregatedStats,
    useAggregatedStatsByProductId,
    useDayByDayByProductIds,
    useExpenseStats
} from "../../../Hooks/Statistics";
import {orderStatuses} from "../Dashboard/DashboardTilesSection";
import {useDebounce} from "@uidotdev/usehooks";
import {AppContext} from "../../../Contexts/App";
import {DayByDayAggregate} from "../../Common/DayByDayAggregate";
import {DayByDayAggregateByProductId} from "../../Common/DayByDayAggregateByProductId";
import {useSettingItemsCache} from "../../../Hooks/SettingItems";
import {SkuProductList} from "../../Common/SkuProductList";
import {calendarOptions} from "../../../Constants/Constants";
import {ProductVatExemptToggle} from "../../Common/ProductVatExemptToggle";

export const ViewProduct = () => {
    const navigate = useNavigate()

    const {settingItems} = useSettingItemsCache(["reduce_shipping_fee_by_percent", "subtract_shipping_in_gross_profits"])

    const [from, setFrom] = useState(getPreviousMonthDate())
    const [to, setTo] = useState(getTodayPlusOne())

    const {settlementsOnly, includeShippingAsCost, setIncludeShippingAsCost, shippingPercentage, setShippingPercentage, calendarPeriod, setCalendarPeriod} = useContext(AppContext)
    const [selectedShopIds, setSelectedShopIds] = useState(null)
    const { id } = useParams()
    const {isLoading: isLoadingProduct, product} = useTiktokProduct(id)
    const [selectedOrderStatuses, setSelectedOrderStatuses] = useState([])
    const debouncedOrderStatuses = useDebounce(selectedOrderStatuses, 1000);
    const [totalSales, setTotalSales] = useState(0)
    const [totalUnits, setTotalUnits] = useState(0)
    const [totalCancelledUnits, setTotalCancelledUnits] = useState(0)
    const [totalCancelledSales, setTotalCancelledSales] = useState(0)
    const [totalCOGS, setTotalCOGS] = useState(0)
    const [totalGrossProfits, setTotalGrossProfits] = useState(0)
    const [totalExpenses, setTotalExpenses] = useState(0)
    const [totalNetProfits, setTotalNetProfits] = useState(0)
    const [totalRoi, setTotalRoi] = useState(0)
    const [totalShippingFees, setTotalShippingFees] = useState(0)
    const [adjustedShippingFee, setAdjustedShippingFee] = useState(0)
    const [platformCommiss, setPlatformCommiss] = useState(0)
    const [affiliateComiss, setAffiliateComiss] = useState(0)
    const [vat, setVAT] = useState(0)
    const [vatPurchases, setVatPurchases] = useState(0)
    const [vatSales, setVatSales] = useState(0)

    useEffect(() => {
        if (includeShippingAsCost) {
            setAdjustedShippingFee(totalShippingFees - (shippingPercentage ? (shippingPercentage / 100) * totalShippingFees : 0))
        } else {
            setAdjustedShippingFee(totalShippingFees)
        }
    }, [totalShippingFees, includeShippingAsCost, shippingPercentage])

    useEffect(() => {
        if (includeShippingAsCost) {
            setTotalGrossProfits(((totalSales - vat) - (totalCOGS + adjustedShippingFee) - affiliateComiss - platformCommiss))
        } else {
            setTotalGrossProfits((totalSales - vat - totalCOGS) - affiliateComiss - platformCommiss)
        }
    }, [totalSales, totalCOGS, adjustedShippingFee, includeShippingAsCost, shippingPercentage])

    useEffect(() => {
        setTotalNetProfits((totalGrossProfits - totalExpenses))
    }, [totalExpenses, totalGrossProfits])

    useEffect(() => {
        setTotalRoi((totalNetProfits / (totalCOGS ? totalCOGS : 1) * 100))
    }, [totalNetProfits, totalCOGS])

    useEffect(() => {
        switch (calendarPeriod) {
            case "this_month_by_day":
                setFrom(getCurrentMonthStartDate())
                setTo(getTodayPlusOne())
                break
            case "this_quarter_by_week":
                setFrom(getCurrentQuarterStartDate())
                setTo(getTodayPlusOne())
                break
            case "last_30_days_by_day":
                setFrom(getPreviousMonthDate())
                setTo(getTodayPlusOne())
                break
            case "last_3_months_by_week":
                setFrom(getThreeMonthsAgoDate())
                setTo(getTodayPlusOne())
                break
            case "last_12_months_by_month":
                setFrom(getYearAgoDate())
                setTo(getTodayPlusOne())
                break
            case "this_year_by_month":
                setFrom(getCurrentYearStartDate())
                setTo(getTodayPlusOne())
                break
        }
    }, [calendarPeriod])

    useEffect(() => {
        if (settingItems) {
            if (settingItems.subtract_shipping_in_gross_profits) {
                const value = settingItems.subtract_shipping_in_gross_profits.value === "false" ? false : true
                setIncludeShippingAsCost(value)
            }

            if (settingItems.reduce_shipping_fee_by_percent) {
                const value = Number(settingItems.reduce_shipping_fee_by_percent.value)
                setShippingPercentage(value)
            }
        }
    }, [settingItems])

    return (
        <>
            <LoadingOverlay visible={isLoadingProduct} />
            <div className={"bg-white"}>
                <div className={"shadow-3xl border-b border-t"}>
                    <Button light className={"m-4"} auto={true} onClick={() => navigate(-1)}>
                        <span className={"mr-2"}>
                            {ARROW_LEFT_ICON}
                        </span>
                        Back
                    </Button>
                    <div className={"p-4 pt-4 border-t flex flex-wrap"}>
                        <div className={"lg:w-1/2 w-full"}>
                            <div className={"flex gap-4"}>
                                {product.image_url && (
                                    <img src={product.image_url} alt={"image-" + product.id} style={{maxWidth:"40px"}} className={"h-8 rounded-md shadow ml-4 mr-4"} />
                                )}
                                {!product.image_url && (
                                    <div className={"bg-gray-50 h-8 rounded-md shadow ml-4 mr-4 px-4"}></div>
                                )}
                                {product && (
                                    <div>
                                        <Text className={"mb-4 opacity-50"}>
                                            <span className={"mr-2"}>{PIE_CHART_ICON}</span>
                                           Product Performance
                                        </Text>
                                        <Text className={"mb-4 text-xl font-bold"}>
                                            <span className={"mr-2"}>{SHOPPING_CART_ICON}</span>
                                            {product.name}
                                        </Text>
                                        <div className={"mt-8 flex items-center w-full"}>
                                            {product && (
                                                <>
                                                    <div className={"mr-10"}>
                                                        <span className={"opacity-50 mr-4"}>Shipping Charges </span>
                                                        <Text
                                                            color={"primary"}>
                                                            {(product
                                                                && !product.auto_calc_shipping
                                                                && product.edges
                                                                && product.edges.product_shipping_charge_ranges
                                                                && product.edges.product_shipping_charge_ranges.length <= 1) && (
                                                                <>
                                                                    <span className={"opacity-50"}>£ {getShippingChargesByProduct(product).toFixed(2)} / unit</span>
                                                                </>
                                                            )}
                                                            {(product
                                                                && !product.auto_calc_shipping
                                                                && product.edges
                                                                && product.edges.product_shipping_charge_ranges
                                                                && product.edges.product_shipping_charge_ranges.length > 1) && (
                                                                <>
                                                                    <span className={"mr-2 opacity-25"}>... / unit</span>
                                                                </>
                                                            )}
                                                            {(product
                                                                && !product.auto_calc_shipping
                                                                && product.edges
                                                                && !product.edges.product_shipping_charge_ranges) && (
                                                                <>
                                                    <span className={"text-yellow-500"}>
                                                                        <span className={"mr-2"}>{WARNING_ICON}</span>
                                                                        Required
                                                                    </span>
                                                                </>
                                                            )}
                                                            {(product && product.auto_calc_shipping) && (
                                                                <>
                                                    <span className={"text-green-500"}>
                                                                            <span className={"mr-2"}>{CHECK_CIRCLE}</span>
                                                                            Auto-calculated
                                                                        </span>
                                                                </>
                                                            )}
                                                        </Text>
                                                    </div>
                                                    <div>
                                                        <span className={"opacity-50 mr-4"}>Cost of Goods </span>
                                                        <Text
                                                            color={"primary"}
                                                            shadow={true}>
                                                            {(product
                                                                && product.edges
                                                                && product.edges.product_cost_of_goods_ranges
                                                                && product.edges.product_cost_of_goods_ranges.length <= 1) && (
                                                                <>
                                                                    £ {getCogsByProduct(product).toFixed(2)} / unit
                                                                </>
                                                            )}
                                                            {(product
                                                                && product.edges
                                                                && product.edges.product_cost_of_goods_ranges
                                                                && product.edges.product_cost_of_goods_ranges.length > 1) && (
                                                                <>
                                                                    ... / unit
                                                                </>
                                                            )}
                                                            {(product
                                                                && product.edges
                                                                && !product.edges.product_cost_of_goods_ranges) && (
                                                                <span className={"text-yellow-500"}>
                                                                        <span className={"mr-2"}>{WARNING_ICON}</span>
                                                                        Required
                                                                    </span>
                                                            )}
                                                        </Text>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                            <div>
                                <ProductVatExemptToggle value={product.vat_exempt} productId={product.id} />
                                <div className={"mt-4 flex w-full items-center justify-end"}>
                                    <div className={""}>
                                        <Button
                                            flat
                                            color={"primary"}
                                            onClick={() => navigate(`/products/configure/${product.id}`)}>
                                            Manage <span className={"font-bold ml-1 mr-1"}>Cost of Goods</span> & <span className={"font-bold ml-1 mr-1"}>Shipping Info</span>
                                            <span className={"ml-4"}>{ARROW_RIGHT_ICON_XS}</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"p-4 border-t flex"}>
                        <div className={"lg:w-1/3 w-full"}>
                            <Select
                                label={"Calendar Period"}
                                radius={"md"}
                                icon={CLOCK_ICON}
                                value={calendarPeriod}
                                onChange={(period) => {
                                    setCalendarPeriod(period)
                                }}
                                data={calendarOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"bg-white"}>
                {!isLoadingProduct && product && (
                    <>
                        <div className={"flex flex-wrap items-stretch"}>
                            <div className={"xl:w-2/3 w-full"}>
                                <div className={"bg-white shadow-3xl border-b h-full"}>
                                    <DayByDayAggregateByProductId
                                        productId={id}
                                        calendarPeriod={calendarPeriod}
                                        from={from}
                                        to={to}
                                        setTotalUnits={setTotalUnits}
                                        setTotalCancelledSales={setTotalCancelledSales}
                                        setTotalCancelledUnits={setTotalCancelledUnits}
                                        setTotalSales={setTotalSales}
                                        setTotalCOGS={setTotalCOGS}
                                        setTotalShippingFees={setTotalShippingFees}
                                        setAffiliateComiss={setAffiliateComiss}
                                        setPlatformCommiss={setPlatformCommiss}
                                        selectedShopIds={selectedShopIds}
                                        setVAT={setVAT}
                                        setVatPurchases={setVatPurchases}
                                        setVatSales={setVatSales}
                                        selectedOrderStatuses={debouncedOrderStatuses}
                                    />
                                </div>
                            </div>
                            <div className={"xl:w-1/3 w-full flex"}>
                                <div className={"bg-white flex items-center w-full h-full border-b border-l shadow-3xl"}>
                                    <div className={"w-full p-8"}>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/3 flex items-center"}>
                                                <Text b>Net Sales</Text>
                                            </div>
                                            <div className={"w-2/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>£ {(totalSales).toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/2 flex items-center"}>
                                                <Text className={"text-xs"}>Sales</Text>
                                            </div>
                                            <div className={"w-1/2 flex items-center justify-end"}>
                                                <Text className={"opacity-75 text-xs"}>£ {(totalSales + totalCancelledSales).toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/2 flex items-center"}>
                                                <Text className={"text-xs"}>Cancelled</Text>
                                                <Text className={"ml-2 opacity-50 text-xs"}>({(totalCancelledSales / totalSales * 100).toFixed(2)}%)</Text>
                                            </div>
                                            <div className={"w-1/2 flex items-center justify-end"}>
                                                <Text className={"opacity-75 text-xs"} color={"red"}>£ -{totalCancelledSales.toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/3 flex items-center"}>
                                                <Text b>Net Units</Text>
                                            </div>
                                            <div className={"w-2/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>{totalUnits - totalCancelledUnits}</Text>
                                            </div>
                                        </div>
                                        <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/2 flex items-center"}>
                                                <Text className={"text-xs"}>Units</Text>
                                            </div>
                                            <div className={"w-1/2 flex items-center justify-end"}>
                                                <Text className={"opacity-75 text-xs"}>{totalUnits}</Text>
                                            </div>
                                        </div>
                                        <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/2 flex items-center"}>
                                                <Text className={"text-xs"}>Cancelled</Text>
                                                <Text className={"text-xs ml-2 opacity-50"}>({(totalCancelledUnits / totalUnits * 100).toFixed(2)}%)</Text>
                                            </div>
                                            <div className={"w-1/2 flex items-center justify-end"}>
                                                <Text className={"opacity-75 text-xs"} color={"red"}>-{totalCancelledUnits}</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/2"}>
                                                <Text b>Shipping</Text>
                                                <Text className={"text-xs opacity-50"}>inc. cancelled orders</Text>
                                            </div>
                                            <div className={"w-1/2 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>£ {(adjustedShippingFee).toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-2/3"}>
                                                <Text b>Affiliate Commission</Text>
                                                {!settlementsOnly && (
                                                    <div className={""}>
                                                        <Text className={"text-xs"} color={"orange"}>~ estimated</Text>
                                                    </div>
                                                )}
                                            </div>
                                            <div className={"w-1/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>£ {affiliateComiss.toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-2/3"}>
                                                <Text b>Platform Fee's</Text>
                                            </div>
                                            <div className={"w-1/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>£ {platformCommiss.toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/3 flex items-center"}>
                                                <Text b>VAT</Text>
                                            </div>
                                            <div className={"w-2/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>£ {vat.toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/2 flex items-center"}>
                                                <Text className={"text-xs"}>Sales</Text>
                                            </div>
                                            <div className={"w-1/2 flex items-center justify-end"}>
                                                <Text className={"opacity-75 text-xs"}>£ {vatSales.toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/2 flex flex-wrap items-center gap-4"}>
                                                <Text className={"text-xs"}>Purchases</Text>
                                                <Text className={"text-xs"} color={"orange"}>~ estimated</Text>
                                            </div>
                                            <div className={"w-1/2 flex items-center justify-end"}>
                                                <Text className={"opacity-75 text-xs"} color={"red"}>£ -{vatPurchases.toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/3"}>
                                                <Text b>Cost of Goods</Text>
                                                <Text className={"text-xs opacity-50"}>exc. cancelled orders</Text>
                                            </div>
                                            <div className={"w-2/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>£ {totalCOGS.toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/3 flex items-center"}>
                                                <Text b>
                                                    Gross Profit
                                                    <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                                </Text>
                                            </div>
                                            <div className={"w-2/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>£ {totalGrossProfits.toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/3 flex items-center"}>
                                                <Text b>
                                                    Net Profit
                                                    <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                                </Text>
                                            </div>
                                            <div className={"w-2/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>£ {totalNetProfits.toFixed(2)}</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/3 flex items-center"}>
                                                <Text b>
                                                    Margin
                                                    <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                                </Text>
                                            </div>
                                            <div className={"w-2/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>{((totalNetProfits / (totalSales ? totalSales : 1)) * 100).toFixed(2)} %</Text>
                                            </div>
                                        </div>
                                        <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                            <div className={"w-1/3 flex items-center"}>
                                                <Text b>
                                                    ROI
                                                    <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                                </Text>
                                            </div>
                                            <div className={"w-2/3 flex items-center justify-end"}>
                                                <Text className={"opacity-75"}>{totalRoi.toFixed(2)} %</Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {product && (
                            <SkuProductList from={from} to={to} selectedShopIds={selectedShopIds} product={product} />
                        )}
                    </>
                )}
            </div>
        </>
    )
}