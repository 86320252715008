import {LoadingOverlay, Modal} from "@mantine/core";
import {useContext, useState} from "react";
import {Button, Switch, Text} from "@nextui-org/react";
import {CHECK_CIRCLE, CHECKMARK_ICON, CLOSE_CIRCLE_ICON, CLOSE_ICON} from "../../../Icons/FontAwesomeIcons";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {AppContext} from "../../../Contexts/App";
import {pricingItems} from "../../../Constants/Constants";
import {useQueryClient} from "@tanstack/react-query";
import moment from "moment/moment";

export const CancelSubscription = ({details, open, setOpen}) => {
    const queryClient = useQueryClient()
    const {clearAppContext} = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(false)

    const handleError = (response) => {
        let message = "Unable to perform action";
        if (response && response.data) {
            message = response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const cancel = () => {
        setIsLoading(true)
        request({
            url: `/subscriptions/orchestrators/update`,
            data: {
                type: "cancel"
            },
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    showNotification({
                        title: "Success! We've cancelled your subscription.",
                        icon: CHECKMARK_ICON,
                        color: "green"
                    })

                    queryClient.clear()
                    setOpen(false)
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <Modal zIndex={999}
                   title={(
                       <div className={"w-full items-center justify-center"}>
                           <Text className={"text-xl font-bold"}>
                               Confirm Cancellation
                           </Text>
                       </div>
                   )}
                   size={"xl"}
                   opened={open}
                   onClose={() => setOpen(false)}
            >
                <Modal.Body>
                    <LoadingOverlay visible={isLoading} />
                    <Text className={"mb-4 font-bold opacity-100"}>
                        Are you sure you want to cancel your subscription?
                    </Text>

                    {details && (
                        <>
                            <Text className={"mt-10 opacity-75"}>
                                If you go ahead with this cancellation, the following will happen:
                            </Text>
                            <Text color="red" className={"mt-4 opacity-50"}>
                                <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span> You'll lose access to ShopFlow.
                            </Text>
                            <Text className={"mb-2 text-xs ml-10 opacity-50"}>
                                on {moment(details.period_end).format("LL")}.
                            </Text>
                            <Text color="red" className={"opacity-50"}>
                                <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span> All order, and product data we hold related to your account will be deleted.
                            </Text>
                            <Text className={"mb-2 text-xs ml-10 opacity-50"}>
                                We'll hold your data for 30 days before deleting it, you will be able to login and re-subscribe in this period.
                            </Text>
                            <Text color="red" className={"opacity-50"}>
                                <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span> We will stop charging you for this subscription.
                            </Text>
                            <Text className={"mb-2 text-xs ml-10 opacity-50"}>
                                on {moment(details.period_end).format("LL")}.
                            </Text>
                            <Text color="red" className={"opacity-50"}>
                                <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span> You'll lose the ability to effectively track accurate profit figures for your TikTok shop(s).
                            </Text>
                            <Text className={"mb-2 text-xs ml-10 opacity-50"}>
                                It can't hurt to remind you how much we love ShopFlow, can it?
                            </Text>
                        </>
                    )}
                    <div className={"mt-20 flex gap-4 justify-end"}>
                        <Button color={"error"} shadow onClick={() => setOpen(false)}>Cancel</Button>
                        <Button color={"default"} bordered onClick={() => cancel()}>Confirm Cancellation</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}