import {useAggregatedStats, useAggregatedStatsByProductId} from "../../Hooks/Statistics";
import {SingleBarChartImpl} from "../Charts/SingleBarChartImpl";
import {CLOSE_CIRCLE_ICON} from "../../Icons/FontAwesomeIcons";
import {Text} from "@nextui-org/react";
import {LoadingOverlay} from "@mantine/core";
import {useContext, useEffect, useState} from "react";
import {getCurrentMonthStartDate, getTodayPlusOne} from "../../Utils/Dates";
import {color3, color1, color2} from "../Charts/SingleBarChartPlusLineChart";
import {AppContext} from "../../Contexts/App";

export const DayByDayAggregateByProductId = ({productId, calendarPeriod, from, to, selectedOrderStatuses, selectedShopIds, setTotalShippingFees, setPlatformCommiss, setVAT, setVatPurchases, setVatSales, setAffiliateComiss, setTotalCancelledSales, setTotalCancelledUnits, setTotalSales, setTotalUnits, setTotalCOGS}) => {
    const {settlementsOnly} = useContext(AppContext)
    const {isLoading: isLoadingAggrStats, dateToTotalsMap, rawData} = useAggregatedStatsByProductId(calendarPeriod, from, to, selectedShopIds, selectedOrderStatuses, productId)

    useEffect(() => {
        let totalSales = Number(0)
        let totalUnits = Number(0)
        let totalCogs = Number(0)
        let totalCancelledUnits = Number(0)
        let totalCancelledSales = Number(0)
        let totalShippingFees = Number(0)
        let totalAffiliateFees = Number(0)
        let totalPlatformFees = Number(0)
        let totalVAT = Number(0)

        if (rawData) {
            for(const item of rawData) {
                totalCancelledSales = (item.TotalSalesCancelled ? item.TotalSalesCancelled : 0) + totalCancelledSales
                totalCancelledUnits = (item.TotalUnitsCancelled ? item.TotalUnitsCancelled : 0) + totalCancelledUnits
                totalSales = (item.TotalSales ? item.TotalSales : 0) + totalSales
                totalUnits =(item.TotalUnits ? item.TotalUnits : 0) + totalUnits
                totalCogs = (item.TotalCogs ? item.TotalCogs : 0) + totalCogs
                totalShippingFees = (item.TotalShippingFee ? item.TotalShippingFee : 0) + totalShippingFees
                totalAffiliateFees = (item.TotalAffiliateCommission ? item.TotalAffiliateCommission : 0) + totalAffiliateFees
                totalPlatformFees = (item.TotalPlatformFee ? item.TotalPlatformFee : 0) + totalPlatformFees
                totalVAT = (item.TotalVAT ? item.TotalVAT : 0) + totalVAT
            }
        }

        const salesVAT = totalVAT
        const purchasesVAT = salesVAT > 0 ? (totalShippingFees / 6) + (totalAffiliateFees / 6) + (totalPlatformFees / 6) : 0
        setVatSales(totalVAT)
        setVatPurchases(purchasesVAT)
        setVAT(salesVAT - purchasesVAT)
        setTotalShippingFees(totalShippingFees)
        setTotalCancelledSales(totalCancelledSales)
        setTotalCancelledUnits(totalCancelledUnits)
        setTotalSales(totalSales)
        setTotalUnits(totalUnits)
        setTotalCOGS(totalCogs)
        setAffiliateComiss(totalAffiliateFees)
        setPlatformCommiss(totalPlatformFees)
    }, [rawData])

    return (
        <div>
            <LoadingOverlay visible={isLoadingAggrStats} />
            {/*<div className={"p-4 flex items-center justify-center w-full"}>*/}
            {/*    <div className={"w-auto flex gap-8"}>*/}
            {/*        <div className={"flex items-center"}>*/}
            {/*            <div className={"w-4 h-4 mr-2 rounded-full"} style={{backgroundColor: color1}}></div>*/}
            {/*            <Text className={"opacity-75"}>Sales</Text>*/}
            {/*        </div>*/}
            {/*        <div className={"flex items-center"}>*/}
            {/*            <div className={"w-4 h-4 mr-2 rounded-full"} style={{backgroundColor: color2}}></div>*/}
            {/*            <Text className={"opacity-75"}s>Units</Text>*/}
            {/*        </div>*/}
            {/*        {settlementsOnly && (*/}
            {/*            <div className={"flex items-center"}>*/}
            {/*                <div className={"w-4 h-4 mr-2 rounded-full"} style={{backgroundColor: color3}}></div>*/}
            {/*                <Text className={"opacity-75"}s>Affiliate Commissions</Text>*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <SingleBarChartImpl
                dataPoints={["TotalNetProfits", "TotalAffiliateCommission", "TotalUnits"]}
                settlementsOnly={settlementsOnly}
                height={"550px"}
                calendarPeriod={calendarPeriod}
                dateToTotalPointMap={dateToTotalsMap}
            />
        </div>
    )
}