import {LoadingOverlay, NumberInput} from "@mantine/core";
import {Button, Text} from "@nextui-org/react";
import {
    ARROW_LEFT_ICON, ARROW_RIGHT_ICON_XS, CHECK_CIRCLE, CLOSE_ICON,
    INFO_CIRCLE_ICON,
    QUESTION_MARK_ICON,
    REFRESH_ICON_SPIN
} from "../../../../Icons/FontAwesomeIcons";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../Contexts/App";
import {useForm} from "@mantine/form";
import {request} from "../../../../Interceptors/Request";
import moment from "moment/moment";
import {showNotification} from "@mantine/notifications";
import {useQueryClient} from "@tanstack/react-query";
import {usePlatformFees} from "../../../../Hooks/TiktokPlatformFees";

export const PlatformFeeConfiguration = () => {
    const {clearAppContext} = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const {platformFees, isLoading: isLoadingFees} = usePlatformFees()
    const [defaultPlatformId, setDefaultPlatformId] = useState(null)
    const queryClient = useQueryClient()

    const form = useForm({
        initialValues: {
            present: true,
            total_platform_fee_as_percentage: 4.9,
        },

        validate: {
            total_platform_fee_as_percentage: (value) => value && value > 0 ? null : 'Amount must be greater than zero'
        },
    });

    const handleError = (response) => {
        let message = "Unable to perform action";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const continueOnboarding = () => {
        setIsSaving(true)
        request({
            url: `/user/orchestrators/update-onboarding`,
            method: "POST",
            data: {
                status: "vat_configuration",
                state: ""
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['currentUser'] }).finally(() => {})
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    const save = () => {
        setIsSaving(true)
        request({
            url: defaultPlatformId ? `/tiktok-platform-fees/orchestrators/update` : `/tiktok-platform-fees/orchestrators/create`,
            method: "POST",
            data: {
                id: defaultPlatformId,
                present: true,
                total_platform_fee_as_percentage: form.values.total_platform_fee_as_percentage,
                from: "2001-02-02",
                to: "2030-02-02",
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({queryKey: ['listPlatformFees']}).finally(() => {})
                    continueOnboarding()
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    useEffect(() => {
        for(const fee of platformFees) {
            if (fee.present) {
                setDefaultPlatformId(fee.id)
            }
        }
    }, [platformFees])

    return (
        <form onSubmit={form.onSubmit((values) => save())}>
            <div
                className="h-full flex items-center justify-center w-3/4 mx-auto"
                style={{minHeight: "100vh"}}>
                <LoadingOverlay visible={isSaving} />
                <div className="my-10 flex items-center items-stretch justify-center h-full w-full">
                    <div>
                        <img src="/assets/shopflow_logo.png" width={"100px"} className={"mb-4 block mx-auto"}/>
                        <Button
                            auto={true}
                            light
                            color={"default"}
                            icon={ARROW_LEFT_ICON}
                            onClick={() => clearAppContext()}
                            className={"block mx-auto mb-4"}
                        >
                            Logout
                        </Button>
                        <div className={"w-full text-center mb-10"}>
                            <Text className={"font-bold text-3xl"}>3. Fee Configuration</Text>
                        </div>
                        <div className={"w-3/4 mx-auto block bg-white shadow rounded-xl p-4 flex justify-center items-center"}>
                            <div>
                                <Text className={"mb-8 text-xl"}>
                                    <span className={"mr-2"}>
                                        {QUESTION_MARK_ICON}
                                    </span>
                                    There are <span className={"underline"}>two types</span> of orders we pull from TikTok: <b className={"underline"}>Unsettled</b>, and <b className={"underline text-green-500"}>Settled</b>.
                                </Text>
                                <Text className={"mb-8 text-lg"}>
                                    For <b className={"underline"}>Unsettled</b> orders, we have to predict your platform fee's because TikTok does not send us accurate order info until the order has been marked as <b>completed</b>.
                                </Text>
                                <NumberInput
                                    min={0.01}
                                    step={0.01}
                                    precision={2}
                                    radius={"md"}
                                    size={"xl"}
                                    className={"pr-2"}
                                    description={"For most sellers, by default, this figure is 1.8%. You'll be able to make date-based adjustments to your platform fee percentage once ShopFlow is setup."}
                                    style={{width: "100%"}}
                                    placeholder="Amount"
                                    formatter={(value) =>
                                        !Number.isNaN(parseFloat(value))
                                            ? `${value}%`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                                            : ''}
                                    {...form.getInputProps(`total_platform_fee_as_percentage`)}
                                />
                                <Text className={"mt-8 text-lg font-bold"}>
                                    * We'd recommend keeping this figure set to 1.8% unless you've signed up to one of TikTok's promotional offers as a seller (which reduces the fee % for first 180 days).
                                </Text>
                                <Button
                                    className={"mt-8"}
                                    style={{width: "100%"}}
                                    disabled={isSaving}
                                    size={"xl"}
                                    color={"success"}
                                    type={"submit"}
                                    iconRight={ARROW_RIGHT_ICON_XS}>
                                    Save & Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}