import {Button, Text} from "@nextui-org/react";
import {
    ARROW_LEFT_ICON,
    CLOCK_ICON,
    EDIT_ICON_SMALL,
    SETTINGS_ICON,
    SHOPPING_CART_ICON
} from "../../../Icons/FontAwesomeIcons";
import {getCogsByProduct} from "../../../Utils/Figures";
import {Select, Tabs} from "@mantine/core";
import {VAT} from "./VAT";
import {TikTokAffiliateCommissions} from "./TikTokAffiliateCommissions";
import {useContext, useEffect} from "react";
import {AppContext} from "../../../Contexts/App";
import {Shipping} from "./Shipping";
import {useSearchParams} from "react-router-dom";
import {TiktokPlatformFees} from "./TiktokPlatformFees";
import {AccountDetails} from "./AccountDetails";

export const Settings = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get("tab")
    const {settingsTab, setSettingsTab} = useContext(AppContext)

    useEffect(() => {
        if(tab && tab !== "") {
            setSettingsTab(tab)
        }
    }, [tab])

    return (
        <>
            <div className={"bg-white"}>
                <div className={"p-4 shadow-3xl border-b border-t"}>
                    <div className={"flex items-center gap-4 mb-2"}>
                        <div className={"text-xl font-bold"}>
                            <span className={"mr-2"}>{SETTINGS_ICON}</span>
                           Settings
                        </div>
                    </div>
                    <div className={""}>
                        <Text className={"text-xs opacity-50"}>
                            Configure your ShopFlow setup.
                        </Text>
                    </div>
                </div>

                <Tabs
                    color="dark"
                    radius={"md"}
                    variant={"pills"}
                    value={settingsTab}
                    onTabChange={(tab) => {
                        setSettingsTab(tab)
                    }}
                >
                    <Tabs.List className={"bg-white shadow-3xl border py-2 pl-2"}>
                        <Tabs.Tab value="account" className="text-md"><span className={"mr-2"}>⚙️</span> Your Account</Tabs.Tab>
                        <Tabs.Tab value="tiktok_platform_fees" className="text-md">
                            <div className={"flex items-center"}>
                                <img src={"/assets/tiktoklogo.png"} alt={"tiktok logo"} width="16" className={"mr-2"} />
                                <div>TikTok: Platform Fee's</div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab value="tiktok_affiliate_commissions" className="text-md">
                            <div className={"flex items-center"}>
                                <img src={"/assets/tiktoklogo.png"} alt={"tiktok logo"} width="16" className={"mr-2"} />
                                <div>TikTok: Affiliates</div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab value="shipping" className="text-md"><span className={"mr-2"}>🚚</span> Shipping</Tabs.Tab>
                        <Tabs.Tab value="vat" className="text-md"><span className={"mr-2"}>🎟️</span> VAT</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel
                        value="account">
                        <AccountDetails />
                    </Tabs.Panel>

                    <Tabs.Panel
                        value="shipping">
                        <Shipping />
                    </Tabs.Panel>

                    <Tabs.Panel
                        value="vat">
                        <VAT />
                    </Tabs.Panel>

                    <Tabs.Panel
                        value="tiktok_platform_fees">
                        <TiktokPlatformFees />
                    </Tabs.Panel>

                    <Tabs.Panel
                        value="tiktok_affiliate_commissions">
                        <TikTokAffiliateCommissions />
                    </Tabs.Panel>
                </Tabs>
            </div>
        </>
    )
}