import {LoadingOverlay} from "@mantine/core";
import {Button, Text} from "@nextui-org/react";
import {ARROW_LEFT_ICON, CLOSE_ICON} from "../../../../Icons/FontAwesomeIcons";
import {useContext, useState} from "react";
import {AppContext} from "../../../../Contexts/App";
import {VAT} from "../../Settings/VAT";
import {request} from "../../../../Interceptors/Request";
import {useQueryClient} from "@tanstack/react-query";
import {showNotification} from "@mantine/notifications";

export const VatConfiguration = () => {
    const {clearAppContext} = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const queryClient = useQueryClient()

    const handleError = (response) => {
        let message = "Unable to perform action";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const continueOnboarding = () => {
        setIsSaving(true)
        request({
            url: `/user/orchestrators/update-onboarding`,
            method: "POST",
            data: {
                status: "finish",
                state: ""
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['currentUser'] }).finally(() => {})
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    return (
        <>
            <div
                className="h-full flex items-center justify-center w-3/4 mx-auto"
                style={{minHeight: "100vh"}}>
                <LoadingOverlay visible={isSaving} />
                <div className="my-10 flex items-center items-stretch justify-center h-full w-full">
                    <div>
                        <img src="/assets/shopflow_logo.png" width={"100px"} className={"mb-4 block mx-auto"}/>
                        <Button
                            auto={true}
                            light
                            color={"default"}
                            icon={ARROW_LEFT_ICON}
                            onClick={() => clearAppContext()}
                            className={"block mx-auto mb-4"}
                        >
                            Logout
                        </Button>
                        <div className={"w-full text-center mb-10"}>
                            <Text className={"font-bold text-3xl"}>4. VAT/Sales Tax Configuration</Text>
                        </div>
                        <div className={"w-3/4 mx-auto block bg-white shadow rounded-xl p-4 flex justify-center items-center"}>
                            <div className={"w-full"}>
                                <VAT
                                    onboardingMode={true}
                                    onSuccess={() => {
                                        continueOnboarding()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}