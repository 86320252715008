import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowCircleRight,
    faArrowDown,
    faArrowLeftLong,
    faArrowRightLong, faArrowUp, faBell, faBoltLightning, faCalendar,
    faCaretDown, faCaretUp,
    faCheck,
    faCheckCircle,
    faClock,
    faClose, faCloudUpload,
    faCode,
    faCodeMerge,
    faCog,
    faCreditCard,
    faDatabase,
    faDollar,
    faDownload,
    faEdit,
    faEllipsis, faEnvelope,
    faExternalLink, faEye, faFileArchive,
    faFileImport, faFirstAid,
    faGlobe,
    faHammer,
    faInfo,
    faInfoCircle, faLayerGroup,
    faList,
    faLock, faMailBulk, faMercury, faPaintbrush, faPaperclip,
    faPieChart,
    faPlay,
    faPlus, faQuestionCircle,
    faRefresh,
    faSearch, faShoppingCart, faStar, faStore, faTags, faThumbsUp, faTicket,
    faTimesCircle,
    faTrashCan, faTruck, faUser,
    faUsers,
    faUserSecret,
    faWarning, faWaveSquare, faWindowRestore
} from '@fortawesome/free-solid-svg-icons'

export const PLUS_ICON = <FontAwesomeIcon icon={faPlus} size={"xs"} />
export const LIST_ICON = <FontAwesomeIcon icon={faList} size={"xs"} />
export const DATABASE_ICON = <FontAwesomeIcon icon={faDatabase} size={"xs"} />
export const SHOPPING_CART_ICON = <FontAwesomeIcon icon={faShoppingCart} size={"xs"} />
export const STORE_ICON = <FontAwesomeIcon icon={faStore} size={"xs"} />
export const ARROW_RIGHT_ICON_XS = <FontAwesomeIcon icon={faArrowRightLong} size={"xs"} />
export const ARROW_RIGHT_ICON = <FontAwesomeIcon icon={faArrowRightLong} size={"xl"} />
export const CIRCLE_ARROW_RIGHT_ICON = <FontAwesomeIcon icon={faArrowCircleRight} size={"lg"} />
export const ARROW_UP_ICON = <FontAwesomeIcon icon={faArrowUp} size={"xs"} />
export const ARROW_DOWN_ICON = <FontAwesomeIcon icon={faArrowDown} size={"xs"} />
export const ARROW_DOWN_ICON_LARGE = <FontAwesomeIcon icon={faArrowDown} size={"xl"} />
export const ARROW_LEFT_ICON = <FontAwesomeIcon icon={faArrowLeftLong} size={"xs"} />
export const WINDOW_RESTORE_ICON = <FontAwesomeIcon icon={faWindowRestore} size={"xs"} />
export const PIE_CHART_ICON = <FontAwesomeIcon icon={faPieChart} size={"xs"} />
export const EMAIL_ICON = <FontAwesomeIcon icon={faEnvelope} size={"xs"} />
export const PASSWORD_ICON = <FontAwesomeIcon icon={faLock} size={"xs"} />
export const TICKET_ICON = <FontAwesomeIcon icon={faTicket} size={"xs"} />
export const TRUCK_ICON = <FontAwesomeIcon icon={faTruck} size={"xs"} />
export const LAYER_ICON = <FontAwesomeIcon icon={faLayerGroup} size={"xs"} />
export const FILE_ICON = <FontAwesomeIcon icon={faFileArchive} size={"xs"} />
export const MERGE_ICON = <FontAwesomeIcon icon={faCodeMerge} size={"xs"} />
export const LOCK_ICON = <FontAwesomeIcon icon={faLock} size={"xs"} />
export const SETTINGS_ICON = <FontAwesomeIcon icon={faCog} size={"xs"} />
export const ALERT_ICON = <FontAwesomeIcon icon={faBell} size={"xs"} />
export const SECURE_ICON = <FontAwesomeIcon icon={faUserSecret} size={"xs"} />
export const MULTIPLE_USER_ICON = <FontAwesomeIcon icon={faUsers} size={"xs"} />
export const USER_ICON = <FontAwesomeIcon icon={faUser} size={"xs"} />
export const BILLING_ICON = <FontAwesomeIcon icon={faDollar} size={"xs"} />
export const ELLIPSIS_ICON = <FontAwesomeIcon icon={faEllipsis} size={"xs"} />
export const CLOSE_ICON = <FontAwesomeIcon icon={faClose} size={"xs"} />
export const EYE_ICON = <FontAwesomeIcon icon={faEye} size={"xs"} />
export const EYE_ICON_LARGE = <FontAwesomeIcon icon={faEye} size={"lg"} />
export const CARET_DOWN_ICON = <FontAwesomeIcon icon={faCaretDown} size={"xs"} />
export const CARET_UP_ICON = <FontAwesomeIcon icon={faCaretUp} size={"xs"} />
export const STAR_ICON = <FontAwesomeIcon icon={faStar} size={"xs"} />
export const TAGS_ICON = <FontAwesomeIcon icon={faTags} size={"xs"} />
export const CALENDAR_ICON = <FontAwesomeIcon icon={faCalendar} size={"xs"} />
export const MONITOR_ICON = <FontAwesomeIcon icon={faWaveSquare} size={"xs"} />
export const CHECKMARK_ICON = <FontAwesomeIcon icon={faCheck} size={"xs"} />
export const REFRESH_ICON = <FontAwesomeIcon icon={faRefresh} size={"xs"} />
export const REFRESH_ICON_SPIN = <FontAwesomeIcon icon={faRefresh} size={"xs"} spin={true} />
export const SEARCH_ICON = <FontAwesomeIcon icon={faSearch} size={"xs"} />
export const ORGANISATION_ICON = <FontAwesomeIcon icon={faGlobe} size={"xs"} />
export const WARNING_ICON = <FontAwesomeIcon icon={faWarning} size={"xs"} />
export const INFO_ICON = <FontAwesomeIcon icon={faInfo} size={"xs"} />
export const QUESTION_MARK_ICON = <FontAwesomeIcon icon={faQuestionCircle} size={"xs"} />
export const INFO_CIRCLE_ICON = <FontAwesomeIcon icon={faInfoCircle} size={"xs"} />
export const UP_ARROW_ICON = <FontAwesomeIcon icon={faArrowUp} size={"xs"} />
export const CLOCK_ICON = <FontAwesomeIcon icon={faClock} size={"xs"} />
export const EDIT_ICON = <FontAwesomeIcon icon={faEdit} size={"xl"} />
export const EDIT_ICON_SMALL = <FontAwesomeIcon icon={faEdit} size={"xs"} />
export const CIRCLE_PLAY = <FontAwesomeIcon icon={faPlay} size={"xs"} />
export const HAMMER_CRASH = <FontAwesomeIcon icon={faHammer} size={"xs"} />
export const TRASH_ICON = <FontAwesomeIcon icon={faTrashCan} size={"xs"} />
export const DOWNLOAD_ICON = <FontAwesomeIcon icon={faDownload} size={"xs"} />
export const PAINTBRUSH_ICON = <FontAwesomeIcon icon={faPaintbrush} size={"xs"} />
export const IMPORT_ICON = <FontAwesomeIcon icon={faFileImport} size={"xs"} />
export const LINK_ICON = <FontAwesomeIcon icon={faExternalLink} size={"xs"} />
export const UPLOAD_ICON = <FontAwesomeIcon icon={faCloudUpload} size={"xs"} />
export const PAPERCLIP_ICON = <FontAwesomeIcon icon={faPaperclip} size={"xs"} />
export const CODE_ICON = <FontAwesomeIcon icon={faCode} size={"xs"} />
export const CREDIT_CARD_ICON = <FontAwesomeIcon icon={faCreditCard} size={"xs"} />
export const FIRST_AID_ICON = <FontAwesomeIcon icon={faFirstAid} size={"xs"} />
export const CHECK_CIRCLE = <FontAwesomeIcon icon={faCheckCircle} size={"xs"} />
export const CLOSE_CIRCLE_ICON = <FontAwesomeIcon icon={faTimesCircle} size={"xs"} />
export const LOADING_SPIN = <FontAwesomeIcon icon={faRefresh} size={"xs"} spin={true} />
export const MERCURY = <FontAwesomeIcon icon={faMercury} size={"xs"} />
export const ELECTRICITY_ICON = <FontAwesomeIcon icon={faBoltLightning} size={"xs"} />