import {Button, Pagination, Text} from "@nextui-org/react";
import {
    ARROW_DOWN_ICON,
    ARROW_LEFT_ICON,
    CLOCK_ICON, CLOSE_CIRCLE_ICON,
    DATABASE_ICON,
    EDIT_ICON_SMALL, INFO_CIRCLE_ICON,
    LINK_ICON,
    PLUS_ICON,
    REFRESH_ICON_SPIN, SEARCH_ICON, SETTINGS_ICON, SHOPPING_CART_ICON, UP_ARROW_ICON
} from "../../../Icons/FontAwesomeIcons";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTikTokDetailedProductStats, useTiktokProduct, useTikTokProductStats} from "../../../Hooks/TiktokProducts";
import {useContext, useEffect, useState} from "react";
import {getPreviousMonthDate, getThreeMonthsAgoDate, getTodayPlusOne, getYearAgoDate} from "../../../Utils/Dates";
import {Checkbox, LoadingOverlay, NumberInput, Select, Tabs} from "@mantine/core";
import {calcMargin, calcNetProfit, getCogsByProduct, getShippingChargesByProduct} from "../../../Utils/Figures";
import {SingleLineChartImpl} from "../../Charts/SingleLineChartImpl";
import {EditCostOfGoodsByProductId} from "../../Common/CostOfGoods/EditCostOfGoodsByProductId";
import {EditShippingChargeProductId} from "../../Modals/ShippingCharges/EditShippingChargeByProductId";
import {EditShippingChargesByProductIdV2} from "../../Common/ShippingChargesV2/EditShippingChargesByProductIdV2";

export const ConfigureProduct = () => {
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams && searchParams.get("tab") && searchParams.get("tab") !== "" ? searchParams.get("tab") : "cogs";

    const { id } = useParams()
    const {isLoading: isLoadingProduct, product} = useTiktokProduct(id)

    return (
        <>
            <LoadingOverlay visible={isLoadingProduct} />
            <div className={"bg-white"}>
                <div className={"shadow-3xl border-b border-t"}>
                    <Button light className={"m-4"} auto={true} onClick={() => navigate(-1)}>
                        <span className={"mr-2"}>
                            {ARROW_LEFT_ICON}
                        </span>
                        Back
                    </Button>
                    <div className={"p-4 pt-4 border-t flex flex-wrap"}>
                        <div className={"lg:w-1/2 w-full flex items-center"}>
                            <div className={"flex items-center gap-4"}>
                                {product.image_url && (
                                    <img src={product.image_url} alt={"image-" + product.id} style={{maxWidth:"40px"}} className={"h-8 rounded-md shadow ml-4 mr-4"} />
                                )}
                                {!product.image_url && (
                                    <div className={"bg-gray-50 h-8 rounded-md shadow ml-4 mr-4 px-4"}></div>
                                )}
                                {product && (
                                    <div>
                                        <Text className={"mb-4 opacity-50"}>
                                           <span className={"mr-2"}>{SETTINGS_ICON}</span>
                                            Cost & Shipping Configuration
                                        </Text>
                                        <Text className={"text-xl font-bold"}>
                                            <span className={"mr-2"}>{SHOPPING_CART_ICON}</span>
                                            {product.name}
                                        </Text>
                                        <Text className={"mt-4 text-xs opacity-50"}>
                                            <span className={"mr-2"}>{ARROW_DOWN_ICON}</span>
                                            Use this configuration screen to record relevant cost of goods details, and shipping details so that we're able to report your profit figures accurately.
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Tabs
                keepMounted={false}
                color="dark"
                className={"bg-white"}
                radius={"md"}
                variant={"pills"}
                className={"bg-white"}
                defaultValue={tab}>
                <Tabs.List className={"bg-white shadow-3xl border py-2 pl-2"}>
                    <Tabs.Tab value="cogs" className="text-md"><span className={"mr-2"}>💰</span> Cost of Goods</Tabs.Tab>
                    <Tabs.Tab value="shipping" className="text-md"><span className={"mr-2"}>🚚</span> Shipping</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel
                    value="cogs">
                    <div className={"bg-white"}>
                        {!isLoadingProduct && product && (
                            <>
                                <div className={"border-b"}>
                                    <EditCostOfGoodsByProductId disableHeader={true} product={product} />
                                </div>
                            </>
                        )}
                    </div>
                </Tabs.Panel>
                <Tabs.Panel
                    value="shipping">
                    <div className={"bg-white"}>
                        {!isLoadingProduct && product && (
                            <>
                                <div className={"border-b"}>
                                    <EditShippingChargesByProductIdV2 disableHeader={true} product={product} />
                                </div>
                            </>
                        )}
                    </div>
                </Tabs.Panel>
            </Tabs>
        </>
    )
}