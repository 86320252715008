export const ShippingFees = () => {
    return (
        <>
            <div>
                <div className={"text-3xl font-bold"}>Setup Shipping Fees</div>
                <div className={"opacity-50 text-md"}>Learn how to setup shipping fee records for manually fulfilled products</div>

                <div className={"mt-8 mb-8 opacity-50 text-md"}>
                 TikTok provides two ways for you to fulfill your orders (with a third to be released soon): <b>Seller Fulfilment</b> and <b>Automatic Label Printing (typically Evri/Hermes in the UK).</b>
                </div>

                <div className={"mb-8 opacity-50 text-md"}>
                    For seller-fulfilled products (products where, you the seller, manually prints a shipping label for orders) we require that you enter shipping fee records. You can setup date-based records to adjust shipping fees based on specific dates. Let's show you how to get that setup for a product.
                </div>

                <div
                    style={{position: "relative", paddingBottom: "calc(52.050264550264544% + 41px)", height: "0", width: "100%"}}>
                    <iframe src="https://demo.arcade.software/RbIcrEVpqNGEKlZ88947?embed" frameBorder="0" loading="lazy"
                            webkitallowfullscreen mozallowfullscreen allowFullScreen
                            style={{position: "absolute", top: "0", left:" 0", width: "100%", height: "100%",colorScheme: "light"}}
                            title="ShopFlow"></iframe>
                </div>
            </div>
        </>
    )
}