import {useContext, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {AppContext} from "../Contexts/App";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";
import {request, requestStats} from "../Interceptors/Request";

export const useTikTokDetailedProductStats = (from, to, productId, selectedShopIds) => {
    const {user, clearAppContext, settlementsOnly} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listTikTokProductDetailedStatsByProductId', settlementsOnly, from, to, user, productId, selectedShopIds],
        () => requestStats({
            url: `/tiktok-products/orchestrators/detailed-stats-by-product-id` +
                `?from=${from}&to=${to}&productId=${productId}` +
                `${selectedShopIds && selectedShopIds.length > 0 ? "&shopIds=" + selectedShopIds.join(",") : ""}`,
            method: "GET"
        }, clearAppContext, settlementsOnly)
            .then((data) => data)
            .catch((error) => error),
        {
            staleTime: DEFAULT_STALE_TIME,
            enabled: (productId && productId != "" ? true : false)}
    )
    const [dataAsSkuMap, setDataAsSkuMap] = useState(null)

    useEffect(() => {
        if (data && data.data) {
            const newMap = {}
            for (const skuItem of data.data) {
                newMap[skuItem["SKU"]] = skuItem
            }
            setDataAsSkuMap(newMap)
        }
    }, [data])

    return {
        skuToTotalsMap: dataAsSkuMap,
        rawData: data ? data.data : [],
        isLoading,
        error
    }
}

export const useTikTokProductStats = (from, to, productIds, selectedShopIds) => {
    const {user, clearAppContext, settlementsOnly} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listTikTokProductStats', settlementsOnly, from, to, user, productIds, selectedShopIds],
        () => requestStats({
            url: `/tiktok-products/orchestrators/stats?from=${from}&to=${to}` +
                `${productIds && productIds.length > 0 ? "&productIds=" + productIds.join(",") : ""}` +
                `${selectedShopIds && selectedShopIds.length > 0 ? "&shopIds=" + selectedShopIds.join(",") : ""}`,
            method: "GET"
        }, clearAppContext, settlementsOnly)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME,
            enabled: (productIds && productIds.length > 0 ? true : false)}
    )
    const [dataAsProductIdMap, setDataAsProductIdMap] = useState(null)

    useEffect(() => {
        if (data && data.data) {
            const newMap = {}
            for (const product of data.data) {
                newMap[product["ProductId"]] = product
            }
            setDataAsProductIdMap(newMap)
        }
    }, [data])

    return {
        productIdToTotalsMap: dataAsProductIdMap,
        rawData: data ? data.data : [],
        isLoading,
        error
    }
}

export const useTiktokProduct = (productId) => {
    const {user, clearAppContext, settlementsOnly} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['getTiktokProduct', user, productId],
        () => requestStats({
            url: `/tiktok-products/orchestrators/retrieve?productId=${productId}`,
            method: "GET"
        }, clearAppContext, settlementsOnly)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )

    return {
        product: data ? data.data : [],
        isLoading,
        error
    }
}

export const useTikTokProducts = (calendarPeriod, activeOnly, page, limit, selectedShopIds) => {
    const {user, clearAppContext, settlementsOnly} = useContext(AppContext)
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const {isLoading, error, data} = useQuery(
        ['listTikTokProducts', calendarPeriod, activeOnly, settlementsOnly, user, page, limit, selectedShopIds],
        () => requestStats({
            url: (activeOnly ? `/tiktok-products/orchestrators/list-active` : `/tiktok-products/orchestrators/list`) + `?page=${page}&limit=${limit}` +
                `${selectedShopIds && selectedShopIds.length > 0 ? "&shopIds=" + selectedShopIds.join(",") : ""}` +
                `${calendarPeriod ? '&period=' + calendarPeriod : ''}`,
            method: "GET"
        }, clearAppContext, settlementsOnly)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )

    useEffect(() => {
        if (data) {
            const numberOfPages = parseInt(data.headers["number_of_pages"])
            const newTotal = parseInt(data.headers["total_count"])
            setTotal(newTotal)
            setTotalPages(numberOfPages)
        }
    }, [data])

    return {
        total: total,
        totalPages: totalPages,
        products: data ? data.data : [],
        isLoading,
        error
    }
}