import {useBulkRangeStats} from "../../../Hooks/Statistics";
import {orderStatuses} from "./DashboardTilesSection";
import {getStartOfToday, getTodayPlusOne} from "../../../Utils/Dates";
import {useContext, useEffect} from "react";
import {AppContext} from "../../../Contexts/App";



export const DashboardSelectedRange = ({selectedShopIds, from, to, orderStatuses, setTotalShippingFees, setVAT, setVatSales, setVatPurchases, setPlatformCommiss, setAffiliateComiss, setTotalCancelledSales, setTotalCancelledUnits, setTotalSales, setTotalUnits, setTotalCOGS}) => {
    const {includeShippingAsCost, shippingPercentage} = useContext(AppContext)
    const {rawData} = useBulkRangeStats(
        selectedShopIds,
        [{
            label: "1",
            from: from,
            to: to
        }],
        orderStatuses,
        includeShippingAsCost,
        shippingPercentage)

    useEffect(() => {
        let totalSales = Number(0)
        let totalUnits = Number(0)
        let totalCogs = Number(0)
        let totalCancelledUnits = Number(0)
        let totalCancelledSales = Number(0)
        let totalShippingFees = Number(0)
        let totalAffiliateFees = Number(0)
        let totalPlatformFees = Number(0)
        let totalVAT = Number(0)

        if (rawData) {
            for(const item of rawData) {
                totalCancelledSales = (item.TotalSalesCancelled ? item.TotalSalesCancelled : 0) + totalCancelledSales
                totalCancelledUnits = (item.TotalUnitsCancelled ? item.TotalUnitsCancelled : 0) + totalCancelledUnits
                totalSales = (item.TotalSales ? item.TotalSales : 0) + totalSales
                totalUnits =(item.TotalUnits ? item.TotalUnits : 0) + totalUnits
                totalCogs = (item.TotalCogs ? item.TotalCogs : 0) + totalCogs
                totalShippingFees = (item.TotalShippingFee ? item.TotalShippingFee : 0) + totalShippingFees
                totalVAT = (item.TotalVAT ? item.TotalVAT : 0) + totalVAT
                totalAffiliateFees = (item.TotalAffiliateCommission ? item.TotalAffiliateCommission : 0) + totalAffiliateFees
                totalPlatformFees = (item.TotalPlatformFee ? item.TotalPlatformFee : 0) + totalPlatformFees
            }
        }

        const salesVAT = totalVAT
        const purchasesVAT = salesVAT > 0 ? (totalShippingFees / 6) + (totalAffiliateFees / 6) + (totalPlatformFees / 6) : 0
        setVatSales(totalVAT)
        setVatPurchases(purchasesVAT)
        setVAT(salesVAT - purchasesVAT)
        setTotalShippingFees(totalShippingFees)
        setTotalCancelledSales(totalCancelledSales)
        setTotalCancelledUnits(totalCancelledUnits)
        setTotalSales(totalSales)
        setTotalUnits(totalUnits)
        setTotalCOGS(totalCogs)
        setAffiliateComiss(totalAffiliateFees)
        setPlatformCommiss(totalPlatformFees)
    }, [rawData])

    return (
        <>
        </>
    )
}