import {LoadingOverlay} from "@mantine/core";
import {Button, Pagination, Switch, Table, Text} from "@nextui-org/react";
import {
    ALERT_ICON,
    CHECK_CIRCLE, CHECKMARK_ICON,
    CLOCK_ICON,
    CLOSE_CIRCLE_ICON, CLOSE_ICON, DATABASE_ICON,
    EDIT_ICON_SMALL, LINK_ICON,
    PLUS_ICON,
    TICKET_ICON,
    TRASH_ICON
} from "../../Icons/FontAwesomeIcons";
import {useExpenses} from "../../Hooks/Expenses";
import {useContext, useEffect, useState} from "react";
import moment from "moment";
import {showNotification} from "@mantine/notifications";
import {request} from "../../Interceptors/Request";
import {AppContext} from "../../Contexts/App";
import {useQueryClient} from "@tanstack/react-query";
import {CreateExpense} from "../Modals/Expenses/CreateExpense";
import {UpdateExpense} from "../Modals/Expenses/UpdateExpense";
import {useNotifications} from "../../Hooks/Notifications";
import {useNavigate, useSearchParams} from "react-router-dom";

export const AllNotifications = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const excludeAcknowledged = searchParams.get("exclude_acknowledged")
    const [all, setAll] = useState(true)
    const queryClient = useQueryClient()
    const {clearAppContext} = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const {isLoading, notifications} = useNotifications(all)
    const navigate = useNavigate()

    useEffect(() => {
        let newAllValue = true
        if (excludeAcknowledged === "true") {
            newAllValue = false
        }
        setAll(newAllValue)
    }, [excludeAcknowledged])

    const handleError = (response) => {
        let message = "Unable to process operation";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const acknowledge = (notificationId) => {
        setIsSaving(true)
        request({
            url: `/notifications/orchestrators/acknowledge?notificationId=${notificationId}`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['getNotifications'] }).finally(() => {})
                    showNotification({
                        title: "Deleted!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    return (
        <>
            <LoadingOverlay visible={isSaving} />
            <div className={"w-full flex flex-wrap bg-white bg-white p-4 shadow-3xl border-b border-t"}>
                <div className={"lg:w-1/2 w-full flex items-center"}>
                    <div>
                        <div className={"flex items-center gap-4"}>
                            <div className={"text-xl font-bold"}>
                                <span className={"mr-2"}>{ALERT_ICON}</span>
                                Notifications
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                    <div className={"flex items-center bg-gray-50 p-4 rounded-xl"}>
                        <Switch
                            size={"lg"}
                            shadow
                            color="success"
                            checked={all}
                            onChange={(value) => {
                                setAll(value.target.checked)
                            }}/>
                        <Text className={"ml-4"}>Show acknowledged notifications</Text>
                    </div>
                </div>
            </div>
            <div className={"bg-white"}>
                {(!isLoading && notifications && notifications.length <= 0) && (
                    <div className={"p-20 text-xl text-center w-full flex items-center justify-center"}>
                        <div>
                            <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                            <Text className={"text-2xl font-bold my-4"}>No notifications</Text>
                        </div>
                    </div>
                )}
                {(!isLoading && notifications && notifications.length > 0) && (
                    <div className={"-mx-4"}>
                        <Table
                            lined
                            headerLined
                            shadow={false}
                            aria-label="Example table with static content"
                            css={{
                                height: "auto",
                                width: "100% !important",
                                marginTop: "0px",
                                borderRadius: "0px",
                                margin: "0px !important",
                                padding: "0px !important"
                            }}
                        >
                            <Table.Header>
                                <Table.Column><span className={"ml-4"}>DETAILS</span></Table.Column>
                                <Table.Column>ACTION</Table.Column>
                                <Table.Column>ACKNOWLEDGE</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {notifications.map((notification, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            <div className={"pl-4"} style={{maxWidth: "400px"}}>
                                                <Text className={"opacity-50 text-xs mb-2 font-bold"}>
                                                    {moment(notification.created_at).fromNow()}
                                                </Text>
                                                <Text className={"font-semibold mb-4 whitespace-pre-line"}>
                                                    {notification.title}
                                                </Text>
                                                <Text className={"opacity-50 whitespace-pre-line text-sm"}>
                                                    {notification.message}
                                                </Text>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {notification.action === "button" && (
                                                <Button
                                                    auto={true}
                                                    color={"primary"}
                                                    icon={LINK_ICON}
                                                    onClick={() => {
                                                        navigate(notification.action_link)
                                                    }}
                                                >{notification.action_display}</Button>
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"flex gap-2 z-999"}>
                                                <Button
                                                    disabled={notification.acknowledged || isSaving}
                                                    auto={true}
                                                    color={"primary"}
                                                    flat
                                                    icon={CHECKMARK_ICON}
                                                    onClick={() => {
                                                        acknowledge(notification.id)
                                                    }}
                                                >
                                                    {notification.acknowledged && ("Acknowledged")}
                                                    {!notification.acknowledged && ("Acknowledge")}
                                                </Button>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                )}
            </div>
        </>
    )
}