export const SetupDashboard = () => {
    return (
        <>
            <div>
                <div className={"text-3xl font-bold"}>Setup Dashboard</div>
                <div className={"opacity-50 text-md"}>Let's dive into your dashboard</div>

                <div className={"mt-8 mb-8 opacity-50 text-md"}>Please follow our interactive demo below detailing how to configure your dashboard.</div>

                <div
                    style={{position: "relative", paddingBottom: "calc(52.050264550264544% + 41px)", height: "0", width: "100%"}}>
                    <iframe src="https://demo.arcade.software/OUhEkgANlpRBz67zJisY?embed" frameBorder="0" loading="lazy"
                            webkitallowfullscreen mozallowfullscreen allowFullScreen
                            style={{position: "absolute", top: "0", left:" 0", width: "100%", height: "100%",colorScheme: "light"}}
                            title="ShopFlow"></iframe>
                </div>
            </div>
        </>
    )
}