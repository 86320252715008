import {Text} from "@nextui-org/react";
import {EditPlatformFees} from "../../Common/PlatformFees/EditPlatformFees";

export const TiktokPlatformFees = () => {
    return (
        <>

            <div className={"px-4 py-4 w-full flex items-center"}>
                <div className={"w-full flex items-center"}>
                    <div>
                        <Text className={"text-xl font-bold"}>
                            Platform Fees
                        </Text>
                        <Text className={"mt-2 text-sm opacity-50"}>
                            For unsettled (uncompleted) orders we have to estimate your platform fee based on what you've setup on your TikTok seller account. Add your platform fee records here so we can accurately report profit figures.
                        </Text>
                    </div>
                </div>
            </div>
            <div className={"border-b"}>
                <EditPlatformFees />
            </div>
        </>
    )
}