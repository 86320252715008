import {useContext, useState} from "react";
import {useTikTokProducts} from "../../../Hooks/TiktokProducts";
import {useShippedCancelledOrders} from "../../../Hooks/TiktokOrders";
import {getFourYearAgoDate, getPreviousMonthDate, getThreeMonthsAgoDate, getTodayPlusOne} from "../../../Utils/Dates";
import {Alert, Checkbox, LoadingOverlay, Text} from "@mantine/core";
import {Button, Pagination, Table} from "@nextui-org/react";
import {
    calcMargin,
    calcNetProfit,
    cutString,
    getCogsByProduct,
    getShippingChargesByProduct
} from "../../../Utils/Figures";
import {
    CALENDAR_ICON,
    CHECK_CIRCLE, CHECKMARK_ICON,
    CLOSE_CIRCLE_ICON, CLOSE_ICON, DOWNLOAD_ICON,
    EDIT_ICON_SMALL, FILE_ICON, INFO_CIRCLE_ICON,
    LINK_ICON, LIST_ICON, SEARCH_ICON, TRUCK_ICON,
    WARNING_ICON
} from "../../../Icons/FontAwesomeIcons";
import {SingleLineChartImpl} from "../../Charts/SingleLineChartImpl";
import {DatePickerInput} from "@mantine/dates";
import moment from "moment";
import {showNotification} from "@mantine/notifications";
import {request, requestEmpty} from "../../../Interceptors/Request";
import {AppContext} from "../../../Contexts/App";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import {useReimbursementReportRangeStats} from "../../../Hooks/Statistics";

export const ReimbursementReportList = ({onSwitchTab}) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const {clearAppContext} = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(false)
    const [includeExported, setIncludeExported] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(30)
    const [from, setFrom] = useState(moment(getFourYearAgoDate(), "YYYY-MM-DD").toDate())
    const [to, setTo] = useState(moment(getTodayPlusOne(), "YYYY-MM-DD").toDate())
    const {
        isLoading: isLoadingOrders,
        total,
        totalPages,
        orders
    } = useShippedCancelledOrders(moment(from).format("YYYY-MM-DD"), moment(to).format("YYYY-MM-DD"), page, limit, includeExported)
    const {isLoading: isLoadingStatistics, rawData} = useReimbursementReportRangeStats(includeExported, moment(from).format("YYYY-MM-DD"), moment(to).format("YYYY-MM-DD"))

    const pagination = (
        <>
            <div className={"bg-white border-b pb-2 pt-2 px-4 w-full flex items-center justify-start text-lg"}>
                <div className={"lg:w-1/2 w-full flex items-center"}>
                    {orders && orders.length && (
                        <Text className={"m-0 opacity-50 text-xs"}>{orders.length} / {total} total</Text>
                    )}
                </div>
                <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                    {totalPages > 1 && (
                        <div className={"pb-1"}>
                            <Pagination
                                size={"xs"}
                                color={"primary"}
                                page={page}
                                onChange={(page) => setPage(page)}
                                total={totalPages}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )

    const handleError = (response) => {
        let message = "Unable to login with Tiktok";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const exportOrders = () => {
        const fromFormatted = moment(from).format("YYYY-MM-DD")
        const toFormatted = moment(to).format("YYYY-MM-DD")
        setIsLoading(true)
        request({
            url: `/reimbursement-reports/orchestrators/export?includeExported=${includeExported}&from=${fromFormatted}&to=${toFormatted}`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['reimbursementReportStats'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listShippedButCancelledOrders'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listReimbursementReportOutputs'] }).finally(() => {})
                    showNotification({
                        title: "We've started exporting these orders. They'll be ready in a couple of minutes.",
                        icon: CHECKMARK_ICON,
                        color: "green"
                    })
                    if (onSwitchTab) onSwitchTab()
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoading(false))

    }

    return (
        <>
            <LoadingOverlay visible={isLoading || isLoadingOrders || isLoadingStatistics} />
            <div className={"bg-white"}>
                <Alert icon={INFO_CIRCLE_ICON} title="How does this work?">
                    TikTok will potentially issue money-back when you've shipped an order, however its been cancelled and refunded. Filter your data by date range, and hit "export" to generate a CSV report detailing order IDs, and equivalent cost of goods. You can send this CSV file to TikTok and request money-back on valid orders. <br /><br/> <b>The exported CSV file containing your eligible order IDs will be stored on the 'export list' page, click the tab above to switch and view a list of your exports, available for download.</b>
                </Alert>
            </div>
            <div className={"bg-white py-4 px-4 border-b border-t"}>
                <div className={"flex flex-wrap"}>
                    <div className={"lg:w-1/2 w-full"}>
                        <Text className={"mb-4 opacity-50 text-xs font-bold"}>
                            <span className={"mr-2"}>{SEARCH_ICON}</span>
                            REFINE
                        </Text>
                        <Checkbox
                            size={"lg"}
                            className={"my-4"}
                            description={"When you click 'export', we'll mark all orders in that file as 'exported' so you don't have to download them again next time."}
                            label={"Include already exported orders"}
                            defaultValue={includeExported}
                            onChange={(event) => {
                                setIncludeExported(event.target.checked)
                            }}/>
                    </div>
                    <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                        <Button
                            size={"xl"}
                            auto={true}
                            icon={DOWNLOAD_ICON}
                            color={"success"}
                            disabled={isLoading || total === 0}
                            shadow={true}
                            onClick={() => exportOrders()}>
                            Export All to CSV
                        </Button>
                    </div>
                </div>
                <div className={"flex gap-4"}>
                    <DatePickerInput
                        radius={"md"}
                        label={"From"}
                        size={"md"}
                        style={{width: "100%"}}
                        placeholder="From date"
                        valueFormat="YYYY-MM-DD"
                        icon={CALENDAR_ICON}
                        defaultValue={from}
                        className={"mr-4"}
                        onChange={(date) => setFrom(date)}
                    />
                    <DatePickerInput
                        label={"To"}
                        size={"md"}
                        radius={"md"}
                        icon={CALENDAR_ICON}
                        style={{width: "100%"}}
                        placeholder="To date"
                        defaultValue={to}
                        valueFormat="YYYY-MM-DD"
                        onChange={(date) => setTo(date)}
                    />
                </div>
            </div>
            {!isLoadingStatistics && rawData && (
                <div>
                    <div className={"px-4 pt-8 border-t"}>
                        <Text className={"opacity-75 text-xl font-bold"}>
                            <span className={"mr-2"}>{SEARCH_ICON}</span>
                            Here's what you could save by submitting a reimbursement report:
                        </Text>
                        <Text className={"opacity-50 text-xs"}>
                            * This does not include previously exported orders.
                        </Text>
                    </div>
                    <div className={"flex flex-wrap"}>
                        <div className={"lg:w-1/3 border-b border-r py-8 px-4"}>
                            <div className={"opacity-50 text-xl font-semibold"}>
                                Cost of Goods
                            </div>
                            <div className={"text-3xl font-bold"}>
                                £ {rawData.TotalCogs.toFixed(2)}
                            </div>
                        </div>
                        <div className={"lg:w-1/3 border-b border-r py-8 px-4"}>
                            <div className={"opacity-50 text-xl font-semibold"}>
                                Shipping Fees
                            </div>
                            <div className={"text-3xl font-bold"}>
                                £ {rawData.TotalShippingFee.toFixed(2)}
                            </div>
                        </div>
                        <div className={"lg:w-1/3 border-b border-r py-8 px-4"}>
                            <div className={"opacity-50 text-xl font-semibold"}>
                                Platform Fees
                            </div>
                            <div className={"text-3xl font-bold"}>
                                £ {rawData.TotalPlatformFee.toFixed(2)}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && orders && orders.length > 0 && (
                <div className={"bg-white"}>
                    <div className={"border-t"}></div>
                    {pagination}
                    <div className={"-mx-4"}>
                        <Table
                            lined
                            headerLined
                            aria-label="Example table with static content"
                            css={{
                                height: "auto",
                                width: "100% !important",
                                marginTop: "0px",
                                borderRadius: "0px",
                                margin: "0px !important",
                                padding: "0px !important"
                            }}
                        >
                            <Table.Header>
                                <Table.Column width="200px">
                                    <div className={"pl-4"}>ORDER ID</div>
                                </Table.Column>
                                <Table.Column width="500px">REASON</Table.Column>
                                <Table.Column width="100px">TOTAL</Table.Column>
                                <Table.Column width="100px">CANCELLED BY</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {orders.map((order, index) => (
                                    <Table.Row
                                        key={order.id}>
                                        <Table.Cell>
                                            <div className={"ml-4"}>
                                                <div className={"font-semibold"}>
                                                    {order.id}
                                                </div>
                                                <div className={"text-xs opacity-50"}>
                                                    {moment(order.create_time).format("LL")}
                                                </div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"opacity-75"}>
                                                {order.cancel_reason}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                £ {order.total_amount.toFixed(2)}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {order.cancel_user === "BUYER" && (
                                                <div className={"mr-4 bg-blue-50 capitalize p-4 py-1 rounded-xl text-center w-auto"}>
                                                    {order.cancel_user.toLowerCase()}
                                                </div>
                                            )}
                                            {order.cancel_user === "SYSTEM" && (
                                                <div className={"mr-4 bg-gray-100 capitalize p-4 py-1 rounded-xl text-center w-auto"}>
                                                    {order.cancel_user.toLowerCase()}
                                                </div>
                                            )}
                                            {order.cancel_user === "SELLER" && (
                                                <div className={"mr-4 bg-green-50 capitalize p-4 py-1 rounded-xl text-center w-auto"}>
                                                    {order.cancel_user.toLowerCase()}
                                                </div>
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                    {pagination}
                </div>
            )}
            {(!isLoading && orders && orders.length === 0) && (
                <div className={"text-black text-opacity-20 font-medium bg-white p-4"}>
                    <Text c={"dimmed"}>
                        <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                        No eligible orders found.
                    </Text>
                </div>
            )}
        </>
    )
}