import {useContext} from "react";
import {AppContext} from "../../../Contexts/App";
import {Button, Container, Divider, Text} from "@nextui-org/react";
import {
    ALERT_ICON,
    ARROW_LEFT_ICON,
    ARROW_RIGHT_ICON_XS, CHECKMARK_ICON,
    CLOCK_ICON, INFO_CIRCLE_ICON, LINK_ICON,
    PIE_CHART_ICON,
    REFRESH_ICON
} from "../../../Icons/FontAwesomeIcons";
import {ProductList} from "../../Common/ProductList";
import {useQueryClient} from "@tanstack/react-query";
import {Alert, LoadingOverlay, Select, Tabs} from "@mantine/core";
import {DayByDayAggregate} from "../../Common/DayByDayAggregate";
import {DashboardChartsSection} from "./DashboardChartsSection";
import {DashboardTilesSection} from "./DashboardTilesSection";
import {useNotifications} from "../../../Hooks/Notifications";
import {useNavigate} from "react-router-dom";
import {NewNotifications} from "../../Common/NewNotifications";

export const Dashboard = () => {
    const {user, clearAppContext, dashboardTab, setDashboardTab} = useContext(AppContext)

    return (
        <div>
            <NewNotifications />
            <div className={"bg-white shadow p-4"}>
                <div className={"flex"}>
                    <div className={"lg:w-1/2 w-full flex items-center"}>
                        <div>
                            <div className={"text-xl font-bold font-heading"}>
                                <span className={"mr-2"}>
                                    {PIE_CHART_ICON}
                                </span>
                                Dashboard
                            </div>
                            <Text className={"text-xs opacity-50"}>
                                Analytics updated in real-time.
                            </Text>
                        </div>
                    </div>
                    <div className={"lg:w-1/2 w-full flex justify-end"}>
                        {/*<Alert className={"mt-4"} icon={INFO_CIRCLE_ICON} title="What's the difference between unsettled, and settled data?">*/}
                        {/*    TikTokAffiliateCommissions provides us with two order data channels: unsettled (excludes affiliate data, order totals can change), and settled (100% accurate completed order data info). We've designed this software so that you can switch between the two seamlessly and get accurate up-to-date info on both real-time order data (unsettled), and completed order data (settled).*/}
                        {/*</Alert>*/}
                    </div>
                </div>
            </div>
            <Tabs
                keepMounted={false}
                color="dark"
                radius={"md"}
                variant={"pills"}
                value={dashboardTab}
                onTabChange={(tab) => {
                    setDashboardTab(tab)
                }}>
                <Tabs.List className={"bg-white shadow-3xl border py-2 pl-2"}>
                    <Tabs.Tab value="charts" className="text-md"><span className={"mr-2"}>📊</span> Charts</Tabs.Tab>
                    <Tabs.Tab value="tiles" className="text-md"><span className={"mr-2"}>📉</span> Tiles</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel
                    value="charts">
                    <DashboardChartsSection />
                </Tabs.Panel>

                <Tabs.Panel value="tiles">
                    <DashboardTilesSection />
                </Tabs.Panel>
            </Tabs>
        </div>
    )
}