import {createContext} from "react";

const defaultAppContext = {
    user: null,
    setUser: () => {},
    token: null,
    setToken: () => {},
    shopAccountIds: null,
    setShopAccountIds: () => {},
    clearAppContext: () => {},
    settlementsOnly: null,
    setSettlementsOnly:  () => {},
    includeShippingAsCost: null,
    setIncludeShippingAsCost:  () => {},
    shippingPercentage: null,
    setShippingPercentage:  () => {},
    calendarPeriod: null,
    settingsTab: null,
    setSettingsTab: () => {},
    dashboardTab: null,
    setDashboardTab: () => {},
    setCalendarPeriod: () => {},
    setIsLoginEnabled: () => {},
    setIsOnboardingMode: () => {},
    setOnboardingState: () => {},
    setOnboardingStatus: () => {},
    subscriptionInactive: false,
    setSubscriptionInactive: () => {},
    subscription: null,
    setSubscription: () => {},
    subscriptionPaymentFailed: false,
    setSubscriptionPaymentFailed: () => {},
}
export const AppContext = createContext(defaultAppContext)

