import {useContext, useState} from "react";
import {getPreviousMonthDate, getTodayPlusOne} from "../../../Utils/Dates";
import {ProductList} from "../../Common/ProductList";
import {Button, Text} from "@nextui-org/react";
import {
    CHECK_CIRCLE,
    CLOCK_ICON,
    CLOSE_ICON,
    DATABASE_ICON, REFRESH_ICON,
    SEARCH_ICON,
    STORE_ICON,
    TICKET_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Loader, LoadingOverlay, MultiSelect, Select, Tabs} from "@mantine/core";
import {DashboardChartsSection, orderStatuses} from "../Dashboard/DashboardChartsSection";
import {useTiktokShops} from "../../../Hooks/TiktokShops";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {AppContext} from "../../../Contexts/App";

export const Products = () => {
    const {clearAppContext} = useContext(AppContext)
    const {shops, isLoading: isLoadingShops} = useTiktokShops()
    const [from, setFrom] = useState(getPreviousMonthDate())
    const [to, setTo] = useState(getTodayPlusOne())
    const [selectedShopIds, setSelectedSelectedShopIds] = useState(null)
    const [syncing, setSyncing] = useState(false)

    const handleError = (response) => {
        let message = "Unable to sync";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const fireSync = () => {
        setSyncing(true)
        request({
            url: `/sync-products`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    showNotification({
                        title: "We've started syncing!",
                        message: "It shouldn't take any longer than 5-10 minutes.",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setSyncing(false))
    }

    return (
        <>
            <LoadingOverlay visible={syncing} />
            <div className={"bg-white p-4 shadow-3xl border-b border-t"}>
                <div className={"flex items-center gap-4"}>
                    <div className={"text-xl font-bold"}>
                        <span className={"mr-2"}>{DATABASE_ICON}</span>
                        Products
                    </div>
                    <Text className={"text-sm opacity-50"}>
                        <span className={"mr-2"}>{CLOCK_ICON}</span>
                        Last 30 days
                    </Text>
                </div>
                <Text className={"text-xs opacity-50"}>
                    Birds eye view of your TikTok product catalogue.
                </Text>
            </div>
            <Tabs
                keepMounted={false}
                color="dark"
                radius={"md"}
                variant={"pills"}
                defaultValue="overview">
                <Tabs.List className={"bg-white shadow-3xl border py-2 pl-2"}>
                    <Tabs.Tab value="overview" className="text-md"><span className={"mr-2"}>💰</span> Overview</Tabs.Tab>
                    {/*<Tabs.Tab disabled value="charts" className="text-md"><span className={"mr-2"}>📉</span> Trends</Tabs.Tab>*/}
                </Tabs.List>

                <Tabs.Panel
                    value="overview">
                    <div className={"bg-white p-4 shadow-3xl border"}>
                        <Text className={"mb-2 opacity-50 text-xs font-bold"}>
                            <span className={"mr-2"}>{SEARCH_ICON}</span>
                            REFINE
                        </Text>
                        <div className={"flex flex-wrap"}>
                            <div className={"lg:w-1/4 w-full"}>
                                <MultiSelect
                                    placeholder={shops && shops.length === 1 ? shops[0].shop_name : "Select shop(s)"}
                                    label={"Shops"}
                                    radius={"md"}
                                    icon={STORE_ICON}
                                    disabled={shops && shops.length === 1 ? true : false}
                                    value={selectedShopIds}
                                    onChange={(values) => setSelectedSelectedShopIds(values)}
                                    data={shops.map((shop) => ({label: shop.shop_name, value: shop.id}))} />
                            </div>
                            <div className={"lg:w-1/4 w-full"}>

                            </div>
                            <div className={"lg:w-1/4 w-full"}>

                            </div>
                            <div className={"lg:w-1/4 w-full flex items-center justify-end"}>
                                <Button
                                    disabled={syncing}
                                    color={"success"}
                                    onClick={() => fireSync()}>
                                    <span className={"mr-2"}>{REFRESH_ICON}</span> Sync Products
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={"bg-white"}>
                        <ProductList showChart={true} from={from} to={to} selectedShopIds={selectedShopIds} />
                    </div>
                </Tabs.Panel>
            </Tabs>
        </>
    )
}