import {LoadingOverlay, Modal} from "@mantine/core";
import {useContext, useState} from "react";
import {Button, Switch, Text} from "@nextui-org/react";
import {CHECK_CIRCLE, CHECKMARK_ICON, CLOSE_CIRCLE_ICON, CLOSE_ICON} from "../../../Icons/FontAwesomeIcons";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {AppContext} from "../../../Contexts/App";
import {pricingItems} from "../../../Constants/Constants";
import {useQueryClient} from "@tanstack/react-query";

export const UpgradeAccount = ({open, setOpen}) => {
    const queryClient = useQueryClient()
    const {clearAppContext, subscriptionInactive, subscriptionPaymentFailed} = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(false)
    const [annualMode, setAnnualMode] = useState(true)

    const handleError = (response) => {
        let message = "Unable to perform action";
        if (response && response.data) {
            message = response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const updateCurrentSubscription = (type) => {
        setIsLoading(true)
        request({
            url: `/subscriptions/orchestrators/update?annual=${annualMode}`,
            data: {
                type: type
            },
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    showNotification({
                        title: "Success! We've updated your subscription.",
                        icon: CHECKMARK_ICON,
                        color: "green"
                    })

                    queryClient.clear()
                    setOpen(false)
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoading(false))
    }

    const newSubscribe = (type) => {
        setIsLoading(true)
        request({
            url: `/subscriptions/orchestrators/create?annual=${annualMode}`,
            data: {
                type
            },
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    showNotification({
                        title: "Redirecting you shortly...",
                        icon: CHECKMARK_ICON,
                        color: "green"
                    })

                    window.location = response.data.url;
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoading(false))
    }

    const subscribe = (type) => {
        if (subscriptionPaymentFailed || subscriptionInactive) {
            newSubscribe(type)
        } else {
            updateCurrentSubscription(type)
        }
    }

    return (
        <>
            <Modal zIndex={999}
                   title={(
                       <div className={"w-full text-center items-center justify-center"}>
                           <Text className={"text-xl font-bold"}>
                               Upgrade Account
                           </Text>
                       </div>
                   )}
                   opened={open}
                   fullScreen={true}
                   onClose={() => setOpen(false)}
            >
                <Modal.Body>
                    <LoadingOverlay visible={isLoading} />
                    <div className={"lg:w-2/3 w-full mx-auto block"}>
                        <div className={"flex items-center gap-4 justify-center w-full"}>
                            <Switch
                                disabled={isLoading}
                                size={"lg"}
                                shadow
                                color="success"
                                checked={annualMode}
                                onChange={(value) => {
                                    setAnnualMode(value.target.checked)
                                }}/>
                            <div>
                                <Text className={"text-2xl"}>Annual billing</Text>
                                <Text className={"text-md opacity-50"}>34% off per month</Text>
                            </div>
                        </div>
                        <div className={"mt-4 flex gap-4"}>
                            {pricingItems.map((item, index) => (
                                <div key={index} className={"rounded-xl text-center border pt-10 mt-10 lg:w-1/3 w-full"}>
                                    <Text className={"text-3xl font-bold"}>{item.title}</Text>
                                    <Text className={"text-md opacity-50"}>{item.description}</Text>
                                    {item.badge && (
                                        <div className={"text-green-500"}>{item.badge}</div>
                                    )}
                                    <div className={"my-8"}>
                                        {item.features.map((feature, featureIndex) => (
                                            <div className={"text-center pb-4 mb-4 border-b"} key={featureIndex}>
                                                <Text className={"text-md opacity-50"}>
                                                    <span className={"mr-2"}>{feature.icon}</span>
                                                    {feature.title}
                                                </Text>
                                            </div>
                                        ))}
                                    </div>
                                    {annualMode && (
                                        <>
                                            <Text className={"w-full text-center mb-2 font-bold text-xl opacity-75"}>£ {item.priceAnnually.toFixed(2)} / year</Text>
                                            <div className={"w-full text-center mb-4 text-green-500"}>34% off per month</div>
                                        </>
                                    )}
                                    {!annualMode && (
                                        <Text className={"w-full text-center mb-4 font-bold text-xl opacity-75"}>£ {item.priceMonthly.toFixed(2)} / month</Text>
                                    )}
                                    <Button
                                        className={"mt-10"}
                                        style={{width: "100%"}}
                                        size={"xl"}
                                        onClick={() => subscribe(item.id)}
                                        shadow={item.selectShadow}
                                        color={item.selectColor}>
                                        Choose Plan
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}