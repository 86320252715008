import {useSet} from "@uidotdev/usehooks";
import {useSettingItems} from "../../../Hooks/SettingItems";
import {useForm} from "@mantine/form";
import {Alert, Checkbox, LoadingOverlay, NumberInput} from "@mantine/core";
import {
    ARROW_RIGHT_ICON_XS,
    CALENDAR_ICON,
    CHECK_CIRCLE, CLOSE_CIRCLE_ICON,
    CLOSE_ICON,
    WARNING_ICON
} from "../../../Icons/FontAwesomeIcons";
import {DatePickerInput} from "@mantine/dates";
import {useContext, useEffect, useState} from "react";
import {Button, Text} from "@nextui-org/react";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {AppContext} from "../../../Contexts/App";
import {useQueryClient} from "@tanstack/react-query";
import moment from "moment/moment";

export const VAT = ({onSuccess, onboardingMode}) => {
    const queryClient = useQueryClient()
    const {settingItems, isLoading} = useSettingItems(["detail_vat", "detail_vat_start_date", "detail_vat_percentage", "use_tiktok_vat_figures_for_completed_orders"])
    const [isSaving, setIsSaving] = useState(false)
    const {clearAppContext} = useContext(AppContext)

    const form = useForm({
        initialValues: {
            use_tiktok_vat_figures_for_completed_orders: false,
            detail_vat: false,
            detail_vat_start_date: null,
            detail_vat_percentage: 0,
        },

        validate: {
            detail_vat_start_date: (value) =>
                (form.values.detail_vat === true) ?
                    (value && value !== "" ? null : 'Please set a start date.') : null,
            detail_vat_percentage: (value) =>
                (form.values.detail_vat === true) ?
                    (value && value !== "" ? null : 'Please set a VAT %.') : null,
        },
    });

    const handleError = (response) => {
        let message = "Unable to save changes";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const save = () => {
        setIsSaving(true)
        request({
            url: `/setting-items/orchestrators/create-or-update-bulk`,
            method: "POST",
            data: [
                {
                    key: "use_tiktok_vat_figures_for_completed_orders",
                    value: form.values.use_tiktok_vat_figures_for_completed_orders.toString()
                },
                {
                    key: "detail_vat",
                    value: form.values.detail_vat.toString(),
                },
                {
                    key: "detail_vat_start_date",
                    value: form.values.detail_vat_start_date ? moment(form.values.detail_vat_start_date).format("YYYY-MM-DD").toString() : "",
                },
                {
                    key: "detail_vat_percentage",
                    value: form.values.detail_vat_percentage.toString(),
                }
            ]
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['getSettingItemsByKeysCache'] }).finally(() => {})
                    showNotification({
                        title: "Saved!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    if (onSuccess) {
                        onSuccess()
                    }
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    useEffect(() => {
        if (settingItems) {
            if (settingItems.use_tiktok_vat_figures_for_completed_orders) {
                const value = settingItems.use_tiktok_vat_figures_for_completed_orders.value === "false" ? false : true
                form.setValues({use_tiktok_vat_figures_for_completed_orders: value})
            }

            if (settingItems.detail_vat) {
                const value = settingItems.detail_vat.value === "false" ? false : true
                form.setValues({detail_vat: value})
            }

            if (settingItems.detail_vat_start_date && settingItems.detail_vat_start_date.value && settingItems.detail_vat_start_date.value !== "") {
                const value = moment(settingItems.detail_vat_start_date.value, "YYYY-MM-DD").toDate()
                form.setValues({detail_vat_start_date: value})
            }

            if (settingItems.detail_vat_percentage) {
                const value = Number(settingItems.detail_vat_percentage.value)
                form.setValues({detail_vat_percentage: value})
            }
        }
    }, [settingItems])

    return (
        <>
            <LoadingOverlay visible={isLoading} />
            <form onSubmit={form.onSubmit((values) => save())}>
                {!onboardingMode && (
                    <div className={"px-4 py-4 w-full flex items-center"}>
                        <div className={"lg:w-1/2 w-full flex items-center"}>
                            <Text className={"text-xl font-bold"}>
                                VAT Configuration
                            </Text>
                        </div>
                        <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                            <Button disabled={isSaving} shadow color={"success"} icon={CHECK_CIRCLE} type={"submit"} auto={true}>Save Changes</Button>
                        </div>
                    </div>
                )}
                <div className={onboardingMode ? "" : "p-4"}>
                    <Checkbox
                        size={"lg"}
                        description={"If selected, we'll calculate tax based on your start date, and tax rate. "}
                        label={"I am VAT (Sales Tax, GST, etc) Registered"}
                        {...form.getInputProps('detail_vat', { type: 'checkbox' })} />

                    <div className={"mb-10 ml-10 mt-4"}>
                        <DatePickerInput
                            disabled={form.values.detail_vat === false}
                            icon={CALENDAR_ICON}
                            dropdownType={"popover"}
                            size={"lg"}
                            placeholder={"Add date"}
                            label={"Start date"}
                            style={{width: "100%"}}
                            valueFormat="YYYY-MM-DD"
                            {...form.getInputProps('detail_vat_start_date')}
                        />

                        <NumberInput
                            disabled={form.values.detail_vat === false}
                            size={"lg"}
                            min={0}
                            max={100}
                            placeholder={"Add percentage"}
                            label={"VAT %"}
                            style={{width: "100%"}}
                            className={"mt-4"}
                            {...form.getInputProps('detail_vat_percentage')}
                        />
                    </div>

                    <Checkbox
                        size={"lg"}
                        description={"If selected, in 'completed orders' mode we'll use what TikTok sends us in terms of VAT/Sales Tax. If not selected, we'll calculate your VAT/Sales Tax based on the configuration entered above."}
                        label={"Use TikTok's VAT/Sales Tax figures"}
                        {...form.getInputProps('use_tiktok_vat_figures_for_completed_orders', { type: 'checkbox' })} />
                </div>
                {onboardingMode && (
                    <Button
                        className={"mt-8"}
                        style={{width: "100%"}}
                        disabled={isSaving}
                        size={"xl"}
                        color={"success"}
                        type={"submit"}
                        iconRight={ARROW_RIGHT_ICON_XS}>
                        Save & Continue
                    </Button>
                )}
            </form>
        </>
    )
}