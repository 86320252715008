import {Button, Divider, Table} from "@nextui-org/react";
import {
    CHECK_CIRCLE,
    CHECKMARK_ICON,
    CLOSE_CIRCLE_ICON, CLOSE_ICON,
    EDIT_ICON_SMALL, INFO_CIRCLE_ICON, INFO_ICON,
    LINK_ICON,
    PLUS_ICON, TRASH_ICON, WARNING_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Alert, LoadingOverlay, Modal, NumberInput, Text} from "@mantine/core";
import {useCogsByProductId, useCogsValidate, usePresentCogsByProductId} from "../../../Hooks/TiktokProductCogs";
import {useContext, useEffect, useState} from "react";
import {CreateEditCostOfGoods} from "./CreateEditCostOfGoods";
import moment from "moment/moment";
import {request} from "../../../Interceptors/Request";
import {showNotification} from "@mantine/notifications";
import {useQueryClient} from "@tanstack/react-query";
import {AppContext} from "../../../Contexts/App";

const explainData = (data) =>  {
    if (!data || data && data.length === 0) {
        return []
    }
    // Sort the data by the "from" date
    data.sort((a, b) => new Date(a.from) - new Date(b.from));

    let explanations = [];
    let presentData;

    for (let item of data) {
        if (item.present) {
            presentData = item;
            break;
        }
    }

    let currentDate = new Date(data[0].from);
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let startDate = new Date(item.from);
        let endDate = item.to ? new Date(item.to) : null;

        if (item.present && i == 0) {
            // Skip the "onwards" explanation for the first record if it's the "present" record
            currentDate = endDate ? new Date(endDate) : new Date(startDate);
            currentDate.setDate(currentDate.getDate() + 1);
            continue;
        }

        // Check for gaps between the previous date and the current start date
        if (currentDate < startDate) {
            let gapEndDate = new Date(startDate);
            gapEndDate.setDate(gapEndDate.getDate() - 1);

            if (presentData) {
                explanations.push(
                    (
                        <>
                            Between <b>{currentDate.toISOString().split('T')[0]} and {gapEndDate.toISOString().split('T')[0]}</b>, the total cost of goods per unit is <b>£{presentData.total_cost_of_goods.toFixed(2)}</b>.
                        </>
                    )
                );
            }
        }

        if (item) {
            explanations.push(
                (
                    <>
                        From <b>{startDate.toISOString().split('T')[0]}</b> <b>{endDate ? ('to ' + endDate.toISOString().split('T')[0]) : 'onwards'}</b>, the total cost of goods per unit is <b>£{item.total_cost_of_goods.toFixed(2)}</b>.
                    </>
                )
            );
        }

        currentDate = endDate ? new Date(endDate) : new Date(startDate);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    if (currentDate) {
        if (presentData){
            explanations.push((
                <>
                    From <b>{currentDate.toISOString().split('T')[0]}</b> onwards, the total cost of goods per unit is <b>£{presentData.total_cost_of_goods.toFixed(2)}</b>.
                </>
            ));
        }
    }

    return explanations;
}

export const EditCostOfGoodsByProductId = ({disableHeader, product}) => {
    const {clearAppContext} = useContext(AppContext)
    const queryClient = useQueryClient()
    const {cogs, isLoading: isLoadingCogs} = useCogsByProductId(product)
    const {issues, isLoading: isLoadingValidation} = useCogsValidate(product)
    const [showNewCogs, setShowNewCogs] = useState(false)
    const [editingCog, setEditingCog] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)

    const handleError = (response) => {
        let message = "Unable to delete cost of goods";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const deleteCogs = (costOfGoodsId) => {
        setIsDeleting(true)
        request({
            url: `/tiktok-products-cogs/orchestrators/delete?cogsId=${costOfGoodsId}`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['listPresentCogsByProductId'] }).finally(() => {})
                    queryClient.invalidateQueries({queryKey: ['listTikTokProducts']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listTikTokProductStats']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listTikTokProductDetailedStatsByProductId']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listAllStats']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listCogsByProductId']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['getTiktokProduct']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['validateCogsByProductId']}).finally(() => {
                    })
                    showNotification({
                        title: "Deleted!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsDeleting(false))
    }

    useEffect(() => {

    })

    return (
        <>
            {product && (
                <>
                    {showNewCogs && (
                        <Modal zIndex={999}
                               opened={true}
                               closeButton
                               fullScreen={true}
                               onClose={() => setShowNewCogs(false)}
                        >
                            <Modal.Body>
                                {(!cogs || (cogs && cogs.length === 0)) && (
                                    <div>
                                        <div className={"mb-2 font-medium text-black text-3xl"}>
                                            <span className={"mr-4 opacity-50"}>{PLUS_ICON}</span>
                                            First, let's create a default <b>Cost of Goods</b> record
                                        </div>
                                        <div className={"mb-10 opacity-50 text-black text-xl"}>
                                            Once we have your "default" cost of goods figures, you will be able to add back-dated cost of goods adjustment records to ensure profit figures are accurately reported.
                                        </div>
                                    </div>
                                )}

                                {((cogs && cogs.length > 0)) && (
                                    <div>
                                        <div className={"mb-10 font-medium text-black text-3xl"}>
                                            <span className={"mr-4 opacity-50"}>{PLUS_ICON}</span>
                                            Create Cost of Goods
                                        </div>
                                        <div className={"mb-10 opacity-50 text-black text-xl"}>
                                            First select a date range for which this cost of goods record should be used, then define your cost elements, and we'll do the rest.
                                        </div>
                                    </div>
                                )}

                                <CreateEditCostOfGoods
                                    live={(!cogs || (cogs && cogs.length === 0))}
                                    isNew={true}
                                    editingCog={true}
                                    productId={product ? product.id : null}
                                    costOfGoods={null}
                                    onDelete={() => {
                                        setShowNewCogs(false)
                                    }}
                                    onSave={() => {
                                        setShowNewCogs(false)
                                    }}
                                />
                            </Modal.Body>
                        </Modal>
                    )}
                    {editingCog && editingCog.id && (
                        <Modal zIndex={999}
                               opened={true}
                               closeButton
                               fullScreen={true}
                               onClose={() => setEditingCog(false)}
                        >
                            <Modal.Body>
                                <div className={"mb-10 font-medium text-black text-3xl"}>
                                    <span className={"mr-4 opacity-50"}>{PLUS_ICON}</span>
                                    Edit Cost of Goods
                                </div>
                                <CreateEditCostOfGoods
                                    isNew={false}
                                    productId={product ? product.id : null}
                                    key={editingCog.id}
                                    onSave={() => {
                                        setEditingCog(null)
                                    }}
                                    costOfGoods={{
                                        id: editingCog.id,
                                        from: new Date(editingCog.from),
                                        to: editingCog.to ? new Date(editingCog.to) : null,
                                        present: editingCog.present,
                                        elements: editingCog.edges.cost_of_goods_range_items,
                                    }} />
                            </Modal.Body>
                        </Modal>
                    )}
                    <LoadingOverlay visible={isLoadingValidation || isLoadingCogs || isDeleting}/>
                    {(!isLoadingCogs && !cogs) || (cogs && cogs.length === 0) && (
                        <div className={"m-4 rounded-xl py-24 px-4 bg-yellow-50 flex items-center justify-center"}>
                            <div className={"w-full"}>
                                <span className={"text-yellow-500 mb-4 w-full justify-center flex text-3xl"}>{WARNING_ICON}</span>
                                <Text className={"font-bold mb-4 w-full flex justify-center text-3xl"}>Missing Cost of Goods Information</Text>
                                <Text className={"w-full justify-center flex"}>Enter your cost of goods details so we're able to accurately report profit figures.</Text>
                                <div className={"mt-8 w-full flex items-center justify-center"}>
                                    <Button
                                        color={"primary"}
                                        icon={PLUS_ICON}
                                        onClick={() => setShowNewCogs(true)}
                                    >
                                        Get Started
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {cogs && cogs.length > 0 && (
                        <>
                            <Alert color={"blue"} icon={INFO_CIRCLE_ICON} title={"Here's how you've configured your cost of goods records:"}>

                                <ul>
                                    {explainData(cogs).map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </Alert>
                            {!isLoadingValidation && issues && issues.length > 0 && (
                                <div className={"bg-red-50 p-4 m-4 mb-0 rounded-xl"}>
                                <span className={"text-red-500"}>
                                    <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                                    Your cost of goods might not be setup correctly.
                                </span>
                                    <ul>
                                        {issues.map((issue, index) => (
                                            <ol key={index}>
                                                {issue}
                                            </ol>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {/*{presentCostOfGoods && (*/}
                            {/*    <div className={"border-b flex flex-wrap"}>*/}
                            {/*        <div className={"w-full flex items-center p-4 pb-0"}>*/}
                            {/*            <div className={"w-4 h-4 mr-4 rounded-full bg-green-500"}></div>*/}
                            {/*            <div>*/}
                            {/*                <Text className={"text-xl font-bold"}>*/}
                            {/*                    Live*/}
                            {/*                </Text>*/}
                            {/*                <Text className={"text-xs opacity-50"}>*/}
                            {/*                    Manage your most up-to-date cost of goods information.*/}
                            {/*                </Text>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className={"bg-green-50 border-t mt-4 w-full flex items-center p-4"}>*/}
                            {/*            <div className={"lg:w-1/3 w-full mr-8"}>*/}
                            {/*                <Text>ID</Text>*/}
                            {/*                <b>{presentCostOfGoods.id}</b>*/}
                            {/*            </div>*/}
                            {/*            <div className={"lg:w-1/3 w-full mr-8"}>*/}
                            {/*                <Text>Date Range</Text>*/}
                            {/*                {cogs.length === 1 && (*/}
                            {/*                    <>*/}
                            {/*                    <span className={"font-bold text-blue-500"}>*/}
                            {/*                        ~*/}
                            {/*                    </span>*/}
                            {/*                        <span className={"mx-4 text-black opacity-50"}>*/}
                            {/*                        to*/}
                            {/*                    </span>*/}
                            {/*                        <span className={"font-bold text-blue-500"}>*/}
                            {/*                        ~*/}
                            {/*                    </span>*/}
                            {/*                    </>*/}
                            {/*                )}*/}
                            {/*                {cogs.length > 1 && (*/}
                            {/*                    <div>*/}
                            {/*                      <span className={"font-bold"}>*/}
                            {/*                      {moment(new Date(presentCostOfGoods.from)).format("YYYY-DD-MM")}*/}
                            {/*                  </span>*/}
                            {/*                        <span className={"mx-2 opacity-50"}>to</span>*/}
                            {/*                        <span className={"font-bold text-blue-500"}>*/}
                            {/*                              Now*/}
                            {/*                            </span>*/}
                            {/*                    </div>*/}
                            {/*                )}*/}
                            {/*            </div>*/}
                            {/*            <div className={"lg:w-1/3 w-full mr-8"}>*/}
                            {/*                <Text b>Cost Per Unit</Text>*/}
                            {/*                <b>£ {presentCostOfGoods.total_cost_of_goods.toFixed(2)}</b>*/}
                            {/*            </div>*/}
                            {/*            <div className={"lg:w-1/3 w-full flex gap-2 flex justify-end"}>*/}
                            {/*                <Button color={"primary"} icon={EDIT_ICON_SMALL} auto={true} flat onClick={() => setEditingCog(presentCostOfGoods)}>Edit</Button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {cogs && cogs.length > 0 && (
                                <div className={"-mx-4"}>
                                    <Table
                                        lined
                                        headerLined
                                        aria-label="Example table with static content"
                                        css={{
                                            height: "auto",
                                            width: "100% !important",
                                            marginTop: "0px",
                                            borderRadius: "0px",
                                            margin: "0px !important",
                                            padding: "0px !important"
                                        }}
                                    >
                                        <Table.Header>
                                            <Table.Column width="25px">
                                                <div className={"pl-4"}>ID</div>
                                            </Table.Column>
                                            <Table.Column width="300px">
                                                TYPE
                                            </Table.Column>
                                            <Table.Column width="100px">
                                                DATE RANGE
                                            </Table.Column>
                                            <Table.Column width="100px">COST PER UNIT</Table.Column>
                                            <Table.Column width="100px">ACTIONS</Table.Column>
                                        </Table.Header>
                                        <Table.Body>
                                            {cogs.map((cog, index) => (
                                                <Table.Row key={cog.id}>
                                                    <Table.Cell>
                                               <span className={"opacity-50 ml-4"}>
                                                   {cog.id}
                                               </span>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {cog.present && (
                                                            <div className={"w-full flex"}>
                                                                <div className={"w-4 h-4 mr-4 rounded-full bg-green-500"}></div>
                                                                <div style={{width: "300px"}}>
                                                                    <Text className={"text-sm font-bold"}>
                                                                        Default
                                                                    </Text>
                                                                    <Text className={"mt-2 text-xs opacity-50 whitespace-pre-line"}>
                                                                        We'll use this as your default cost of goods per unit for this product.
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {!cog.present && (
                                                            <div className={"w-full flex"}>
                                                                <div className={"w-4 h-4 mr-4 rounded-full bg-gray-500"}></div>
                                                                <div>
                                                                    <Text className={"text-sm font-bold"}>
                                                                        Date-based
                                                                    </Text>
                                                                    <Text className={"mt-2 text-xs opacity-50 whitespace-pre-line"}>
                                                                        From <>
                                                  <span className={"font-bold"}>
                                                  {moment(new Date(cog.from)).format("YYYY-DD-MM")}
                                              </span>
                                                                            <span className={"mx-1 opacity-50"}>to</span>
                                                                            <span className={"font-bold"}>
                                                                                {moment(new Date(cog.to)).format("YYYY-DD-MM")}
                                                                            </span>
                                                                        </> we'll use this cost of goods record.
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {cog.present && (
                                                            <>
                                                            <span className={"font-bold text-blue-500"}>
                                                          ~
                                                        </span>
                                                                <span className={"mx-4 text-black opacity-50"}>
                                                          -
                                                        </span>
                                                                <span className={"font-bold text-blue-500"}>
                                                          ~
                                                        </span>
                                                            </>
                                                        )}
                                                        {!cog.present && (
                                                            <>
                                                  <span>
                                                  {moment(new Date(cog.from)).format("YYYY-DD-MM")}
                                              </span>
                                                                <span className={"mx-2 opacity-50"}>to</span>
                                                                <>
                                                                    {moment(new Date(cog.to)).format("YYYY-DD-MM")}
                                                                </>
                                                            </>
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <b>£ {cog.total_cost_of_goods.toFixed(2)}</b>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <div className={"flex gap-2"}>
                                                            <Button color={"primary"} icon={EDIT_ICON_SMALL} auto={true} flat onClick={() => setEditingCog(cog)}/>
                                                            {!cog.present && (
                                                                <Button color={"error"} icon={TRASH_ICON} auto={true} flat
                                                                        onClick={() => {
                                                                            deleteCogs(cog.id)
                                                                            setEditingCog(null)
                                                                        }}/>
                                                            )}
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </div>
                            )}
                            {/*{(!filteredCogs || filteredCogs && filteredCogs.length === 0) && (*/}
                            {/*    <div className={"m-4 rounded-xl py-12 px-4 bg-gray-50 flex items-center justify-center"}>*/}
                            {/*        <div className={"w-full"}>*/}
                            {/*            <span className={"text-blue-500 mb-4 w-full justify-center flex text-3xl"}>{INFO_CIRCLE_ICON}</span>*/}
                            {/*            <Text className={"font-bold mb-4 w-full flex justify-center text-3xl"}>No records found</Text>*/}
                            {/*            <div className={"w-full flex justify-center items-center"}>*/}
                            {/*                <div className={"w-1/2"}>*/}
                            {/*                    <Text className={"justify-center flex text-center"}>That's no problem! We'll always use your "live" cost of goods figures.</Text>*/}
                            {/*                    <Text className={"mt-2 font-bold justify-center flex text-center"}>If you'd ever like us to back-date COGS information, hit the button below to setup a COGS record which defines cost per unit for a specific date range.</Text>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className={"mt-8 w-full flex items-center justify-center"}>*/}
                            {/*                <Button*/}
                            {/*                    icon={PLUS_ICON}*/}
                            {/*                    color={"success"}*/}
                            {/*                    shadow={true}*/}
                            {/*                    onClick={() => setShowNewCogs(true)}*/}
                            {/*                >*/}
                            {/*                    Add Cost of Goods*/}
                            {/*                </Button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            <Button
                                color={"gray"}
                                icon={PLUS_ICON}
                                shadow
                                size={"lg"}
                                style={{width: "100%", borderRadius: "0px"}}
                                onClick={() => setShowNewCogs(true)}
                            >
                                <div>
                                    <Text>New Date-Range: COGS Record</Text>
                                </div>
                            </Button>
                        </>
                    )}
                </>
            )}
        </>
    )
}