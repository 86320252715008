import {AppContext} from "../Contexts/App";
import {useQuery} from "@tanstack/react-query";
import {requestStats} from "../Interceptors/Request";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";
import {useContext, useEffect, useState} from "react";
export const useReimbursementReportOutputs = (page, limit) => {
    const {user, clearAppContext, settlementsOnly} = useContext(AppContext)
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const {isLoading, error, data} = useQuery(
        ['listReimbursementReportOutputs', settlementsOnly, user, page, limit],
        () => requestStats({
            url: `reimbursement-reports/orchestrators/list?page=${page}&limit=${limit}`,
            method: "GET",
        }, clearAppContext, settlementsOnly)
            .then((data) => data)
            .catch((error) => error),
        {refetchInterval: 10000}
    )

    useEffect(() => {
        if (data) {
            const numberOfPages = parseInt(data.headers["number_of_pages"])
            const newTotal = parseInt(data.headers["total_count"])
            setTotal(newTotal)
            setTotalPages(numberOfPages)
        }
    }, [data])

    return {
        total: total,
        totalPages: totalPages,
        reports: data ? data.data : [],
        isLoading,
        error
    }
}