import {useForm} from "@mantine/form";
import {
    ARROW_LEFT_ICON,
    CHECK_CIRCLE,
    CLOSE_CIRCLE_ICON,
    CLOSE_ICON,
    EDIT_ICON_SMALL, INFO_CIRCLE_ICON,
    PLUS_ICON,
    TRASH_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Button} from "@nextui-org/react";
import {DatePickerInput} from "@mantine/dates";
import {Alert, Checkbox, LoadingOverlay, NumberInput, Text, TextInput} from "@mantine/core";
import {request} from "../../../Interceptors/Request";
import {showNotification} from "@mantine/notifications";
import {useQueryClient} from "@tanstack/react-query";
import {useContext, useState} from "react";
import {AppContext} from "../../../Contexts/App";
import moment from "moment";

export const CreateEditPlatformFees = ({live, isNew, platformFee, onDelete, onSave}) => {
    const [isSaving, setIsSaving] = useState(false)
    const {clearAppContext} = useContext(AppContext)
    const queryClient = useQueryClient()
    const [error, setError] = useState(null)
    const form = useForm({
        initialValues: {
            present: platformFee && platformFee.present ? platformFee.present : (!!live),
            from: platformFee && platformFee.from ? platformFee.from : new Date(),
            to: platformFee && platformFee.to ? platformFee.to : new Date(),
            total_platform_fee_as_percentage: platformFee && platformFee.total_platform_fee_as_percentage ? platformFee.total_platform_fee_as_percentage : 0
        },

        validate: {
            from: (value) => {
                if(form.values.present) {
                    return null
                }

                if(!value || value === "") {
                    return 'Invalid from date';
                }

                if (value >= form.values.to) {
                    return 'Invalid: needs to be before "to" date.';
                }

                return null
            },
            to: (value) => {
                if(form.values.present) {
                    return null
                }

                if(!value || value === "") {
                    return 'Invalid to date';
                }

                if (value <= form.values.from) {
                    return 'Invalid: needs to be after "from" date.';
                }

                return null
            },
            total_platform_fee_as_percentage: (value) => value && value > 0 ? null : 'Amount must be greater than zero'
        },
    });

    const handleError = (response) => {
        let message = "Unable to create or edit shipping charges";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        setError(message)
    }

    const save = () => {
        setIsSaving(true)
        request({
            url: (platformFee && platformFee.id !== 0) ?
                `/tiktok-platform-fees/orchestrators/update` : `/tiktok-platform-fees/orchestrators/create`,
            method: "POST",
            data: {
                id: platformFee && platformFee.id ? platformFee.id : null,
                present: form.values.present,
                total_platform_fee_as_percentage: form.values.total_platform_fee_as_percentage,
                from: form.values.from ? moment(form.values.from).format('YYYY-MM-DD') : "2020-02-02",
                to: form.values.to ? moment(form.values.to).format('YYYY-MM-DD') : "2020-02-02",
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({queryKey: ['listTikTokProducts']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listTikTokProductStats']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listTikTokProductDetailedStatsByProductId']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listAllStats']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listPlatformFees']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['getTiktokProduct']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['validatePlatformFees']}).finally(() => {
                    })
                    showNotification({
                        title: "Saved!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    onSave()
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    return (
        <form onSubmit={form.onSubmit((values) => save())}>

            <LoadingOverlay visible={isSaving} />
            {error && (
                <Alert radius={"md"} color="red" className={"mb-8"} icon={CLOSE_CIRCLE_ICON} title="Operation failed">
                    {error}
                </Alert>
            )}
            <div className={((isNew) ? "bg-yellow-50 " : "bg-gray-50 ") + "mb-8 rounded-xl shadow"}>
                <div className={"flex flex-wrap border-b p-4"}>
                    <div className={"lg:w-2/3 flex items-center"}>
                        {live && (
                            <div className={"flex items-center"}>
                                <div className={"w-4 h-4 mr-4 rounded-full bg-green-500"}></div>
                                <div>
                                    <Text className={"text-xl font-bold"}>
                                        Default
                                    </Text>
                                    <Text className={"text-xs opacity-50"}>
                                        Enter your charge below, and we'll use this as your default platform fee record. You'll be able to add back-dated platform fee's after this.
                                    </Text>
                                </div>
                            </div>
                        )}
                        {!live && !form.values.present && (
                            <>
                                <DatePickerInput
                                    disabled={form.values.present}
                                    label={"From"}
                                    style={{width: "350px"}}
                                    size={"xl"}
                                    placeholder="From date"
                                    valueFormat="YYYY-DD-MM"
                                    className={"mr-4"}
                                    {...form.getInputProps('from')}
                                />
                            </>
                        )}
                        {!live && form.values.present && (
                            <>
                                <TextInput
                                    disabled={form.values.present}
                                    label={"From"}
                                    style={{width: "350px"}}
                                    size={"xl"}
                                    placeholder="From date"
                                    valueFormat="YYYY-DD-MM"
                                    className={"mr-4"}
                                    value="~"
                                />
                            </>
                        )}
                        {!form.values.present && (
                            <>
                                <DatePickerInput
                                    disabled={form.values.present}
                                    label={"To"}
                                    size={"xl"}
                                    style={{width: "350px"}}
                                    placeholder="To date"
                                    valueFormat="YYYY-DD-MM"
                                    {...form.getInputProps('to')}
                                />
                            </>
                        )}
                        {!live && (
                            <>
                                {form.values.present && (
                                    <>
                                        <TextInput
                                            disabled={true}
                                            label={"To"}
                                            size={"xl"}
                                            style={{width: "350px"}}
                                            placeholder="To date"
                                            value={"~"}
                                        />
                                    </>
                                )}
                                <Checkbox
                                    className={"ml-4"}
                                    size={"xl"}
                                    description={"Tick this to make this platform fee record your default."}
                                    style={{width: "350px"}}
                                    label={"Default"}
                                    {...form.getInputProps('present', { type: 'checkbox' })}
                                />
                            </>
                        )}
                    </div>
                    <div className={"lg:w-1/3 flex items-center justify-end text-xl pr-10"}>

                    </div>
                </div>
                <div className={"p-4"}>
                    <NumberInput
                        min={0.01}
                        step={0.01}
                        precision={2}
                        radius={"md"}
                        size={"xl"}
                        className={"pr-2"}
                        label={"Platform Fee Percentage"}
                        description={"Enter the platform fee (as a percentage - by default its 4.9%)"}
                        style={{width: "100%"}}
                        placeholder="Amount"
                        {...form.getInputProps(`total_platform_fee_as_percentage`)}
                    />
                </div>
            </div>
            <div className={"mt-4 flex items-center justify-end gap-4"}>
                <>
                    <Button
                        auto={true}
                        shadow
                        size={"xl"}
                        color={"default"}
                        icon={ARROW_LEFT_ICON}
                        onClick={() => onSave()}>
                        Cancel
                    </Button>
                    {/*<Button*/}
                    {/*    auto={true}*/}
                    {/*    shadow*/}
                    {/*    size={"xl"}*/}
                    {/*    color={"error"}*/}
                    {/*    icon={CLOSE_CIRCLE_ICON}*/}
                    {/*    onClick={() => onDelete(costOfGoods ? costOfGoods.id : null)}>*/}
                    {/*    Delete*/}
                    {/*</Button>*/}
                    <Button
                        auto={true}
                        shadow
                        size={"xl"}
                        color={"success"}
                        icon={CHECK_CIRCLE}
                        type={"submit"}
                    >
                        Save
                    </Button>
                </>
            </div>
        </form>
    )
}