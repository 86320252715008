import {SingleDataSetLineChart} from "./SingleLineChart";
import {CLOSE_ICON} from "../../Icons/FontAwesomeIcons";
import {Alert} from "@mantine/core";
import moment from "moment";

const convertDateToTotalPointMapToNivoDataSet = (dateToTotalPointMap) =>{
    const dataArray = []
    if (dateToTotalPointMap) {
        for (let date of Object.keys(dateToTotalPointMap)) {
            const unformattedDate = date.split(" ")[0]
            const momentFormatted = moment(unformattedDate).format("D/M").toString()

            dataArray.push({
                x: momentFormatted,
                y: dateToTotalPointMap[date].TotalSales
            })
        }
    }
    return dataArray
}

export const SingleLineChartImpl = ({height, dateToTotalPointMap: dateToTotalPoints, staticLine, staticLineLabel}) => {
    const data = convertDateToTotalPointMapToNivoDataSet(dateToTotalPoints)

    return (
        <div style={{height: height}}>
            <SingleDataSetLineChart data={data}
                                    identifier={"Total points"} />
        </div>
    )
}
