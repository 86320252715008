import {useAggregatedStats} from "../../Hooks/Statistics";
import {SingleBarChartImpl} from "../Charts/SingleBarChartImpl";
import {
    CHECK_CIRCLE,
    CLOSE_CIRCLE_ICON,
    CLOSE_ICON,
    PIE_CHART_ICON,
    SETTINGS_ICON,
    STORE_ICON
} from "../../Icons/FontAwesomeIcons";
import {Text} from "@nextui-org/react";
import {LoadingOverlay, MultiSelect, Select} from "@mantine/core";
import {useContext, useEffect, useState} from "react";
import {getCurrentMonthStartDate, getTodayPlusOne} from "../../Utils/Dates";
import {color3, color1, color2, colorArray} from "../Charts/SingleBarChartPlusLineChart";
import {AppContext} from "../../Contexts/App";
import {useSettingItems} from "../../Hooks/SettingItems";
import {showNotification} from "@mantine/notifications";
import {request} from "../../Interceptors/Request";
import {useQueryClient} from "@tanstack/react-query";

const order = {
    TotalSales: 1,
    TotalNetProfits: 2,
    // You can modify the following rankings based on your needs:
    TotalAffiliateCommission: 3,
    TotalCogs: 4,
    TotalPlatformFee: 5,
    TotalShippingFee: 6,
    TotalVAT: 7,
    TotalUnits: 8 // placing Units last, but you can adjust if necessary
};

export const DayByDayAggregate = ({usingSelectedDate, onDateSelect, calendarPeriod, from, to, selectedOrderStatuses, selectedShopIds, setTotalShippingFees, setVAT, setVatSales, setVatPurchases, setPlatformCommiss, setAffiliateComiss, setTotalCancelledSales, setTotalCancelledUnits, setTotalSales, setTotalUnits, setTotalCOGS}) => {
    const {settlementsOnly, clearAppContext} = useContext(AppContext)
    const {settingItems, isLoading} = useSettingItems(["dashboard_chart_1_data_points", "dashboard_chart_1_chart_type"])
    const {isLoading: isLoadingAggrStats, dateToTotalsMap, rawData} = useAggregatedStats(calendarPeriod, from, to, selectedShopIds, selectedOrderStatuses)
    const [selectedDataPoints, setSelectedDataPoints] = useState(["TotalNetProfits", "TotalUnits", "TotalAffiliateCommission"])
    const [graphType, setGraphType] = useState("grouped")
    const [isSaving, setIsSaving] = useState(false)
    const queryClient = useQueryClient()
    const handleError = (response) => {
        let message = "Unable to save changes";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const saveGraphType = (value) => {
        setIsSaving(true)
        request({
            url: `/setting-items/orchestrators/create-or-update-bulk`,
            method: "POST",
            data: [
                {
                    key: "dashboard_chart_1_chart_type",
                    value: value,
                },
            ]
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['getSettingItemsByKeysCache'] }).finally(() => {})
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    const saveDataPoints = (values) => {
        setIsSaving(true)
        request({
            url: `/setting-items/orchestrators/create-or-update-bulk`,
            method: "POST",
            data: [
                {
                    key: "dashboard_chart_1_data_points",
                    value: JSON.stringify(values),
                },
            ]
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['getSettingItemsByKeysCache'] }).finally(() => {})
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }


    useEffect(() => {
        if (settingItems) {
            if (settingItems.dashboard_chart_1_chart_type) {
                if (settingItems.dashboard_chart_1_chart_type.value === "grouped" || settingItems.dashboard_chart_1_chart_type.value == "stacked") {
                    setGraphType(settingItems.dashboard_chart_1_chart_type.value)
                }
            }

            if (settingItems.dashboard_chart_1_data_points) {
                const valueStr = settingItems.dashboard_chart_1_data_points.value
                let jsonArr = []
                try {
                    jsonArr = JSON.parse(valueStr)
                } catch (e) {
                    console.log("failed to parse")
                }
                setSelectedDataPoints(jsonArr)
            }
        }
    }, [settingItems])

    useEffect(() => {
        let totalSales = Number(0)
        let totalUnits = Number(0)
        let totalCogs = Number(0)
        let totalCancelledUnits = Number(0)
        let totalCancelledSales = Number(0)
        let totalShippingFees = Number(0)
        let totalAffiliateFees = Number(0)
        let totalPlatformFees = Number(0)
        let totalVAT = Number(0)

        if (rawData) {
            for(const item of rawData) {
                totalCancelledSales = (item.TotalSalesCancelled ? item.TotalSalesCancelled : 0) + totalCancelledSales
                totalCancelledUnits = (item.TotalUnitsCancelled ? item.TotalUnitsCancelled : 0) + totalCancelledUnits
                totalSales = (item.TotalSales ? item.TotalSales : 0) + totalSales
                totalUnits =(item.TotalUnits ? item.TotalUnits : 0) + totalUnits
                totalCogs = (item.TotalCogs ? item.TotalCogs : 0) + totalCogs
                totalShippingFees = (item.TotalShippingFee ? item.TotalShippingFee : 0) + totalShippingFees
                totalVAT = (item.TotalVAT ? item.TotalVAT : 0) + totalVAT
                totalAffiliateFees = (item.TotalAffiliateCommission ? item.TotalAffiliateCommission : 0) + totalAffiliateFees
                totalPlatformFees = (item.TotalPlatformFee ? item.TotalPlatformFee : 0) + totalPlatformFees
            }
        }

        const salesVAT = totalVAT
        const purchasesVAT = salesVAT > 0 ? (totalShippingFees / 6) + (totalAffiliateFees / 6) + (totalPlatformFees / 6) : 0
        setVatSales(totalVAT)
        setVatPurchases(purchasesVAT)
        setVAT(salesVAT - purchasesVAT)
        setTotalShippingFees(totalShippingFees)
        setTotalCancelledSales(totalCancelledSales)
        setTotalCancelledUnits(totalCancelledUnits)
        setTotalSales(totalSales)
        setTotalUnits(totalUnits)
        setTotalCOGS(totalCogs)
        setAffiliateComiss(totalAffiliateFees)
        setPlatformCommiss(totalPlatformFees)
    }, [rawData, usingSelectedDate])

    return (
        <div>
            <LoadingOverlay visible={isLoading || isLoadingAggrStats} />
            {/*<div className={"p-4 flex items-center justify-center w-full"}>*/}
            {/*    <div className={"w-auto flex gap-8"}>*/}
            {/*        {selectedDataPoints.map((item, index) => (*/}
            {/*            <div className={"flex items-center"} key={index}>*/}
            {/*                <div className={"w-4 h-4 mr-2 rounded-full"} style={{backgroundColor: colorArray[index]}}></div>*/}
            {/*                <Text className={"opacity-75"}>{getLabel(item)}</Text>*/}
            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <SingleBarChartImpl
                graphType={graphType}
                dataPoints={selectedDataPoints}
                onDateSelect={onDateSelect}
                settlementsOnly={settlementsOnly}
                height={"650px"}
                calendarPeriod={calendarPeriod}
                dateToTotalPointMap={dateToTotalsMap}
            />

            <div className={"p-4"}>
                <MultiSelect
                    placeholder={"Select data"}
                    label={"Configure"}
                    disabled={isSaving}
                    radius={"md"}
                    description={"Select which data points we should show on the chart above."}
                    icon={SETTINGS_ICON}
                    value={selectedDataPoints}
                    onChange={(values) => {
                        const sorted = values.sort((a, b) => order[a] - order[b]).reverse();
                        setSelectedDataPoints(sorted)
                        saveDataPoints(sorted)
                    }}
                    data={[
                        {
                            label: "Net Sales",
                            value: "TotalSales"
                        },
                        {
                            label: "Net Profits",
                            value: "TotalNetProfits"
                        },
                        {
                            label: "Units",
                            value: "TotalUnits"
                        },
                        {
                            label: "Affiliate Commission",
                            value: "TotalAffiliateCommission"
                        },
                        {
                            label: "Cost of Goods",
                            value: "TotalCogs"
                        },
                        {
                            label: "Platform Fee",
                            value: "TotalPlatformFee"
                        },
                        {
                            label: "Shipping Fee",
                            value: "TotalShippingFee"
                        },
                        {
                            label: "VAT",
                            value: "TotalVAT"
                        },
                    ]} />

                <Select
                    className={"mt-4"}
                    placeholder={"Select graph type"}
                    disabled={isSaving}
                    radius={"md"}
                    description={"Select graph type"}
                    icon={PIE_CHART_ICON}
                    value={graphType}
                    onChange={(value) => {
                        setGraphType(value)
                        saveGraphType(value)
                    }}
                    data={[
                        {
                            label: "Grouped",
                            value: "grouped"
                        },
                        {
                            label: "Stacked",
                            value: "stacked"
                        },
                    ]} />
            </div>
        </div>
    )
}