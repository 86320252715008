import {useContext, useEffect, useState} from "react";
import {request, requestEmpty} from "../../../Interceptors/Request";
import {Center, Container, LoadingOverlay} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {ARROW_RIGHT_ICON_XS, CHECKMARK_ICON, CLOSE_ICON} from "../../../Icons/FontAwesomeIcons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, Text} from "@nextui-org/react";
import {AppContext} from "../../../Contexts/App";

export const LoginWithTikTokCallback = () => {
    const {setToken, setUser, user, clearAppContext} = useContext(AppContext)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")
    const state = searchParams.get("state")
    const [isLoading, setIsLoading] = useState(false)

    const handleError = (response) => {
        let message = "Unable to login with TikTok";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const connect = () => {
        setIsLoading(true)
        // delete code from query params
        const useCode = code
        const callbackState = state

        searchParams.delete("code");
        searchParams.delete("state");
        setSearchParams(searchParams);

        const callbackArr = callbackState.split(",")
        const isUs = callbackArr[0] !== "false"
        const isSandbox = callbackArr[1] !== "false"

        request({
            url: user ? `/user/orchestrators/add-seller-account` : `/user/orchestrators/authenticate`,
            method: "POST",
            data: {
                code: useCode,
                is_us: false,
                is_sandbox: false,
            }
        }, clearAppContext).then((response) => {
                if (response && response.status === 200) {
                    showNotification({
                        title: "Successfully connected seller account!",
                        icon: CHECKMARK_ICON,
                        color: "green"
                    })

                    if (!user) {
                        const user = {name: response.data.name}
                        localStorage.setItem("SHOPA-user", JSON.stringify(user))
                        localStorage.setItem("SHOPA-token", response.data.token)
                        setUser(user)
                        setToken(response.data.token)

                        navigate("/")
                    } else {
                        navigate("/shop-accounts")
                    }
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <LoadingOverlay visible={isLoading} />
            <Container className={"h-full flex items-center justify-center"} style={{minHeight: "100vh"}}>
                <div className={"flex items-center justify-center h-full w-full"}>
                    <div className={"bg-white shadow-3xl rounded-xl border w-full"}>
                        {user && (
                            <>
                                <div className={"p-10"}>
                                    <Text className={"font-bold text-3xl"}>👋 Welcome back, we're all set.</Text>
                                    <Text className={"opacity-50 mt-4 text-xl"}>One last thing, hit the button below to confirm authorization for this new seller account.</Text>
                                </div>
                                <Button
                                    size={"xl"}
                                    className={"mt-10"}
                                    style={{width: "100%"}}
                                    disabled={isLoading}
                                    type={"primary"}
                                    iconRight={ARROW_RIGHT_ICON_XS}
                                    onClick={() => connect()}>
                                    Confirm Authorization
                                </Button>
                            </>
                        )}
                        {!user && (
                            <>
                                <div className={"p-10"}>
                                    <Text h1 b>👋 Welcome back!</Text>
                                    <Text className={"opacity-50 mt-4"}>Hit the button below, and we'll log you in.</Text>
                                </div>
                                <Button
                                    size={"xl"}
                                    className={"mt-10"}
                                    style={{width: "100%"}}
                                    disabled={isLoading}
                                    type={"primary"}
                                    iconRight={ARROW_RIGHT_ICON_XS}
                                    onClick={() => connect()}>
                                    Take me to the dashboard!
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Container>
        </>
    )
}