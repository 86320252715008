import {useContext, useState} from "react";
import {AppContext} from "../../../Contexts/App";
import {useForm} from "@mantine/form";
import {Alert, LoadingOverlay, PasswordInput, TextInput} from "@mantine/core";
import {Button, Text} from "@nextui-org/react";
import {
    ARROW_RIGHT_ICON_XS,
    CHECK_CIRCLE, CHECKMARK_ICON, CLOSE_CIRCLE_ICON,
    CLOSE_ICON,
    EMAIL_ICON, INFO_CIRCLE_ICON,
    PASSWORD_ICON
} from "../../../Icons/FontAwesomeIcons";
import {showNotification} from "@mantine/notifications";
import {requestEmpty} from "../../../Interceptors/Request";
import {useNavigate} from "react-router-dom";

export const LoginWithEmailPassword = () => {
    const navigate = useNavigate()
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const {clearAppContext, setToken, setUser} = useContext(AppContext)
    const [error, setError] = useState(null)

    const form = useForm({
        initialValues: {
            email: "",
            password: ""
        },

        validate: {
            email: (value) => (value && value !== "" ? null : "Please enter an email address."),
            password: (value) => (value && value !== "" ? null : "Please enter a password.")
        },
    });

    const handleError = (response) => {
        let message = "Unable to login";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        setError(message)
    }

    const save = () => {
        setIsLoggingIn(true)
        requestEmpty({
            url: `/user/orchestrators/authenticate-by-email`,
            method: "POST",
            data: {
                email: form.values.email,
                password: form.values.password,
            }
        })
            .then((response) => {
                if (response && response.status === 200) {
                    showNotification({
                        title: "Success!",
                        icon: CHECKMARK_ICON,
                        color: "green"
                    })

                    const user = {name: response.data.name}
                    localStorage.setItem("SHOPA-user", JSON.stringify(user))
                    localStorage.setItem("SHOPA-token", response.data.token)
                    setUser(user)
                    setToken(response.data.token)

                    navigate("/")
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoggingIn(false))
    }

    return (
        <>
            <LoadingOverlay visible={isLoggingIn} />
            <form onSubmit={form.onSubmit((values) => save())}>
                {error && (
                    <Alert color="red" radius="md" className={"shadow my-8"} icon={CLOSE_CIRCLE_ICON} title="Oops! Something went wrong.">
                        {error}
                    </Alert>
                )}

                <div className="bg-white shadow-3xl rounded-xl border w-full">
                    <div className="p-10">
                        <TextInput
                            radius={"md"}
                            icon={EMAIL_ICON}
                            size={"lg"}
                            label={"Email Address"}
                            placeholder={"charlie@shop-flow.com"}
                            {...form.getInputProps('email')}
                        />
                        <PasswordInput
                            className={"mt-2"}
                            radius={"md"}
                            size={"lg"}
                            icon={PASSWORD_ICON}
                            label={"Password"}
                            placeholder={"C!D<SD37"}
                            {...form.getInputProps('password')}
                        />
                    </div>
                    <Button
                        style={{width: "100%"}}
                        disabled={isLoggingIn}
                        size={"xl"}
                        type={"primary"}
                        iconRight={ARROW_RIGHT_ICON_XS}
                        onClick={() => save()}>
                        Login
                    </Button>
                </div>
            </form>
        </>
    )
}