
import {
    CLOSE_CIRCLE_ICON, CLOSE_ICON,
    EDIT_ICON_SMALL, FILE_ICON,
    INFO_CIRCLE_ICON, LINK_ICON,
    MERGE_ICON,
    PLUS_ICON,
    TICKET_ICON, TRASH_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Button, Pagination, Table, Text} from "@nextui-org/react";
import {Alert, Anchor, LoadingOverlay} from "@mantine/core";
import moment from "moment/moment";
import {useTiktokShopAccounts} from "../../../Hooks/TiktokShopAccounts";
import {useState} from "react";
import {showNotification} from "@mantine/notifications";
import {requestEmpty} from "../../../Interceptors/Request";
import {useReports} from "../../../Hooks/Reports";
import {useNavigate} from "react-router-dom";

export const Reports = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(15)
    const {total, totalPages, reports, isLoading} = useReports(page, limit)
    const [isLoadingReq, setIsLoadingReq] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <LoadingOverlay visible={isLoadingReq || isLoading} />
            <div className={"bg-white p-4 shadow-3xl border-b border-t flex"}>
                <div className={"lg:w-1/2 w-full"}>
                    <div className={"flex items-center gap-4"}>
                        <div className={"text-xl font-bold"}>
                            <span className={"mr-2"}>{FILE_ICON}</span>
                            Reports
                        </div>
                    </div>
                    <Text className={"text-xs opacity-50"}>
                        Create, edit and download reports.
                    </Text>
                </div>
            </div>
            <div className={"pt-4 pb-4 border-t border-b px-4 bg-white w-full flex items-center "}>
                <div className={"mr-2 flex"}>
                    <div>
                        <Pagination
                            size={"sm"}
                            disabled={isDeleting}
                            color={"primary"}
                            page={page}
                            onChange={(page) => setPage(page)}
                            total={totalPages}
                        />
                    </div>
                </div>
            </div>
            <div className={"bg-white"}>
                {(!isLoading && reports && reports.length <= 0) && (
                    <div className={"p-20 text-xl text-center w-full flex items-center justify-center"}>
                        <div>
                            <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                            <Text className={"text-2xl font-bold my-4"}>No reports configured</Text>
                            <div className={"w-full flex items-center justify-center"}>
                                <Button
                                    auto={true}
                                    className={"mt-4"}
                                    color={"success"}
                                    disabled={isLoading || isLoadingReq}
                                    icon={PLUS_ICON}
                                    onClick={() => navigate("/reports/create-edit")}
                                >Create Report</Button>
                            </div>
                        </div>
                    </div>
                )}
                {(!isLoading && reports && reports.length > 0) && (
                    <div className={"-mx-4"}>
                        <Table
                            lined
                            headerLined
                            shadow={false}
                            aria-label="Example table with static content"
                            css={{
                                height: "auto",
                                width: "100% !important",
                                marginTop: "0px",
                                borderRadius: "0px",
                                margin: "0px !important",
                                padding: "0px !important"
                            }}
                        >
                            <Table.Header>
                                <Table.Column><span className={"ml-4"}>NAME / DESC</span></Table.Column>
                                <Table.Column>SHOPS</Table.Column>
                                <Table.Column>LINKED</Table.Column>
                                <Table.Column>ACTIONS</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {reports.map((report, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            <div className={"pl-4"}>
                                                <Text>
                                                    <b>{report.label}</b>
                                                </Text>
                                                <Text className={"opacity-50 text-sm"}>
                                                    {report.description}
                                                </Text>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>

                                        </Table.Cell>
                                        <Table.Cell>
                                            {moment(report.created_at).format("LL")}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"flex gap-2 z-999"}>
                                                <Button
                                                    auto={true}
                                                    color={"error"}
                                                    flat
                                                    icon={TRASH_ICON}
                                                    onClick={() => {

                                                    }}
                                                />
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        <Button
                            icon={PLUS_ICON}
                            color="success"
                            auto={true}
                            disabled={isLoading || isLoadingReq}
                            flat
                            size={"lg"}
                            style={{width: "100%", borderRadius: "0px"}}
                        >
                            Create Report
                        </Button>
                    </div>
                )}
            </div>
        </>
    )
}