export const CompletedOrdersMode = () => {
    return (
        <>
            <div>
                <div className={"text-3xl font-bold"}>Completed Orders Mode</div>
                <div className={"opacity-50 text-md"}>What's Completed orders mode?</div>

                <div className={"mt-8 mb-8 opacity-50 text-md"}>
                    TikTok provides us with two data channels for orders: unsettled & settled orders. <br/><br/>
                    In completed orders mode we will only show your settled order data, a settled order is an order which has been delivered, and the corresponding funds have been deposited into your business accounts. Completed only orders mode enables sellers to get a view of settled orders which they have been paid for, as well as end-of-order affiliate commission, discount, and platform fee details.
                </div>

                <div className={"mb-8 opacity-50 text-md"}>
                    You can switch to <b>Live Orders Mode</b> to get real-time unsettled order data, which excludes figures like affiliate commissions, so we'll do our best to estimate those for you based on past order data.
                </div>
            </div>
        </>
    )
}