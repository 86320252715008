export const COGS = () => {
    return (
        <>
            <div>
                <div className={"text-3xl font-bold"}>Setup Cost of Goods</div>
                <div className={"opacity-50 text-md"}>Learn how to setup cost of goods records for products</div>

                <div className={"mt-8 mb-8 opacity-50 text-md"}>
                 Cost of goods records are used to record the cost of a specific product so that we can accurately report profit figures. Follow the interactive tutorial below, and learn how to setup cost of goods records to match your business needs.
                </div>

                <div
                    style={{position: "relative", paddingBottom: "calc(73.13953488372093% + 41px)", height: "0", width: "100%"}}>
                    <iframe src="https://demo.arcade.software/b6neQzvxg4y5XFxteApC?embed" frameBorder="0" loading="lazy"
                            webkitallowfullscreen mozallowfullscreen allowFullScreen
                            style={{position: "absolute", top: "0", left:" 0", width: "100%", height: "100%",colorScheme: "light"}}
                            title="ShopFlow"></iframe>
                </div>
            </div>
        </>
    )
}