
import {useContext, useEffect, useState} from 'react';
import {createStyles, Navbar, Group, Code, getStylesRef, rem, Text, LoadingOverlay, Indicator} from '@mantine/core';
import {
    ALERT_ICON,
    ARROW_LEFT_ICON, CHECK_CIRCLE, CLOSE_ICON, CREDIT_CARD_ICON,
    DATABASE_ICON, FILE_ICON, INFO_CIRCLE_ICON, LINK_ICON, MERGE_ICON,
    PIE_CHART_ICON, QUESTION_MARK_ICON, REFRESH_ICON, REFRESH_ICON_SPIN,
    SETTINGS_ICON,
    SHOPPING_CART_ICON,
    TICKET_ICON, TRUCK_ICON, WARNING_ICON
} from "../../Icons/FontAwesomeIcons";
import {Button, Divider, Switch, Tooltip} from "@nextui-org/react";
import {AppContext} from "../../Contexts/App";
import {request, requestEmpty} from "../../Interceptors/Request";
import {showNotification} from "@mantine/notifications";
import {clear} from "localforage";
import {useLocation, useNavigate} from "react-router-dom";
import {SettledModeToggle} from "./SettledModeToggle";
import {useNotifications} from "../../Hooks/Notifications";
import {useCurrentUser} from "../../Hooks/User";
import moment from "moment";
import {Logo} from "./Logo";

const useStyles = createStyles((theme) => ({
    header: {
        paddingBottom: theme.spacing.md,
        marginBottom: `calc(${theme.spacing.md} * 1.5)`,
        borderBottom: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
    },

    footer: {
        paddingTop: theme.spacing.md,
        marginTop: theme.spacing.md,
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
    },

    link: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        borderRadius: theme.radius.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,

            [`& .${getStylesRef('icon')}`]: {
                color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            },
        },
    },

    linkIcon: {
        ref: getStylesRef('icon'),
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
        marginRight: theme.spacing.sm,
    },

    linkActive: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
            color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
            [`& .${getStylesRef('icon')}`]: {
                color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
            },
        },
    },
}));

const data = [
    { link: '/dashboard', label: 'Dashboard', icon: PIE_CHART_ICON },
    { link: '/products', label: 'Products', icon: DATABASE_ICON },
    { link: '/expenses', label: 'Expenses', icon: TICKET_ICON },
    { link: '/moneyback/returns', label: 'Return Reimbursement', icon: TRUCK_ICON },
   // { link: '/reports', label: 'Reports', icon: FILE_ICON },
];

export const AdminNavbar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {clearAppContext} = useContext(AppContext)
    const { classes, cx } = useStyles();
    const [active, setActive] = useState('Dashboard');
    const [syncing, setSyncing] = useState(false)
    const {notifications, isLoading: isLoadingNotifications} = useNotifications(false)

    useEffect(() => {
        for (const navItem of data) {
            if (window.location.pathname === navItem.link) {
                setActive(navItem.label)
            }
        }
    }, [])

    const handleError = (response) => {
        let message = "Unable to sync";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const links = data.map((item) => (
        <a
            className={cx(classes.link, { [classes.linkActive]: item.label === active })}
            href={item.link}
            key={item.label}
            onClick={(event) => {
                event.preventDefault();
                navigate(item.link)
                setActive(item.label)
            }}
        >
            <span className={"mr-2"}>{item.icon}</span>
            <span>{item.label}</span>
        </a>
    ));

    useEffect(() => {
        const pathname = location.pathname
        if (pathname.includes("settings")) {
            setActive("settings")
        } else if(pathname.includes("notifications")) {
            setActive("notifications")
        } else if(pathname.includes("billing")) {
            setActive("billing")
        } else if(pathname.includes("dashboard")) {
            setActive("Dashboard")
        } else if(pathname.includes("products")) {
            setActive("Products")
        } else if(pathname.includes("expenses")) {
            setActive("Expenses")
        } else if (pathname.includes("help")) {
            setActive("help")
        } else if (pathname.includes("shop-accounts")) {
            setActive("shop-accounts")
        } else if (pathname.includes("reports")) {
            setActive("Reports")
        } else if(pathname.includes("moneyback/returns")) {
            setActive("Return Reimbursement")
        }
    }, [location]);

    return (
        <>
            <LoadingOverlay visible={isLoadingNotifications} />
            <Navbar p="md" style={{width: "16vw", height: "100vh", position: "fixed"}}>
                <Navbar.Section grow>
                    <Group className={classes.header} position="center">
                        <Logo />
                    </Group>
                    {links}
                </Navbar.Section>

                <SettledModeToggle />

                <Navbar.Section className={classes.footer}>
                    <a href="#"
                       className={cx(classes.link, { [classes.linkActive]: "notifications" === active })}
                       onClick={($event) => {
                           $event.stopPropagation()
                           navigate("/notifications")
                           setActive("notifications")
                       }}>
                        {notifications && notifications.length > 0 && (
                            <Indicator color={"yellow"}
                                inline
                                position="middle-end"
                                label={notifications.length}
                                size={16}>
                                <span className={"text-yellow-500 opacity-50 mr-4"}>{ALERT_ICON}</span>
                                <span className={"text-yellow-500 mr-4"}>Notifications</span>
                            </Indicator>
                        )}
                        {(!notifications || notifications && notifications.length <= 0) && (
                            <>
                                <span className={"opacity-50 mr-4"}>{ALERT_ICON}</span>
                                <span>Notifications</span>
                            </>
                        )}
                    </a>

                    <a href="#"
                       className={cx(classes.link, { [classes.linkActive]: "settings" === active })}
                       onClick={($event) => {
                           $event.stopPropagation()
                           navigate("/settings")
                           setActive("settings")
                       }}>
                        <span className={"opacity-50 mr-4"}>{SETTINGS_ICON}</span>
                        <span>Settings</span>
                    </a>

                    <a href="#"
                       className={cx(classes.link, { [classes.linkActive]: "shop-accounts" === active })}
                       onClick={($event) => {
                           $event.stopPropagation()
                           navigate("/shop-accounts")
                           setActive("shop-accounts")
                       }}>
                        <span className={"opacity-50 mr-4"}>{MERGE_ICON}</span>
                        <span>Seller Accounts</span>
                    </a>

                    <a href="#"
                       disabled
                       className={cx(classes.link, { [classes.linkActive]: "billing" === active })}
                       onClick={($event) => {
                           $event.stopPropagation()
                           navigate("/billing")
                           setActive("billing")
                       }}>
                        <span className={"opacity-50 mr-4"}>{CREDIT_CARD_ICON}</span>
                        <span>Billing</span>
                    </a>

                    <a href="#"
                       disabled
                       className={cx(classes.link, { [classes.linkActive]: "help" === active })}
                       onClick={($event) => {
                           $event.stopPropagation()
                           navigate("/help")
                           setActive("help")
                       }}>
                        <span className={"opacity-50 mr-4"}>{QUESTION_MARK_ICON}</span>
                        <span>Help</span>
                    </a>

                    <a href="#"
                       className={classes.link}
                       onClick={($event) => {
                           $event.stopPropagation()
                           clearAppContext()
                       }}>
                        <span className={"opacity-50 mr-4"}>{ARROW_LEFT_ICON}</span>
                        <span>Logout</span>
                    </a>
                    {/*<Divider></Divider>*/}
                    {/*<a href="#"*/}
                    {/*   className={classes.link}*/}
                    {/*   onClick={($event) => {*/}
                    {/*       $event.stopPropagation()*/}
                    {/*       if (!syncing) {*/}
                    {/*           fireSync()*/}
                    {/*       }*/}
                    {/*   }}>*/}
                    {/*    <span className={"opacity-50 mr-4"}>{syncing ? REFRESH_ICON_SPIN : REFRESH_ICON}</span>*/}
                    {/*    <span>Sync</span>*/}
                    {/*</a>*/}
                </Navbar.Section>
            </Navbar>
        </>
    );
}