import {useState} from "react";
import {Badge, LoadingOverlay, Pagination, Text} from "@mantine/core";
import moment from "moment";
import {useSubscriptionPayments} from "../../../Hooks/Payments";
import {CHECK_CIRCLE, CLOSE_CIRCLE_ICON, WARNING_ICON} from "../../../Icons/FontAwesomeIcons";

export const PaymentsList = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const {isLoading: isLoadingPayments, total, totalPages, payments} = useSubscriptionPayments(page, limit)

    return (
        <>
            <LoadingOverlay visible={isLoadingPayments} blur={4} />
            {!isLoadingPayments && payments && payments.length > 0 && (
                <>
                    <Pagination
                        page={page}
                        onChange={setPage}
                        total={totalPages}
                    />
                    <div className={"mt-8"}></div>
                    {payments.map((payment, index) => (
                        <div className={"pb-4"} key={index}>
                            <div
                                className={"rounded-xl flex cursor-pointer"}>
                                <div className={"flex flex-wrap w-full"}>
                                    <div className={"lg:w-1/3 w-full text-lg"}>
                                        <div className={"font-medium"}>
                                            {moment(payment.created_at).format("LL")}
                                        </div>
                                    </div>
                                    <div className={"lg:w-1/3 w-full flex items-center justify-center text-lg"}>
                                        {payment.amount_paid && (
                                            <div>
                                                <div className={"font-medium"}>£ {(payment.amount_paid / 100).toFixed(2)}</div>
                                            </div>
                                        )}
                                        {!payment.amount_paid && (
                                            <div>
                                                <div className={"font-medium"}>£ 0.00</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={"lg:w-1/3 w-full flex items-center justify-center"}>
                                        {payment.status === "pending" && (
                                            <Badge size={"xl"} color={"gray"} style={{textTransform: "inherit", fontWeight: 400}}>
                                                <span className={"mr-2"}>{WARNING_ICON}</span>
                                                Pending
                                            </Badge>
                                        )}
                                        {payment.status === "failed" && (
                                            <Badge size={"xl"} color={"gray"} style={{textTransform: "inherit", fontWeight: 400}}>
                                                <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                                                Failed
                                            </Badge>
                                        )}
                                        {payment.status === "paid" && (
                                            <div>
                                                <Badge size={"xl"} color={"green"} style={{textTransform: "inherit", fontWeight: 400}}>
                                                    <span className={"mr-2"}>{CHECK_CIRCLE}</span>
                                                    Success
                                                </Badge>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
            {((!isLoadingPayments && !payments) || (!isLoadingPayments && payments && payments.length === 0)) && (
                <div className={"text-opacity-20 font-medium"}>
                    <Text c={"dimmed"}>
                        <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                        No payment history found.
                    </Text>
                </div>
            )}
        </>
    )
}

