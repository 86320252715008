import {useState} from "react";
import {useTikTokProducts} from "../../../Hooks/TiktokProducts";
import {useShippedCancelledOrders} from "../../../Hooks/TiktokOrders";
import {getFourYearAgoDate, getPreviousMonthDate, getThreeMonthsAgoDate, getTodayPlusOne} from "../../../Utils/Dates";
import {Alert, Anchor, Checkbox, LoadingOverlay, Text} from "@mantine/core";
import {Button, Pagination, Table} from "@nextui-org/react";
import {
    calcMargin,
    calcNetProfit,
    cutString,
    getCogsByProduct,
    getShippingChargesByProduct
} from "../../../Utils/Figures";
import {
    CALENDAR_ICON,
    CHECK_CIRCLE, CHECKMARK_ICON,
    CLOSE_CIRCLE_ICON, CLOSE_ICON, DOWNLOAD_ICON,
    EDIT_ICON_SMALL, FILE_ICON, INFO_CIRCLE_ICON,
    LINK_ICON, LIST_ICON, REFRESH_ICON_SPIN, SEARCH_ICON, TRUCK_ICON,
    WARNING_ICON
} from "../../../Icons/FontAwesomeIcons";
import {SingleLineChartImpl} from "../../Charts/SingleLineChartImpl";
import {DatePickerInput} from "@mantine/dates";
import moment from "moment";
import {useReimbursementReportOutputs} from "../../../Hooks/ReimbursementReportOutputs";
import {useNavigate} from "react-router-dom";
import {showNotification} from "@mantine/notifications";
import {requestEmpty} from "../../../Interceptors/Request";

export const ReimbursementReportOutputList = () => {
    const [isDownloading, setIsDownloading] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(15)
    const {
        isLoading,
        total,
        totalPages,
        reports
    } = useReimbursementReportOutputs(page, limit)
    const navigate = useNavigate()

    const pagination = (
        <>
            <div className={"bg-white border-b pb-2 pt-2 px-4 w-full flex items-center justify-start text-lg"}>
                <div className={"lg:w-1/2 w-full flex items-center"}>
                    {reports && reports.length && (
                        <Text className={"m-0 opacity-50 text-xs"}>{reports.length} / {total} total</Text>
                    )}
                </div>
                <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                    {totalPages > 1 && (
                        <div className={"pb-1"}>
                            <Pagination
                                size={"xs"}
                                color={"primary"}
                                page={page}
                                onChange={(page) => setPage(page)}
                                total={totalPages}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )

    const handleError = (response) => {
        let message = "Unable to download";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const download = (reimbursementId) => {
        setIsDownloading(true)
        requestEmpty({
            url: `/reimbursement-reports/orchestrators/retrieve-download-link?reimbursementReportOutputId=${reimbursementId}`,
            method: "GET"
        })
            .then((response) => {
                if (response && response.status === 200) {
                    const url = response.data.url;

                    window.location.href = url
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsDownloading(false))
    }

    return (
        <>
            <LoadingOverlay visible={isDownloading || isLoading} />
            <div className={"bg-white"}>
                <Alert icon={INFO_CIRCLE_ICON} title="How does this work?">
                    A CSV file is generated for each Tiktok Seller Account connected to ShopFlow. Simply take each file (or one if you only have one seller account connected), and send these files to TikTok support requesting money back on eligible orders.
                </Alert>
            </div>
            {!isLoading && reports && reports.length > 0 && (
                <div className={"bg-white"}>
                    <div className={"border-t"}></div>
                    {pagination}
                    <div className={"-mx-4"}>
                        <Table
                            lined
                            headerLined
                            aria-label="Example table with static content"
                            css={{
                                height: "auto",
                                width: "100% !important",
                                marginTop: "0px",
                                borderRadius: "0px",
                                margin: "0px !important",
                                padding: "0px !important"
                            }}
                        >
                            <Table.Header>
                                <Table.Column width="200px">
                                    <div className={"pl-4"}>CREATED</div>
                                </Table.Column>
                                <Table.Column width="500px">DATE RANGE</Table.Column>
                                <Table.Column width="500px">SHOP ACCOUNT</Table.Column>
                                <Table.Column width="100px">ORDERS</Table.Column>
                                <Table.Column width="100px">STATUS</Table.Column>
                                <Table.Column width="500px">DOWNLOAD</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {reports.map((report, index) => (
                                    <Table.Row
                                        key={report.id}>
                                        <Table.Cell>
                                            <div className={"text-xs opacity-50 ml-4"}>
                                                {moment(report.date_created).format("LL")}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"opacity-75"}>
                                                {moment(report.date_cover_from).format("LL")}
                                                <span className={"mx-4"}> - </span>
                                                {moment(report.date_cover_to).format("LL")}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Anchor
                                                component="button"
                                                type="button"
                                                onClick={() => navigate("/shop-accounts")}
                                            >
                                                {report.shop_account_name}
                                                <span className={"ml-2"}>{LINK_ICON}</span>
                                            </Anchor>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {report.order_count ? report.order_count : 0}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {report.job && (
                                              <>
                                                  {report.job.status === "pending" && (
                                                      <div className={"mr-4 bg-yellow-100 capitalize p-4 py-1 rounded-xl text-center"}>
                                                          <span className={"mr-2"}>{WARNING_ICON}</span>
                                                          Pending
                                                      </div>
                                                  )}
                                                  {report.job.status === "started" && (
                                                      <div className={"mr-4 bg-gray-100 capitalize p-4 py-1 rounded-xl text-center"}>
                                                          <span className={"mr-2"}>{REFRESH_ICON_SPIN}</span>
                                                          Exporting
                                                      </div>
                                                  )}
                                                  {report.job.status === "success" && (
                                                      <div className={"mr-4 bg-green-100 capitalize p-4 py-1 rounded-xl text-center"}>
                                                          <span className={"mr-2"}>{CHECKMARK_ICON}</span>
                                                          Ready for Download
                                                      </div>
                                                  )}
                                                  {report.job.status === "failed" && (
                                                      <div className={"mr-4 bg-red-100 capitalize p-4 py-1 rounded-xl text-center"}>
                                                          <span className={"mr-2"}>{CLOSE_ICON}</span>
                                                          Failed
                                                      </div>
                                                  )}
                                              </>
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button
                                                onClick={() => download(report.id)}
                                                disabled={report.job.status !== "success"} auto={true} color={"success"} icon={DOWNLOAD_ICON}></Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                    {pagination}
                </div>
            )}
            {(!isLoading && reports && reports.length === 0) && (
                <div className={"text-black text-opacity-20 font-medium bg-white p-4"}>
                    <Text c={"dimmed"}>
                        <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                        No reports found.
                    </Text>
                </div>
            )}
        </>
    )
}