import {Switch, Text} from "@nextui-org/react";
import {ALERT_ICON, QUESTION_MARK_ICON} from "../../../Icons/FontAwesomeIcons";
import {Tabs} from "@mantine/core";
import {SetupDashboard} from "./SetupDashboard";
import {LiveOrdersMode} from "./LiveOrdersMode";
import {CompletedOrdersMode} from "./CompletedOrdersMode";
import {Shipping} from "../Settings/Shipping";
import {ShippingFees} from "./ShippingFees";
import {COGS} from "./COGS";
import {AffiliateEstimation} from "./AffiliateEstimation";
import {PlatformFee} from "./PlatformFee";
import {VATSetup} from "./VATSetup";

export const Help = () => {
    return (
        <>
            <div className={"w-full flex flex-wrap bg-white bg-white p-4 shadow-3xl border-b border-t"}>
                <div className={"lg:w-1/2 w-full flex items-center"}>
                    <div>
                        <div className={"text-xl font-bold"}>
                            <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                            Help
                        </div>
                        <Text className={"text-xs opacity-50"}>
                            Learn about how to use ShopFlow.
                        </Text>
                    </div>
                </div>
                <div className={"lg:w-1/2 w-full flex items-center justify-end"}>

                </div>
            </div>
            <div className={"bg-white"}>
                <Tabs
                    keepMounted={false}
                    color="dark"
                    orientation="vertical"
                    radius={"md"}
                    variant={"pills"}
                    defaultValue={"1"}>
                    <Tabs.List className={"bg-white shadow-3xl border-r pr-4 py-2 pl-2"} >
                        <Tabs.Tab value="1" className="mt-10 text-md" >
                            <div>
                                <div>Setup Dashboard</div>
                                <div className={"opacity-50 text-xs w-48 whitespace-pre-line"}>Let's dive into your dashboard</div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab value="2" className="text-md">
                            <div>
                                <div>Live Orders Mode</div>
                                <div className={"opacity-50 text-xs"}>What's live mode?</div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab value="3" className="text-md">
                            <div>
                                <div>Completed Orders Mode</div>
                                <div className={"opacity-50 whitespace-pre-line text-xs w-48"}>What's completed orders mode?</div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab value="4" className="text-md">
                            <div>
                                <div>Setup VAT / Tax</div>
                                <div className={"opacity-50 whitespace-pre-line text-xs w-48"}>How to setup VAT calculation</div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab value="5" className="text-md">
                            <div>
                                <div>Setup Platform Fee</div>
                                <div className={"opacity-50 whitespace-pre-line text-xs w-48"}>Learn all about how we record platform fee's</div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab value="6" className="text-md">
                            <div>
                                <div>Setup Affiliate Commission</div>
                                <div className={"opacity-50 whitespace-pre-line text-xs w-48"}>For live orders only. In completed mode we show accurate affiliate figures.</div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab value="7" className="text-md">
                            <div>
                                <div>Setup Cost of Goods</div>
                                <div className={"opacity-50 whitespace-pre-line text-xs w-48"}>Learn how to setup cost of goods records for products</div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab value="8" className="text-md">
                            <div>
                                <div>Setup Shipping Fees</div>
                                <div className={"opacity-50 whitespace-pre-line text-xs w-48"}>Learn how to setup shipping fee records for manually fulfilled products</div>
                            </div>
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel
                        value="1">
                        <div className={"mt-10 px-8 py-4"}>
                            <SetupDashboard />
                        </div>
                    </Tabs.Panel>

                    <Tabs.Panel
                        value="2">
                        <div className={"mt-10 px-8 py-4"}>
                            <LiveOrdersMode />
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel
                        value="3">
                        <div className={"mt-10 px-8 py-4"}>
                            <CompletedOrdersMode />
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel
                        value="4">
                        <div className={"mt-10 px-8 py-4"}>
                            <VATSetup />
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel
                        value="5">
                        <div className={"mt-10 px-8 py-4"}>
                            <PlatformFee />
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel
                        value="6">
                        <div className={"mt-10 px-8 py-4"}>
                            <AffiliateEstimation />
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel
                        value="7">
                        <div className={"mt-10 px-8 py-4"}>
                            <COGS />
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel
                        value="8">
                        <div className={"mt-10 px-8 py-4"}>
                            <ShippingFees />
                        </div>
                    </Tabs.Panel>
                </Tabs>
            </div>
        </>
    )
}