import {
    CLOSE_CIRCLE_ICON, CLOSE_ICON,
    EDIT_ICON_SMALL,
    INFO_CIRCLE_ICON, LINK_ICON,
    MERGE_ICON,
    PLUS_ICON,
    TICKET_ICON, TRASH_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Button, Pagination, Table, Text} from "@nextui-org/react";
import {Alert, Anchor, LoadingOverlay} from "@mantine/core";
import moment from "moment/moment";
import {useTiktokShopAccounts} from "../../../Hooks/TiktokShopAccounts";
import {useState} from "react";
import {showNotification} from "@mantine/notifications";
import {requestEmpty} from "../../../Interceptors/Request";

export const ShopAccounts = () => {
    const {shopAccounts, isLoading} = useTiktokShopAccounts()
    const [isLoadingReq, setIsLoadingReq] = useState(false)
    const [isUs, setIsUs] = useState(false)
    const [isSandbox, setIsSandbox] = useState(false)

    const handleError = (response) => {
        let message = "Unable to login with Tiktok";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const loginWithTikTok = () => {
        setIsLoadingReq(true)
        requestEmpty({
            url: `/tiktok/oauth/authorize/uris`,
            method: "GET"
        })
            .then((response) => {
                if (response && response.status === 200) {
                    let url;
                    if (isSandbox && isUs) {
                        url = response.data["sandbox_us"];
                    } else if (isSandbox && !isUs) {
                        url = response.data["sandbox_non_us"];
                    } else if (!isSandbox && !isUs) {
                        url = response.data["live_non_us"];
                    }  else if (!isSandbox && isUs) {
                        url = response.data["live_us"];
                    }

                    url = url + "&state=" + isUs + "," + isSandbox

                    window.location.href = url
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoadingReq(false))
    }

    return (
        <>
            <LoadingOverlay visible={isLoadingReq || isLoading} />
            <div className={"bg-white p-4 shadow-3xl border-b border-t flex"}>
                <div className={"lg:w-1/2 w-full"}>
                    <div className={"flex items-center gap-4"}>
                        <div className={"text-xl font-bold"}>
                            <span className={"mr-2"}>{MERGE_ICON}</span>
                           Your Seller Accounts
                        </div>
                    </div>
                    <Text className={"text-xs opacity-50"}>
                        Manage all seller accounts linked to this ShopFlow account.
                    </Text>
                </div>
            </div>
            <div className={"bg-white"}>
                {(!isLoading && shopAccounts && shopAccounts.length <= 0) && (
                    <div className={"p-20 text-xl text-center w-full flex items-center justify-center"}>
                        <div>
                            <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                            <Text className={"text-2xl font-bold my-4"}>No accounts configured</Text>
                            <div className={"w-full flex items-center justify-center"}>
                                <Button
                                    auto={true}
                                    className={"mt-4"}
                                    color={"success"}
                                    disabled={isLoading || isLoadingReq}
                                    icon={PLUS_ICON}
                                    onClick={() => loginWithTikTok()}
                                >Connect Seller Account</Button>
                            </div>
                        </div>
                    </div>
                )}
                {(!isLoading && shopAccounts && shopAccounts.length > 0) && (
                    <div className={"-mx-4"}>
                        <Table
                            lined
                            headerLined
                            shadow={false}
                            aria-label="Example table with static content"
                            css={{
                                height: "auto",
                                width: "100% !important",
                                marginTop: "0px",
                                borderRadius: "0px",
                                margin: "0px !important",
                                padding: "0px !important"
                            }}
                        >
                            <Table.Header>
                                <Table.Column><span className={"ml-4"}>NAME / REGION</span></Table.Column>
                                <Table.Column>SHOPS</Table.Column>
                                <Table.Column>LINKED</Table.Column>
                                <Table.Column>ACTIONS</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {shopAccounts.map((account, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            <div className={"pl-4"}>
                                                <Text>
                                                    <b>{account.seller_name}</b>
                                                </Text>
                                                <Text className={"opacity-50 text-sm"}>
                                                    {account.seller_base_region}
                                                </Text>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Anchor
                                                component="button"
                                                type="button"
                                                onClick={() => console.log("chicken")}
                                            >
                                                {account.shop_count} shop(s)
                                                <span className={"ml-2"}>{LINK_ICON}</span>
                                            </Anchor>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {moment(account.created_at).format("LL")}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"flex gap-2 z-999"}>
                                                <Button
                                                    auto={true}
                                                    color={"error"}
                                                    flat
                                                    icon={TRASH_ICON}
                                                    onClick={() => {

                                                    }}
                                                />
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        <Button
                            icon={PLUS_ICON}
                            color="success"
                            auto={true}
                            disabled={isLoading || isLoadingReq}
                            flat
                            size={"lg"}
                            style={{width: "100%", borderRadius: "0px"}}
                            onClick={() => loginWithTikTok()}
                        >
                            Add another seller account
                        </Button>
                    </div>
                )}
            </div>
        </>
    )
}