import {ResponsiveLine} from "@nivo/line";

export const SingleDataSetLineChart = ({identifier, data}) => {

    const formattedData = [
        {
            "id": identifier,
            "data": data
        }
    ]

    return (
        <ResponsiveLine
            data={formattedData}
            curve={"cardinal"}
            margin={{ top: 35, right: 35, bottom: 50, left: 15 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            enableCrosshair={false}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridY={false}
            enableGridX={false}
            lineWidth={2}
            colors={['#228be6']}
            pointSize={4}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[]}
        />
    )
}
