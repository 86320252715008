import {useSet} from "@uidotdev/usehooks";
import {useSettingItems} from "../../../Hooks/SettingItems";
import {useForm} from "@mantine/form";
import {Checkbox, LoadingOverlay, NumberInput, Select} from "@mantine/core";
import {
    CALENDAR_ICON,
    CHECK_CIRCLE,
    CLOCK_ICON,
    CLOSE_ICON,
    QUESTION_MARK_ICON,
    SETTINGS_ICON, WARNING_ICON
} from "../../../Icons/FontAwesomeIcons";
import {DatePickerInput} from "@mantine/dates";
import {useContext, useEffect, useState} from "react";
import {Button, Text} from "@nextui-org/react";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {AppContext} from "../../../Contexts/App";
import {useAffiliateEstimationTechniques} from "../../../Hooks/AffiliateEstimationTechniques";
import {useQueryClient} from "@tanstack/react-query";
import {EditPlatformFees} from "../../Common/PlatformFees/EditPlatformFees";

export const TikTokAffiliateCommissions = () => {
    const queryClient = useQueryClient()
    const {settingItems, isLoading} = useSettingItems(["affiliate_commission_estimation_technique"])
    const [isSaving, setIsSaving] = useState(false)
    const {clearAppContext} = useContext(AppContext)
    const {techniques, isLoading: isLoadingTechniques} = useAffiliateEstimationTechniques()

    const form = useForm({
        initialValues: {
            affiliate_commission_estimation_technique: ""
        },

        validate: {

        },
    });

    const handleError = (response) => {
        let message = "Unable to save changes";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const save = () => {
        setIsSaving(true)
        request({
            url: `/setting-items/orchestrators/create-or-update-bulk`,
            method: "POST",
            data: [
                {
                    key: "affiliate_commission_estimation_technique",
                    value: form.values.affiliate_commission_estimation_technique.toString(),
                },
            ]
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['getSettingItemsByKeysCache'] }).finally(() => {})
                    showNotification({
                        title: "Saved!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    useEffect(() => {
        if (settingItems) {
            if (settingItems.affiliate_commission_estimation_technique) {
                form.setValues({affiliate_commission_estimation_technique: settingItems.affiliate_commission_estimation_technique.value})
            }
        }
    }, [settingItems])

    useEffect(() => {
        if (techniques && techniques.length > 0) {
            if (form.values.affiliate_commission_estimation_technique === "") {
                const first = techniques[0]
                form.setValues({affiliate_commission_estimation_technique: first.label})
            }
        }
    }, [techniques])

    return (
        <>
            <LoadingOverlay visible={isLoading || isLoadingTechniques} />
            <form onSubmit={form.onSubmit((values) => save())}>
                <div className={"px-4 py-4 w-full flex items-center"}>
                    <div className={"lg:w-1/2 w-full flex items-center"}>
                        <Text className={"text-xl font-bold"}>
                            Affiliate Commissions
                        </Text>
                    </div>
                    <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                        <Button disabled={isSaving} shadow color={"success"} icon={CHECK_CIRCLE} type={"submit"} auto={true}>Save Changes</Button>
                    </div>
                </div>
                <div className={"pb-32 p-4"}>
                    <div className="-ml-2 mb-4 mantine-xynbgb mantine-Checkbox-labelWrapper"><label
                        className="mantine-kegijc mantine-Checkbox-label" htmlFor="mantine-lki8cd9bl">How should we estimate your affiliate commission figures for uncompleted orders?</label>
                        <div
                            className="mantine-Text-root mantine-InputWrapper-description mantine-Checkbox-description mantine-1si5nio">
                           TikTok only sends us accurate affiliate commission data after the order has been delivered, and marked as completed, so for live orders we need to know how you'd like us to "estimate" the affiliate commission figure for each order.
                        </div>
                        <div
                            className="mt-4 mantine-Text-root mantine-InputWrapper-description mantine-Checkbox-description mantine-1si5nio">
                           * We are actively working on better and more accurate ways to estimate affiliate commission figures for unsettled orders.
                        </div>
                    </div>
                    <Select
                        size={"lg"}
                        data={techniques ? techniques.map((item) => ({
                            value: item.label,
                            label: item.description,
                        })) : []}
                        {...form.getInputProps('affiliate_commission_estimation_technique')}/>
                    <div className={"bg-yellow-50 p-4 rounded-xl mt-10 text-yellow-500"}>
                        <span className={"mr-2"}>{WARNING_ICON}</span>
                        We update your "estimated" affiliate commission figures every 24 hours, so please be aware upon updating your estimation technique that it can take up to 24 hours to reflect on your dashboard.
                    </div>
                </div>
            </form>
        </>
    )
}