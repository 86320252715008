import {ResponsiveBar} from "@nivo/bar";
import {curveBasis, curveBumpX, curveCatmullRom, line} from "d3-shape";
import { Axes } from "@nivo/axes";
import { computeXYScalesForSeries } from "@nivo/scales";
import { useTooltip, TableTooltip } from "@nivo/tooltip";
import moment from "moment";
import {useEffect} from "react";

export const color1 = "#0095ff";
export const color2 = "#fa4231";
export const color3 = "#faaa31";
export const color4 = "#000000";
export const color5 = "#314cfa";
export const color6 = "#c131fa";
export const color7 = "#18c00b";
export const color8 = "#31faf3";

export const colorArray = [color1,color2,color3,color4,color5,color6,color7,color8]


function CustomTooltipWithAffiliate({ data }) {
    return (
        <>
            <TableTooltip
                rows={[
                    ["Net Sales", data.TotalSales ? data.TotalSales.toFixed(2) : 0],
                    ["VAT", (data.TotalVAT - (data.TotalShippingFee / 6) - (data.TotalAffiliateCommission / 6) - (data.TotalPlatformFee / 6)).toFixed(2)],
                    ["Cost of Goods", data.TotalCogs ? data.TotalCogs.toFixed(2) : 0],
                    ["Affiliates", data.TotalAffiliateCommission ? data.TotalAffiliateCommission.toFixed(2) : 0],
                    ["Platform Fee", data.TotalPlatformFee ? data.TotalPlatformFee.toFixed(2) : 0],
                    ["Shipping Fee", data.TotalShippingFee ? data.TotalShippingFee.toFixed(2) : 0],
                    ["Units", data.TotalUnits ? data.TotalUnits : 0],
                    ["Net Profits", data.TotalNetProfits ? data.TotalNetProfits.toFixed(2) : 0],
                ]}
            />
        </>
    );
}

const getLabel = (dataPoint) => {
    switch (dataPoint) {
        case "TotalSales":
            return {label: "Net Sales", color: color7}
        case "TotalNetProfits":
            return {label: "Net Profits", color: color1}
        case "TotalCogs":
            return {label: "Cost of Goods", color: color4}
        case "TotalAffiliateCommission":
            return {label: "Affiliate Commission", color: color6}
        case "TotalShippingFee":
            return {label: "Shipping Fee", color: color5}
        case "TotalPlatformFee":
            return {label: "Platform Fee", color: color3}
        case "TotalUnits":
            return {label: "Units", color: color2}
        case "TotalVAT":
            return {label: "VAT", color: color8}
    }
    return "No label"
}

export const SingleDataSetBarLineChart = ({calendarPeriod, graphType, dataPoints, identifier, data, staticLines, settlementsOnly, onDateSelect}) => {
    const onClickBar = (bars, bar, idx) => {
        const actualDate = bar.actual_date;
        const nextBarDate = bars[idx + 1] ? bars[idx + 1].actual_date : moment()
        if (onDateSelect) onDateSelect(actualDate, nextBarDate)
    }

    const UnitsLine = ({ bars, xScale, yScale }) => {
        const lineGenerator = line()
            .defined((d, i, data) => {
                if (i === 0 || i === data.length - 1) {
                    return true;
                }
                return data[i - 1].x < d.x && d.x <= data[i + 1].x;
            })
            .x((bar) => xScale(bar.data.indexValue) + bar.width)
            .y((bar) => {
                return yScale((bar.data.data.TotalUnits ? bar.data.data.TotalUnits : 0))
            }).curve(curveBumpX)

        return (
            <path
                d={lineGenerator(bars)}
                fill="none"
                stroke="red"
                strokeWidth="3"
            />
        );
    };

    let layers = ["grid", "axes", "bars", "markers", "legends"]
    if (dataPoints && dataPoints.indexOf("TotalUnits") > -1) {
        layers = ["grid", "axes", "bars", UnitsLine, "markers", "legends"]
    } else if(!dataPoints) {
        layers = ["grid", "axes", "bars", UnitsLine, "markers", "legends"]
    }

    const calcDataPoints = dataPoints ? dataPoints.filter(item => item === "TotalUnits" ? null : item) : ["TotalSales", "TotalAffiliateCommission"]

    const getLegendData = () => {
        const startingPoint = calcDataPoints.map((id, index) => ({
            color: getLabel(id).color,
            id,
            label: getLabel(id).label
        }))

        if (dataPoints && dataPoints.indexOf("TotalUnits") > -1) {
            startingPoint.push({color: "red", label: "Total Units"})
        }

        return startingPoint
    }

    const allValues = data.flatMap(d =>
        Object.keys(d)
            .filter(key => (dataPoints ? dataPoints : ["TotalSales", "TotalAffiliateCommission", "TotalUnits"]).includes(key))
            .map(key => d[key])
    ).filter(val => typeof val === 'number');

    const maxValue = allValues.length > 0 ? Math.max(...allValues) : 1000;
    const padding = maxValue * 0.2;

    return (
        <span className={"cursor-pointer"}>
            <ResponsiveBar
                margin={{ top: 90, right: 50, bottom: 30, left: 60 }}
                data={data}
                keys={calcDataPoints}
                groupMode={graphType ? graphType : "grouped"}
                padding={0.2}
                indexBy="DateCaptured"
                onClick={(barData) => {
                    onClickBar(data, barData.data, barData.index)
                }}
                tooltip={({ data }) => (
                    <CustomTooltipWithAffiliate
                        style={{cursor: "pointer"}}
                        data={data}
                    />
                )}
                enableLabel={false}
                borderRadius={0}
                enableGridX={true}
                enableGridY={false}
                layers={layers}
                colors={({id, data}) => {
                    return getLabel(id).color
                }}
                axisBottom={{
                    format: function(value){
                        let momentFormatted = moment(value).format("D/M").toString()
                        switch (calendarPeriod) {
                            case "this_month_by_day":
                                return moment(value).format("D/M").toString()
                            case "this_quarter_by_week":
                                return moment(value).format("D/M").toString()
                            case "last_30_days_by_day":
                                return moment(value).format("D/M").toString()
                            case "last_3_months_by_week":
                                return moment(value).format("D/M").toString()
                            case "last_12_months_by_month":
                                return moment(value).format("M/YY").toString()
                            case "this_year_by_month":
                                return moment(value).format("M/YY").toString()
                        }
                        return momentFormatted
                    }
                }}
                colorBy={"id"}
                legends={[
                    {
                        symbolShape: "circle",
                        dataFrom: 'keys',
                        data: getLegendData(),
                        anchor: 'top',
                        direction: 'row',
                        justify: true,
                        translateX: 0,
                        translateY: -60,
                        itemsSpacing: 60,
                        itemWidth: 50,
                        itemHeight: 42,
                        itemDirection: 'top-to-bottom',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                maxValue={maxValue + padding}
            />
        </span>
    )
}

/*
    const UnitsLine = ({ bars, xScale, innerWidth, innerHeight, tooltip }) => {
        const scale = computeXYScalesForSeries(
            [
                {
                    id: "only",
                    data: data.map((it) => ({x: it.date, y: parseFloat(it.total_units) }))
                }
            ],
            { type: "linear" },
            { type: "linear" },
            innerWidth,
            innerHeight
        );
        const lineGenerator = line()
            .x((bar) => {
                return bar.x + bar.width / 2
            })
            .y((bar) => {
                return bar.data.data.total_units ? scale.yScale(bar.data.data.total_units) :  scale.yScale(0.01)
            });

        const tip = useTooltip();

        function renderTip(e, idx) {
            return tip.showTooltipFromEvent(
                <CustomTooltip
                    barValue={parseFloat(data[idx].total_sales)}
                    lineValue={parseFloat(data[idx].total_units)} />,
                e
            );
        }

        return (
            <>
                <Axes
                    yScale={scale.yScale}
                    xScale={xScale}
                    width={innerWidth}
                    height={innerHeight}
                    right={{
                        ticksPosition: "after"
                    }}
                />
                <path
                    d={lineGenerator(bars)}
                    fill="none"
                    stroke={salesColor}
                    style={{ pointerEvents: "none" }}
                />
                {bars.map((bar) => {
                    return (
                        <circle
                            key={bar.key}
                            cx={xScale(bar.data.data.date) + bar.width / 2}
                            cy={
                                bar.data.data.total_units &&
                                bar.data.data.total_units != 0 ?
                                    scale.yScale(bar.data.data.total_units) : scale.yScale(0.01)}
                            r={4}
                            fill={salesColor}
                            stroke={salesColor}
                            style={{ pointerEvents: "none" }}
                        />
                    )
                })}
                {bars.map((bar, idx) => (
                    <rect
                        onClick={() => onClickBar(bars, bar, idx)}
                        key={bar.key}
                        x={bar.x}
                        y={0}
                        height={innerHeight}
                        width={bar.width}
                        fill="transparent"
                        style={{cursor: "pointer"}}
                        onMouseEnter={(e) => renderTip(e, idx)}
                        onMouseMove={(e) => renderTip(e, idx)}
                        onMouseLeave={tip.hideTooltip}
                    />
                ))}
            </>
        );
    };
    const AffiliateLine = ({ bars, xScale, yScale, innerWidth, innerHeight, tooltip }) => {
        const lineGenerator = line()
            .x((bar) => {
                return bar.x + bar.width / 2
            })
            .y((bar) => {
                return bar.data.data.total_affiliate_commission ? yScale(bar.data.data.total_affiliate_commission) :  yScale(0.01)
            });

        const tip = useTooltip();

        function renderTip(e, idx) {
            return tip.showTooltipFromEvent(
                <CustomTooltipWithAffiliate
                    sales={parseFloat(data[idx].total_sales)}
                    units={parseFloat(data[idx].total_units)}
                    affiliate={parseFloat(data[idx].total_affiliate_commission)} />,
                e
            );
        }

        return (
            <>
                <path
                    d={lineGenerator(bars)}
                    fill="none"
                    stroke={affiliateColor}
                    style={{ pointerEvents: "none" }}
                />
                {bars.map((bar) => {
                    return (
                        <circle
                            key={bar.key}
                            cx={xScale(bar.data.data.date) + bar.width / 2}
                            cy={
                                bar.data.data.total_affiliate_commission &&
                                bar.data.data.total_affiliate_commission != 0 ?
                                    yScale(bar.data.data.total_affiliate_commission) : yScale(0.01)}
                            r={4}
                            fill={affiliateColor}
                            stroke={affiliateColor}
                            style={{ pointerEvents: "none" }}
                        />
                    )
                })}
                {bars.map((bar, idx) => (
                    <rect
                        key={bar.key}
                        x={bar.x}
                        y={0}
                        height={innerHeight}
                        width={bar.width}
                        fill="transparent"
                        onClick={() => onClickBar(bars, bar, idx)}
                        style={{cursor: "pointer"}}
                        onMouseEnter={(e) => renderTip(e, idx)}
                        onMouseMove={(e) => renderTip(e, idx)}
                        onMouseLeave={tip.hideTooltip}
                    />
                ))}
            </>
        );
    }
 */