import {useContext, useEffect, useState} from "react";
import {AppContext} from "../Contexts/App";
import {useQuery} from "@tanstack/react-query";
import {request} from "../Interceptors/Request";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";

export const useExpenseLines= (page, limit) => {
    const {user, clearAppContext} = useContext(AppContext)
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const {isLoading, error, data} = useQuery(
        ['listExpenseLines', user, page, limit],
        () => request({
            url: `/expense-lines/orchestrators/list?page=${page}&limit=${limit}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )

    useEffect(() => {
        if (data) {
            const numberOfPages = parseInt(data.headers["number_of_pages"])
            const newTotal = parseInt(data.headers["total_count"])
            setTotal(newTotal)
            setTotalPages(numberOfPages)
        }
    }, [data])

    return {
        total: total,
        totalPages: totalPages,
        expenses: data ? data.data : [],
        isLoading,
        error
    }
}

export const useExpenses= (page, limit) => {
    const {user, clearAppContext} = useContext(AppContext)
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const {isLoading, error, data} = useQuery(
        ['listExpenses', user, page, limit],
        () => request({
            url: `/expenses/orchestrators/list?page=${page}&limit=${limit}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )

    useEffect(() => {
        if (data) {
            const numberOfPages = parseInt(data.headers["number_of_pages"])
            const newTotal = parseInt(data.headers["total_count"])
            setTotal(newTotal)
            setTotalPages(numberOfPages)
        }
    }, [data])

    return {
        total: total,
        totalPages: totalPages,
        expenses: data ? data.data : [],
        isLoading,
        error
    }
}