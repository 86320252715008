import {useQueryClient} from "@tanstack/react-query";
import {useSettingItems} from "../../../Hooks/SettingItems";
import {AppContext} from "../../../Contexts/App";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";
import {CHECK_CIRCLE, CLOSE_ICON} from "../../../Icons/FontAwesomeIcons";
import {request} from "../../../Interceptors/Request";
import {Checkbox, LoadingOverlay, TextInput} from "@mantine/core";
import {Button, Text} from "@nextui-org/react";
import {useContext, useState} from "react";

export const AccountDetails = () => {
    const queryClient = useQueryClient()
    const [isSaving, setIsSaving] = useState(false)
    const {clearAppContext} = useContext(AppContext)

    const form = useForm({
        initialValues: {
            password: ""
        },

        validate: {
            password: (value) => value && value !== "" ? null : "Enter a new password",
            confirm_password: (value) => value && value !== "" && value === form.values.password ? null : "Please confirm your password."
        },
    });

    const handleError = (response) => {
        let message = "Unable to save changes";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const save = () => {
        setIsSaving(true)
        request({
            url: `/user/orchestrators/update-password`,
            method: "POST",
            data: {
                password: form.values.password,
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    showNotification({
                        title: "Your new password is now active!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    return (
        <>
            <form onSubmit={form.onSubmit((values) => save())}>
                <div className={"px-4 py-4 w-full flex items-center"}>
                    <div className={"lg:w-1/2 w-full"}>
                        <Text className={"text-xl font-bold"}>
                            Change Password
                        </Text>
                        <Text className={"text-xs opacity-50"}>
                            If you'd like to update your account's password, please enter your new password below, and hit 'save changes' to confirm.
                        </Text>
                    </div>
                    <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                        <Button disabled={isSaving} shadow color={"success"} icon={CHECK_CIRCLE} type={"submit"} auto={true}>Save Changes</Button>
                    </div>
                </div>
                <div className={"p-4"}>
                    <TextInput
                        size={"lg"}
                        label={"New Password"}
                        placeholder={"e.g 23Q3|k\\hH6-`"}
                        {...form.getInputProps('password')}
                    />

                    <TextInput
                        size={"lg"}
                        className={"mt-4"}
                        placeholder={"Confirm your new password"}
                        label={"Confirm Password"}
                        {...form.getInputProps('confirm_password')}
                    />
                </div>
            </form>
        </>
    )
}