import {Button, Text} from "@nextui-org/react";
import {Checkbox, LoadingOverlay, MultiSelect, NumberInput, Select, Tabs} from "@mantine/core";
import {DayByDayAggregate} from "../../Common/DayByDayAggregate";
import {
    CLOCK_ICON, CLOSE_ICON,
    DATABASE_ICON, INFO_CIRCLE_ICON, LINK_ICON,
    PIE_CHART_ICON,
    REFRESH_ICON,
    SEARCH_ICON,
    SETTINGS_ICON, SHOPPING_CART_ICON, STORE_ICON, TICKET_ICON
} from "../../../Icons/FontAwesomeIcons";
import {ProductList} from "../../Common/ProductList";
import {useQueryClient} from "@tanstack/react-query";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../Contexts/App";
import {useTiktokShops} from "../../../Hooks/TiktokShops";
import {
    getCurrentMonthStartDate, getCurrentQuarterStartDate, getCurrentYearStartDate,
    getPreviousMonthDate,
    getThreeMonthsAgoDate,
    getTodayPlusOne,
    getYearAgoDate
} from "../../../Utils/Dates";
import {useDebounce} from "@uidotdev/usehooks";
import {Link, useNavigate} from "react-router-dom";
import {useExpenseStats} from "../../../Hooks/Statistics";
import {useSettingItems, useSettingItemsCache} from "../../../Hooks/SettingItems";
import {DashboardSelectedRange} from "./DashboardSelectedRange";
import moment from "moment/moment";
import {calendarOptions} from "../../../Constants/Constants";

export const orderStatuses =  [
    "UNPAID",
    "ON_HOLD",
    "AWAITING_SHIPMENT",
    "AWAITING_COLLECTION",
    "PARTIALLY_SHIPPING",
    "IN_TRANSIT",
    "DELIVERED",
    "COMPLETED",
    "CANCELLED"
]

export const DashboardChartsSection = () => {
    const queryClient = useQueryClient()
    const {settingItems} = useSettingItemsCache(["reduce_shipping_fee_by_percent", "subtract_shipping_in_gross_profits"])
    const navigate = useNavigate()
    const {settlementsOnly, includeShippingAsCost, calendarPeriod, setCalendarPeriod, setIncludeShippingAsCost, shippingPercentage, setShippingPercentage} = useContext(AppContext)
    const {shops, isLoading: isLoadingShops} = useTiktokShops()
    const [isPreparing, setIsPreparing] = useState(false)
    const [selectedShopIds, setSelectedSelectedShopIds] = useState(null)
    const [selectedOrderStatuses, setSelectedOrderStatuses] = useState([])
    const debouncedOrderStatuses = useDebounce(selectedOrderStatuses, 1000);
    const [totalSales, setTotalSales] = useState(0)
    const [totalUnits, setTotalUnits] = useState(0)
    const [totalCancelledUnits, setTotalCancelledUnits] = useState(0)
    const [totalCancelledSales, setTotalCancelledSales] = useState(0)
    const [totalCOGS, setTotalCOGS] = useState(0)
    const [totalGrossProfits, setTotalGrossProfits] = useState(0)
    const [totalExpenses, setTotalExpenses] = useState(0)
    const [totalNetProfits, setTotalNetProfits] = useState(0)
    const [totalRoi, setTotalRoi] = useState(0)
    const [totalShippingFees, setTotalShippingFees] = useState(0)
    const [adjustedShippingFee, setAdjustedShippingFee] = useState(0)
    const [platformCommiss, setPlatformCommiss] = useState(0)
    const [affiliateComiss, setAffiliateComiss] = useState(0)
    const [vat, setVAT] = useState(0)
    const [vatPurchases, setVatPurchases] = useState(0)
    const [vatSales, setVatSales] = useState(0)

    const [from, setFrom] = useState(getPreviousMonthDate())
    const [to, setTo] = useState(getTodayPlusOne())
    const [productsFrom, setProductsFrom] = useState(from)
    const [productsTo, setProductsTo] = useState(to)
    const [isUsingTopLevelDate, setIsUsingTopLevelDate] = useState(true)

    const {rawData: expenseStats, isLoading: isLoadingExpenseStats} = useExpenseStats(from, to)

    const clearSelectedMode = () => {
        setProductsFrom(from)
        setProductsTo(to)
        setIsUsingTopLevelDate(true)
    }

    useEffect(() => {
        if (settingItems) {
            if (settingItems.subtract_shipping_in_gross_profits) {
                const value = settingItems.subtract_shipping_in_gross_profits.value === "false" ? false : true
                setIncludeShippingAsCost(value)
            }

            if (settingItems.reduce_shipping_fee_by_percent) {
                const value = Number(settingItems.reduce_shipping_fee_by_percent.value)
                setShippingPercentage(value)
            }
        }
    }, [settingItems])

    useEffect(() => {
        if (expenseStats && expenseStats.Total) {
            setTotalExpenses(expenseStats.Total)
        }
    }, [expenseStats])

    useEffect(() => {
        if (includeShippingAsCost) {
            setAdjustedShippingFee(totalShippingFees - (shippingPercentage ? (shippingPercentage / 100) * totalShippingFees : 0))
        } else {
            setAdjustedShippingFee(totalShippingFees)
        }
    }, [totalShippingFees, includeShippingAsCost, shippingPercentage])

    useEffect(() => {
        if (includeShippingAsCost) {
            setTotalGrossProfits(((totalSales - vat) - (totalCOGS + adjustedShippingFee) - affiliateComiss - platformCommiss))
        } else {
            setTotalGrossProfits((totalSales - vat - totalCOGS) - affiliateComiss - platformCommiss)
        }
    }, [totalSales, totalCOGS, adjustedShippingFee, includeShippingAsCost, shippingPercentage])

    useEffect(() => {
        setTotalNetProfits((totalGrossProfits - totalExpenses))
    }, [totalExpenses, totalGrossProfits])

    useEffect(() => {
        setTotalRoi((totalNetProfits / (totalCOGS ? totalCOGS : 1) * 100))
    }, [totalNetProfits, totalCOGS])

    useEffect(() => {
        let newFrom = getPreviousMonthDate()
        let newTo = getTodayPlusOne()
        switch (calendarPeriod) {
            case "this_quarter_by_week":
                newFrom = getCurrentQuarterStartDate()
                newTo = getTodayPlusOne()
                setFrom(newFrom)
                setTo(newTo)

                setProductsTo(newTo)
                setProductsFrom(newFrom)
                setIsUsingTopLevelDate(true)
                break
            case "this_month_by_day":
                newFrom = getCurrentMonthStartDate()
                newTo = getTodayPlusOne()
                setFrom(newFrom)
                setTo(newTo)

                setProductsTo(newTo)
                setProductsFrom(newFrom)
                setIsUsingTopLevelDate(true)
                break
            case "last_30_days_by_day":
                setFrom(newFrom)
                setTo(newTo)

                setProductsTo(newTo)
                setProductsFrom(newFrom)
                setIsUsingTopLevelDate(true)
                break
            case "last_3_months_by_week":
                newFrom = getThreeMonthsAgoDate()
                newTo = getTodayPlusOne()

                setFrom(newFrom)
                setTo(newTo)

                setProductsFrom(newFrom)
                setProductsTo(newTo)
                setIsUsingTopLevelDate(true)
                break
            case "last_12_months_by_month":
                newFrom = getYearAgoDate()
                newTo = getTodayPlusOne()

                setFrom(newFrom)
                setTo(newTo)

                setProductsFrom(newFrom)
                setProductsTo(newTo)
                setIsUsingTopLevelDate(true)
                break
            case "this_year_by_month":
                newFrom = getCurrentYearStartDate()
                newTo = getTodayPlusOne()

                setFrom(newFrom)
                setTo(newTo)

                setProductsFrom(newFrom)
                setProductsTo(newTo)
                setIsUsingTopLevelDate(true)
                break
        }
    }, [calendarPeriod])

    return (
        <div>
            <LoadingOverlay visible={isPreparing || isLoadingShops || isLoadingExpenseStats} />
            <div className={"bg-white p-4 shadow-3xl border"}>
                <Text className={"mb-2 opacity-50 text-xs font-bold"}>
                    <span className={"mr-2"}>{SEARCH_ICON}</span>
                    REFINE
                </Text>
                <div className={"flex flex-wrap"}>
                    <div className={"lg:w-1/5 w-full"}>
                        <Select
                            label={"Calendar Period"}
                            radius={"md"}
                            icon={CLOCK_ICON}
                            value={calendarPeriod}
                            onChange={(period) => {
                                setCalendarPeriod(period)
                            }}
                            data={calendarOptions} />
                    </div>
                    <div className={"lg:w-1/4 w-full lg:pl-4 pl-0"}>
                        <MultiSelect
                            placeholder={shops && shops.length === 1 ? shops[0].shop_name : "Select shop(s)"}
                            label={"Shops"}
                            radius={"md"}
                            icon={STORE_ICON}
                            disabled={shops && shops.length === 1 ? true : false}
                            value={selectedShopIds}
                            onChange={(values) => setSelectedSelectedShopIds(values)}
                            data={shops.map((shop) => ({label: shop.shop_name, value: shop.id}))} />
                    </div>
                    <div className={"lg:w-1/3 w-full lg:pl-4 pl-0"}>
                        {/*<MultiSelect*/}
                        {/*    placeholder={"Select order statuses"}*/}
                        {/*    label={"Status"}*/}
                        {/*    radius={"md"}*/}
                        {/*    icon={TICKET_ICON}*/}
                        {/*    clearable={true}*/}
                        {/*    value={selectedOrderStatuses}*/}
                        {/*    onChange={(values) => setSelectedOrderStatuses(values)}*/}
                        {/*    data={orderStatuses.map((item) => ({value: item, label: item.replaceAll("_", " ")}))} />*/}
                    </div>
                </div>
            </div>
            <div className={"flex flex-wrap items-stretch" + (isUsingTopLevelDate ? " bg-white" : " bg-blue-50")}>
                <div className={"xl:w-2/3 w-full"}>
                    <div className={"shadow-3xl border h-full" + (isUsingTopLevelDate ? " bg-white" : " bg-blue-50")}>
                        {!isUsingTopLevelDate && (
                            <DashboardSelectedRange
                                calendarPeriod={calendarPeriod}
                                from={productsFrom}
                                to={productsTo}
                                setTotalUnits={setTotalUnits}
                                setTotalCancelledSales={setTotalCancelledSales}
                                setTotalCancelledUnits={setTotalCancelledUnits}
                                setTotalSales={setTotalSales}
                                setTotalCOGS={setTotalCOGS}
                                setTotalShippingFees={setTotalShippingFees}
                                setAffiliateComiss={setAffiliateComiss}
                                setPlatformCommiss={setPlatformCommiss}
                                setVAT={setVAT}
                                setVatSales={setVatSales}
                                setVatPurchases={setVatPurchases}
                                orderStatuses={debouncedOrderStatuses}
                            />
                        )}
                        <DayByDayAggregate
                            usingSelectedDate={isUsingTopLevelDate}
                            onDateSelect={(newFrom, newTo) => {
                                setIsPreparing(true)
                                setTimeout(() => {
                                    setIsPreparing(false)
                                    setProductsFrom(newFrom.format("YYYY-MM-DD"))
                                    if(newTo.isAfter(moment().startOf("day"))) {
                                        setProductsTo(newTo.add(1, 'days').format("YYYY-MM-DD"))
                                    } else {
                                        setProductsTo(newTo.format("YYYY-MM-DD"))
                                    }
                                    setIsUsingTopLevelDate(false)
                                    console.log("new dates", newFrom, newTo)
                                }, 1000)
                            }}
                            calendarPeriod={calendarPeriod}
                            from={from}
                            to={to}
                            setTotalUnits={setTotalUnits}
                            setTotalCancelledSales={setTotalCancelledSales}
                            setTotalCancelledUnits={setTotalCancelledUnits}
                            setTotalSales={setTotalSales}
                            setTotalCOGS={setTotalCOGS}
                            setTotalShippingFees={setTotalShippingFees}
                            setAffiliateComiss={setAffiliateComiss}
                            setPlatformCommiss={setPlatformCommiss}
                            setVAT={setVAT}
                            setVatSales={setVatSales}
                            setVatPurchases={setVatPurchases}
                            selectedShopIds={selectedShopIds}
                            selectedOrderStatuses={debouncedOrderStatuses}
                        />
                    </div>
                </div>
                <div className={"xl:w-1/3 w-full flex"}>
                    <div className={"flex items-center w-full h-full border shadow-3xl"}>
                        <div className={"w-full p-8"}>
                            {!isUsingTopLevelDate && (
                                <Button
                                    auto={true}
                                    flat
                                    size={"sm"}
                                    className={"mb-4"}
                                    color={"primary"}
                                    onClick={() => clearSelectedMode()}
                                >
                                    Showing from <span
                                    className="font-bold mx-2">{moment(productsFrom, "YYYY-MM-DD").format("LL")}</span> to <span
                                    className="font-bold mx-2">{moment(productsTo, "YYYY-MM-DD").format("LL")}</span>
                                    <span className={"ml-1"}>
                                {CLOSE_ICON}
                            </span>
                                </Button>
                            )}
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/3 flex items-center"}>
                                    <Text b>Net Sales</Text>
                                </div>
                                <div className={"w-2/3 flex items-center justify-end"}>
                                    <Text className={"opacity-75"}>£ {(totalSales).toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/2 flex items-center"}>
                                    <Text className={"text-xs"}>Sales</Text>
                                </div>
                                <div className={"w-1/2 flex items-center justify-end"}>
                                    <Text className={"opacity-75 text-xs"}>£ {(totalSales + totalCancelledSales).toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/2 flex items-center"}>
                                    <Text className={"text-xs"}>Cancelled</Text>
                                    <Text className={"ml-2 opacity-50 text-xs"}>({(totalCancelledSales / totalSales * 100).toFixed(2)}%)</Text>
                                </div>
                                <div className={"w-1/2 flex items-center justify-end"}>
                                    <Text className={"opacity-75 text-xs"} color={"red"}>£ -{totalCancelledSales.toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/3 flex items-center"}>
                                    <Text b>Net Units</Text>
                                </div>
                                <div className={"w-2/3 flex items-center justify-end"}>
                                    <Text className={"opacity-75"}>{totalUnits - totalCancelledUnits}</Text>
                                </div>
                            </div>
                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/2 flex items-center"}>
                                    <Text className={"text-xs"}>Units</Text>
                                </div>
                                <div className={"w-1/2 flex items-center justify-end"}>
                                    <Text className={"opacity-75 text-xs"}>{totalUnits}</Text>
                                </div>
                            </div>
                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/2 flex items-center"}>
                                    <Text className={"text-xs"}>Cancelled</Text>
                                    <Text className={"text-xs ml-2 opacity-50"}>({(totalCancelledUnits / totalUnits * 100).toFixed(2)}%)</Text>
                                </div>
                                <div className={"w-1/2 flex items-center justify-end"}>
                                    <Text className={"opacity-75 text-xs"} color={"red"}>-{totalCancelledUnits}</Text>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/2"}>
                                    <Text b>Shipping</Text>
                                    <br />
                                    <Link className={"text-xs"} to={"/settings?tab=shipping"}><span className={"mr-1"}>{SETTINGS_ICON}</span> Configure</Link>
                                </div>
                                <div className={"w-1/2 flex items-center justify-end"}>
                                    <div>
                                        <Text className={"opacity-75 text-right"}>£ {(adjustedShippingFee).toFixed(2)}</Text>
                                        <Text className={"text-xs opacity-50"}>inc. cancelled orders</Text>
                                    </div>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-2/3"}>
                                    <Text b>Affiliate Commission</Text>
                                    {!settlementsOnly && (
                                        <div className={""}>
                                            <Link className={"text-xs"} to={"/settings?tab=tiktok_affiliate_commissions"}><span className={"mr-1"}>{SETTINGS_ICON}</span> Configure</Link>
                                        </div>
                                    )}
                                </div>
                                <div className={"w-1/3 flex items-center justify-end"}>
                                    <div>
                                        <Text className={"opacity-75 text-right"}>£ {affiliateComiss.toFixed(2)}</Text>
                                        {!settlementsOnly && (
                                            <div className={""}>
                                                <Text className={"text-xs"} color={"orange"}>~ estimated</Text>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-2/3"}>
                                    <Text b>Platform Fee's</Text>
                                    <br />
                                    <Link className={"text-xs"} to={"/settings?tab=tiktok_platform_fees"}><span className={"mr-1"}>{SETTINGS_ICON}</span> Configure</Link>
                                </div>
                                <div className={"w-1/3 flex items-center justify-end"}>
                                    <Text className={"opacity-75"}>£ {platformCommiss.toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/3 flex items-center"}>
                                    <Text b>VAT</Text>
                                </div>
                                <div className={"w-2/3 flex items-center justify-end"}>
                                    <Text className={"opacity-75"}>£ {(vat).toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/2 flex items-center"}>
                                    <Text className={"text-xs"}>Sales</Text>
                                </div>
                                <div className={"w-1/2 flex items-center justify-end"}>
                                    <Text className={"opacity-75 text-xs"}>£ {vatSales.toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"pl-4 flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/2 flex flex-wrap items-center gap-4"}>
                                    <Text className={"text-xs"}>Purchases</Text>
                                    <Text className={"text-xs"} color={"orange"}>~ estimated</Text>
                                </div>
                                <div className={"w-1/2 flex items-center justify-end"}>
                                    <Text className={"opacity-75 text-xs"} color={"red"}>£ -{vatPurchases.toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/3"}>
                                    <Text b>Cost of Goods</Text>
                                </div>
                                <div className={"w-2/3 flex items-center justify-end"}>
                                    <div>
                                        <Text className={"opacity-75 text-right"}>£ {totalCOGS.toFixed(2)}</Text>
                                        <Text className={"text-xs opacity-50"}>exc. cancelled orders</Text>
                                    </div>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/3 flex items-center"}>
                                    <Text b>
                                        Gross Profit
                                        <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                    </Text>
                                </div>
                                <div className={"w-2/3 flex items-center justify-end"}>
                                    <Text className={"opacity-75"}>£ {totalGrossProfits.toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/3 flex items-center"}>
                                    <Text b
                                          onClick={() => navigate("/expenses")}
                                          className={"cursor-pointer text-blue-500"}
                                          color={"rgb(59,130,246)"}>
                                        Expenses <span className={"ml-2"}>{LINK_ICON}</span>
                                    </Text>
                                </div>
                                <div className={"w-2/3 flex items-center justify-end"}>
                                    <Text className={"opacity-75"}>£ {totalExpenses.toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/3 flex items-center"}>
                                    <Text b>
                                        Net Profit
                                        <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                    </Text>
                                </div>
                                <div className={"w-2/3 flex items-center justify-end"}>
                                    <Text className={"opacity-75"}>£ {totalNetProfits.toFixed(2)}</Text>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/3 flex items-center"}>
                                    <Text b>
                                        Margin
                                        <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                    </Text>
                                </div>
                                <div className={"w-2/3 flex items-center justify-end"}>
                                    <Text className={"opacity-75"}>{((totalNetProfits / (totalSales ? totalSales : 1)) * 100).toFixed(2)} %</Text>
                                </div>
                            </div>
                            <div className={"flex flex-wrap border-b pb-2 mb-2"}>
                                <div className={"w-1/3 flex items-center"}>
                                    <Text b>
                                        ROI
                                        <span className={"ml-2"}>{INFO_CIRCLE_ICON}</span>
                                    </Text>
                                </div>
                                <div className={"w-2/3 flex items-center justify-end"}>
                                    <Text className={"opacity-75"}>{totalRoi.toFixed(2)} %</Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>

                    <Tabs
                        keepMounted={false}
                        color="dark"
                        radius={"md"}
                        variant={"pills"}
                        defaultValue="products"
                    >
                        <Tabs.List
                            className={"bg-white shadow-3xl border py-2 pl-2"}>
                            {/*<Text className={"opacity-50 text-center mr-2 flex justify-center items-center"}>*/}
                            {/*    <span className={"mr-2"}>{CLOCK_ICON}</span>*/}
                            {/*    All Time*/}
                            {/*</Text>*/}
                            <Tabs.Tab value="products" className="text-md"><span className={"mr-2"}>📦</span> Active Products</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel
                            value="products"
                            className={"bg-white"}>
                            {/*<div className={"flex"}>*/}
                            {/*    <div className={"lg:w-1/2 w-full flex items-center px-4 py-4"}>*/}
                            {/*        <div>*/}
                            {/*            <Text className={"opacity-50"}>We'll update meta information (name, description, and images) every 24 hours</Text>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className={"lg:w-1/2 w-full flex items-center justify-end px-4 py-4"}>*/}
                            {/*        <Button*/}
                            {/*            color={"success"}*/}
                            {/*            icon={REFRESH_ICON}*/}
                            {/*            onClick={() => {*/}
                            {/*                queryClient.invalidateQueries({ queryKey: ['listTikTokProductStats'] }).finally(() => {})*/}
                            {/*            }}>*/}
                            {/*            Refresh*/}
                            {/*        </Button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <ProductList
                                onCloseDateRange={() => clearSelectedMode()}
                                showDateRange={!isUsingTopLevelDate}
                                activeOnly={true}
                                showChart={false}
                                from={productsFrom}
                                to={productsTo}
                                selectedShopIds={selectedShopIds}
                            />
                        </Tabs.Panel>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}