import {
    CHECKMARK_ICON,
    CLOSE_CIRCLE_ICON, CLOSE_ICON,
    DOWNLOAD_ICON,
    INFO_CIRCLE_ICON,
    LIST_ICON,
    TRUCK_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Alert, Tabs, Text} from "@mantine/core";
import {Button} from "@nextui-org/react";
import {ReimbursementReportList} from "./ReimbursementReportList";
import {ReimbursementReportOutputList} from "./ReimbursementReportOutputList";
import {useState} from "react";
import {useCurrentUser} from "../../../Hooks/User";

export const ReimbursementReports = () => {
    const [tab, setTab] = useState("unclaimed")

    return (
        <>
            <div className={"bg-white p-4 shadow-3xl border-b border-t flex"}>
                <div className={"lg:w-1/2 w-full"}>
                    <div className={"flex items-center gap-4"}>
                        <div className={"text-xl font-bold"}>
                            <span className={"mr-2"}>{TRUCK_ICON}</span>
                            Return Reimbursement
                        </div>
                    </div>
                    <Text className={"text-xs opacity-50"}>
                        Download a report detailing all orders which have been shipped, but cancelled.
                    </Text>
                </div>
                <div className={"lg:w-1/2 w-full flex items-center justify-end"}>

                </div>
            </div>
            <Tabs
                keepMounted={false}
                color="dark"
                radius={"md"}
                variant={"pills"}
                className={"bg-white"}
                value={tab}
                onTabChange={(newValue) => setTab(newValue)}>
                <Tabs.List className={"bg-white shadow-3xl border py-2 pl-2"}>
                    <Tabs.Tab value="unclaimed" className="text-md">
                        <span className={"mr-2"}>{CLOSE_ICON}</span> Unclaimed
                        <br />
                        <div className={"opacity-50"}>View all orders for money-back</div>
                    </Tabs.Tab>
                    <Tabs.Tab value="history" className="text-md">
                        <span className={"mr-2"}>{LIST_ICON}</span> Exports List
                        <br />
                        <div className={"opacity-50"}>View & download all your exports</div>
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel
                    value="unclaimed">
                    <ReimbursementReportList onSwitchTab={() => {
                        setTab("history")
                    }} />
                </Tabs.Panel>

                <Tabs.Panel
                    value="history">
                    <ReimbursementReportOutputList />
                </Tabs.Panel>
            </Tabs>
        </>
    )
}