import {LoginWithTikTok} from "./LoginWithTikTok";
import {Divider, Tabs} from "@mantine/core";
import {useContext, useState} from "react";
import {AppContext} from "../../../Contexts/App";
import {LoginWithEmailPassword} from "./LoginWithEmailPassword";
import {Text} from "@nextui-org/react";
import {ARROW_DOWN_ICON} from "../../../Icons/FontAwesomeIcons";

export const Login = () => {
    return (
        <>
            <div
                className="h-full flex items-center justify-center w-3/4 mx-auto"
                style={{minHeight: "100vh"}}>
                <div className="mt-4 flex items-center items-stretch justify-center h-full w-full">
                    <div>
                        <img src="/assets/shopflow_logo.png" width={"100px"} className={"mb-10 block mx-auto"}/>
                        <div className={"w-full text-center mb-10"}>
                            <Text className={"font-bold text-3xl"}>ShopFlow</Text>
                            <Text className={"opacity-50 mt-2"}>Unlock a suite of tools that help you better understand your TikTok shop's performance & sales.</Text>
                        </div>

                        <LoginWithTikTok />
                        <div className={"my-4"}/>
                        <Text className={"opacity-50 w-full mt-10 mb-4 text-center font-bold"}>
                            Already have an account?
                        </Text>
                        <LoginWithEmailPassword />
                        <div className={"mb-32"} />
                    </div>
                </div>
            </div>
        </>
    )
}