import {useForm} from "@mantine/form";
import {
    ARROW_LEFT_ICON,
    CHECK_CIRCLE,
    CLOSE_CIRCLE_ICON,
    CLOSE_ICON,
    EDIT_ICON_SMALL, INFO_CIRCLE_ICON,
    PLUS_ICON,
    TRASH_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Button} from "@nextui-org/react";
import {DatePickerInput} from "@mantine/dates";
import {Alert, Checkbox, LoadingOverlay, NumberInput, Text, TextInput} from "@mantine/core";
import {request} from "../../../Interceptors/Request";
import {showNotification} from "@mantine/notifications";
import {useQueryClient} from "@tanstack/react-query";
import {useContext, useState} from "react";
import {AppContext} from "../../../Contexts/App";
import moment from "moment";

export const CreateEditCostOfGoods = ({live, isNew, productId, costOfGoods, previousCog, onDelete, onSave}) => {
    const [isSaving, setIsSaving] = useState(false)
    const {clearAppContext} = useContext(AppContext)
    const queryClient = useQueryClient()
    const [error, setError] = useState(null)
    const form = useForm({
        initialValues: {
            present: costOfGoods && costOfGoods.present ? costOfGoods.present : (!!live),
            from: costOfGoods && costOfGoods.from ? costOfGoods.from : new Date(),
            to: costOfGoods && costOfGoods.to ? costOfGoods.to : new Date(),
            elements: costOfGoods && costOfGoods.elements && costOfGoods.elements.length > 0
                ? costOfGoods.elements : [{cost_element: "Manufacturing", comment: "", amount: 5, units: 1}]
        },

        validate: {
            from: (value) => {
                if(form.values.present) {
                    return null
                }

                if(!value || value === "") {
                    return 'Invalid from date';
                }

                if (value >= form.values.to) {
                    return 'Invalid: needs to be before "to" date.';
                }

                return null
            },
            to: (value) => {
                if(form.values.present) {
                    return null
                }

                if(!value || value === "") {
                    return 'Invalid to date';
                }

                if (value <= form.values.from) {
                    return 'Invalid: needs to be after "from" date.';
                }

                return null
            },
            elements: {
                cost_element: (value) => (value && value !== "" ? null : 'Please enter a cost element'),
            }
        },
    });

    const handleError = (response) => {
        let message = "Unable to create or edit cost of goods";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        setError(message)
    }

    const save = () => {
        setIsSaving(true)
        request({
            url: (costOfGoods && costOfGoods.id !== 0) ?
                `/tiktok-products-cogs/orchestrators/update` : `/tiktok-products-cogs/orchestrators/create`,
            method: "POST",
            data: {
                id: costOfGoods ? costOfGoods.id : null,
                product_id: productId,
                present: form.values.present,
                elements: form.values.elements,
                from: form.values.from ? moment(form.values.from).format('YYYY-MM-DD') : "2020-02-02",
                to: form.values.to ? moment(form.values.to).format('YYYY-MM-DD') : "2020-02-02",
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({queryKey: ['validateCogsByProductId']}).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listTikTokProducts'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listTikTokProductStats'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listTikTokProductDetailedStatsByProductId'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listAllStats'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listCogsByProductId'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listPresentCogsByProductId'] }).finally(() => {})
                    showNotification({
                        title: "Saved!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    onSave()
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    const elementFields = form.values.elements.map((item, index) => (
        <div className={"flex flex-wrap w-full mb-2"}>
            <div className={"lg:w-1/6 w-full flex gap-2"}>
                <TextInput
                    radius={"md"}
                    className={"pr-2"}
                    size={"xl"}
                    style={{width: "100%"}}
                    placeholder="Cost Element"
                    {...form.getInputProps(`elements.${index}.cost_element`)}
                />
            </div>
            <div className={"lg:w-2/6 w-full flex"}>
                <TextInput
                    radius={"md"}
                    className={"pr-2"}
                    size={"xl"}
                    style={{width: "100%"}}
                    placeholder="Comment"
                    {...form.getInputProps(`elements.${index}.comment`)}
                />
            </div>
            <div className={"lg:w-1/6 w-full flex"}>
                <NumberInput
                    min={0.01}
                    step={0.01}
                    precision={2}
                    radius={"md"}
                    size={"xl"}
                    className={"pr-2"}
                    style={{width: "100%"}}
                    placeholder="Amount"
                    {...form.getInputProps(`elements.${index}.amount`)}
                />
            </div>
            <div className={"lg:w-1/6 w-full flex"}>
                <NumberInput
                    min={0.01}
                    radius={"md"}
                    step={0.01}
                    precision={2}
                    size={"xl"}
                    className={"pr-2"}
                    style={{width: "100%"}}
                    placeholder="Units"
                    {...form.getInputProps(`elements.${index}.units`)}
                />
            </div>
            <div className={"lg:w-1/6 w-full flex"}>
                <NumberInput
                    min={0.01}
                    readOnly={true}
                    disabled={true}
                    step={0.01}
                    precision={2}
                    size={"xl"}
                    radius={"md"}
                    className={"pr-2"}
                    style={{width: "100%"}}
                    placeholder="Amount per unit"
                    value={form.values.elements[index].amount / form.values.elements[index].units}
                />
                {form.values.elements && form.values.elements.length > 1 && (
                    <Button
                        auto={true}
                        shadow
                        size={"xl"}
                        color={"error"}
                        icon={TRASH_ICON}
                        onClick={() => {
                            form.removeListItem('elements', index)
                        }}>
                    </Button>
                )}
            </div>
        </div>
    ));

    const getTotalAmountPerUnit = () => {
        let total = 0;
        for(const item of form.values.elements) {
            total = total + (item.amount / item.units)
        }
        return total
    }

    return (
        <form onSubmit={form.onSubmit((values) => save())}>
            <LoadingOverlay visible={isSaving} />
            {error && (
                <Alert radius={"md"} color="red" className={"mb-8"} icon={CLOSE_CIRCLE_ICON} title="Operation failed">
                    {error}
                </Alert>
            )}
            <div className={((isNew) ? "bg-yellow-50 " : "bg-gray-50 ") + "mb-8 rounded-xl shadow"}>
                <div className={"flex flex-wrap border-b p-4"}>
                    <div className={"lg:w-2/3 flex items-center"}>
                        {live && (
                            <div className={"flex items-center"}>
                                <div className={"w-4 h-4 mr-4 rounded-full bg-green-500"}></div>
                                <div>
                                    <Text className={"text-xl font-bold"}>
                                        Default
                                    </Text>
                                    <Text className={"text-xs opacity-50"}>
                                        Enter your cost elements below, and we'll use this as your default cost of goods record. You'll be able to add back-dated cost of goods after this.
                                    </Text>
                                </div>
                            </div>
                        )}
                        {!live && !form.values.present && (
                            <>
                                <DatePickerInput
                                    disabled={form.values.present}
                                    label={"From"}
                                    style={{width: "300px"}}
                                    size={"xl"}
                                    placeholder="From date"
                                    valueFormat="YYYY-DD-MM"
                                    className={"mr-4"}
                                    {...form.getInputProps('from')}
                                />
                            </>
                        )}
                        {!live && form.values.present && (
                            <>
                                <TextInput
                                    disabled={form.values.present}
                                    label={"From"}
                                    style={{width: "300px"}}
                                    size={"xl"}
                                    placeholder="From date"
                                    valueFormat="YYYY-DD-MM"
                                    className={"mr-4"}
                                    value="~"
                                />
                            </>
                        )}
                        {!form.values.present && (
                            <>
                                <DatePickerInput
                                    disabled={form.values.present}
                                    label={"To"}
                                    size={"xl"}
                                    style={{width: "300px"}}
                                    placeholder="To date"
                                    valueFormat="YYYY-DD-MM"
                                    {...form.getInputProps('to')}
                                />
                            </>
                        )}
                        {!live && (
                            <>
                                {form.values.present && (
                                    <>
                                        <TextInput
                                            disabled={true}
                                            label={"To"}
                                            size={"xl"}
                                            style={{width: "300px"}}
                                            placeholder="To date"
                                            value={"~"}
                                        />
                                    </>
                                )}
                                <Checkbox
                                    className={"ml-4"}
                                    size={"xl"}
                                    description={"Tick this to make this cost of goods record your default."}
                                    style={{width: "300px"}}
                                    label={"Default"}
                                    {...form.getInputProps('present', { type: 'checkbox' })}
                                />
                            </>
                        )}
                    </div>
                    <div className={"lg:w-1/3 flex items-center justify-end text-xl pr-10"}>
                        <div>
                            <Text className={"font-bold opacity-50 text-sm"}>TOTAL</Text>
                            £ {getTotalAmountPerUnit().toFixed(2)} / unit
                        </div>
                    </div>
                </div>
                <div className={"p-4"}>
                    <div className={"flex flex-wrap w-full mb-2"}>
                        <div className={"lg:w-1/6 w-full flex"}>
                            <Text className={"opacity-50 text-xs"}>COST ELEMENT</Text>
                        </div>
                        <div className={"lg:w-2/6 w-full flex"}>
                            <Text className={"opacity-50 text-xs"}>NOTES</Text>
                        </div>
                        <div className={"lg:w-1/6 w-full flex"}>
                            <Text className={"opacity-50 text-xs"}>AMOUNT</Text>
                        </div>
                        <div className={"lg:w-1/6 w-full flex"}>
                            <Text className={"opacity-50 text-xs"}>UNITS</Text>
                        </div>
                        <div className={"lg:w-1/6 w-full flex"}>
                            <Text className={"opacity-50 text-xs"}>AMOUNT PER UNIT</Text>
                        </div>
                    </div>
                    {elementFields}
                    <Button
                        className={"mt-4"}
                        style={{width: "100%"}}
                        shadow={true}
                        color={"primary"}
                        icon={PLUS_ICON}
                        size={"xl"}
                        onClick={() => {
                            form.insertListItem('elements', { units: 1, amount: 1, cost_element: "", comment: "" })
                        }}
                    >
                        Add Cost Element
                    </Button>
                </div>
            </div>
            <div className={"mt-4 flex items-center justify-end gap-4"}>
                <>
                    <Button
                        auto={true}
                        shadow
                        size={"xl"}
                        color={"default"}
                        icon={ARROW_LEFT_ICON}
                        onClick={() => onSave()}>
                        Cancel
                    </Button>
                    {/*<Button*/}
                    {/*    auto={true}*/}
                    {/*    shadow*/}
                    {/*    size={"xl"}*/}
                    {/*    color={"error"}*/}
                    {/*    icon={CLOSE_CIRCLE_ICON}*/}
                    {/*    onClick={() => onDelete(costOfGoods ? costOfGoods.id : null)}>*/}
                    {/*    Delete*/}
                    {/*</Button>*/}
                    <Button
                        auto={true}
                        shadow
                        size={"xl"}
                        color={"success"}
                        icon={CHECK_CIRCLE}
                        type={"submit"}
                    >
                        Save
                    </Button>
                </>
            </div>
        </form>
    )
}