import {Button, Text} from "@nextui-org/react";
import {
    ARROW_LEFT_ICON,
    ARROW_RIGHT_ICON_XS, ARROW_UP_ICON,
    CHECK_CIRCLE, CLOSE_CIRCLE_ICON, CLOSE_ICON,
    EMAIL_ICON,
    PASSWORD_ICON, REFRESH_ICON, REFRESH_ICON_SPIN
} from "../../../../Icons/FontAwesomeIcons";
import {LoadingOverlay, PasswordInput, TextInput} from "@mantine/core";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../Contexts/App";
import {useJobByLabel} from "../../../../Hooks/Job";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../../Interceptors/Request";
import {useQueryClient} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {useCurrentUser} from "../../../../Hooks/User";

export const Import = () => {
    const queryClient = useQueryClient()
    const {clearAppContext} = useContext(AppContext)
    const {job, isLoading: isLoadingJob} = useJobByLabel("IMPORT_PRODUCT_AND_ORDER_HISTORY")
    const [isSendingOnboardingSyncJob, setIsSendingOnboardingSyncJob] = useState(false)
    const [isContinuing, setIsContinuing] = useState(false)
    const navigate = useNavigate()

    const handleError = (response) => {
        let message = "Unable to perform action";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const triggerImport = () => {
        setIsSendingOnboardingSyncJob(true)
        request({
            url: `/user/orchestrators/start-onboarding-sync`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['getJobByLabel'] }).finally(() => {})
                    showNotification({
                        title: "Great job. We'll start importing right away.",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSendingOnboardingSyncJob(false))
    }

    const continueOnboarding = () => {
        setIsContinuing(true)
        request({
            url: `/user/orchestrators/update-onboarding`,
            method: "POST",
            data: {
                status: "fees_configuration",
                state: ""
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['currentUser'] }).finally(() => {})
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsContinuing(false))
    }

    return (
        <div
            className="h-full flex items-center justify-center w-3/4 mx-auto"
            style={{minHeight: "100vh"}}>
            <LoadingOverlay visible={isContinuing || isLoadingJob || isSendingOnboardingSyncJob} />
            <div className="my-10 flex items-center items-stretch justify-center h-full w-full">
                <div>
                    <img src="/assets/shopflow_logo.png" width={"100px"} className={"mb-4 block mx-auto"}/>
                    <Button
                        auto={true}
                        light
                        color={"default"}
                        icon={ARROW_LEFT_ICON}
                        onClick={() => clearAppContext()}
                        className={"block mx-auto mb-4"}
                    >
                        Logout
                    </Button>
                    <div className={"w-full text-center mb-10"}>
                        <Text className={"font-bold text-3xl"}>2. Let's import your products & orders</Text>
                    </div>
                    <div className="w-full" style={{minWidth: "500px"}}>
                        {!isLoadingJob && !job && (
                            <>
                                <Text className={"opacity-50 p-4"}>
                                    The first step is to import your products, and past orders (the important bit!).
                                </Text>
                                <Button
                                    style={{width: "100%"}}
                                    size={"xl"}
                                    disabled={isSendingOnboardingSyncJob}
                                    color={"success"}
                                    type={"submit"}
                                    iconRight={CHECK_CIRCLE}
                                    onClick={() => triggerImport()}>
                                    Import products & past order history
                                </Button>
                                <Text className={"text-green-600 p-4 w-full text-center justify-center items-center flex"}>
                                    <span className={"mr-2"}>{ARROW_UP_ICON}</span>
                                    (Click me)
                                </Text>
                            </>
                        )}
                        {!isLoadingJob && job && (
                            <div className={"bg-white rounded-xl shadow p-4"}>
                                {job.status === "success" && (
                                    <div className={"mb-4 py-24 px-4 bg-green-50 flex items-center justify-center rounded-xl "}>
                                        <div>
                                            <span className={"text-green-500 mb-4 w-full justify-center flex text-3xl"}>{CHECK_CIRCLE}</span>
                                            <Text className={"font-bold mb-4 w-full flex justify-center text-3xl"}>Import Successful</Text>
                                            <Text className={"text-lg"}>We've imported <b className={"underline"}>all</b> your products, and past orders. <br/><br/><b className={"underline"}>Next step</b> is to configure your ShopFlow installation.</Text>
                                        </div>
                                    </div>
                                )}
                                {job.status === "failed" && (
                                    <div className={"mb-4 py-24 px-4 bg-red-50 flex items-center justify-center rounded-xl "}>
                                        <div>
                                            <span className={"text-red-500 mb-4 w-full justify-center flex text-3xl"}>{CHECK_CIRCLE}</span>
                                            <Text className={"font-bold mb-4 w-full flex justify-center text-3xl"}>Import Failed</Text>

                                            <ul>
                                                {job.issues && job.issues.length > 0 && (
                                                    job.issues.map((issue, index) => (
                                                        <li key={index} className={"opacity-50"}><Text>{issue}</Text></li>
                                                    ))
                                                )}
                                            </ul>
                                            <Text className={"opacity-75 mt-8"}>
                                                * Please contact a member of staff if this issue persists.
                                            </Text>
                                        </div>
                                    </div>
                                )}
                                {job.status === "failed" && (
                                    <Button
                                        style={{width: "100%"}}
                                        size={"xl"}
                                        color={"success"}
                                        type={"submit"}
                                        iconRight={REFRESH_ICON}
                                        onClick={() => triggerImport()}>
                                        Retry
                                    </Button>
                                )}
                                {(job.status === "started" || job.status === "pending") && (
                                    <div className={"p-4 py-24 flex justify-center items-center"}>
                                        <div className={"text-center"}>
                                            <div className={"flex items-center justify-center w-full text-3xl"}>{REFRESH_ICON_SPIN}</div>
                                            <Text className={"opacity-50 mt-4 text-xl"}>Importing products & order history ...</Text>

                                            <Text className={"opacity-25 mt-8 underline"}>This can take several minutes. You can exit this page, and come back another time.</Text>
                                        </div>
                                    </div>
                                )}
                                {job.status === "success" && (
                                    <Button
                                        style={{width: "100%"}}
                                        size={"xl"}
                                        disabled={isContinuing}
                                        color={"success"}
                                        onClick={() => continueOnboarding()}
                                        type={"submit"}
                                        iconRight={ARROW_RIGHT_ICON_XS}>
                                        Continue
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}