import {CHECKMARK_ICON, CLOSE_ICON} from "../Icons/FontAwesomeIcons";

export const BACKEND_URL = process.env.REACT_APP_API_ENDPOINT;
//export const BACKEND_URL = "http://localhost:8000/"
export const DEFAULT_STALE_TIME = 1000 * 60 * 10;

export const calendarOptions = [
    {
        label: "This month, by day",
        value: "this_month_by_day"
    },
    {
        label: "Last 30 days, by day",
        value: "last_30_days_by_day"
    },
    {
        label: "Last 3 months, by week",
        value: "last_3_months_by_week"
    },
    {
        label: "This quarter, by week",
        value: "this_quarter_by_week"
    },
    {
        label: "Last 12 months, by month",
        value: "last_12_months_by_month"
    },
    {
        label: "This year, by month",
        value: "this_year_by_month"
    },
]
export const pricingItems = [
    {
        id: "starter",
        title: "Starter",
        description: "For new sellers",
        priceMonthly: 15.00,
        priceAnnually: 115.99,
        selectColor: "default",
        selectShadow: false,
        features: [
            {
                icon: CHECKMARK_ICON,
                title: "1 TikTok Shop"
            },
            {
                icon: CHECKMARK_ICON,
                title: "250 orders per month"
            },
            {
                icon: CHECKMARK_ICON,
                title: "100 products"
            },
        ],
    },
    {
        id: "intermediate",
        title: "Intermediate",
        description: "For established sellers",
        priceMonthly: 29.00,
        priceAnnually: 224.99,
        selectColor: "default",
        selectShadow: true,
        features: [
            {
                icon: CHECKMARK_ICON,
                title: "1 TikTok Shop"
            },
            {
                icon: CHECKMARK_ICON,
                title: "1,000 orders per month"
            },
            {
                icon: CHECKMARK_ICON,
                title: "250 products"
            },
        ],
    },
    {
        id: "master",
        title: "Master",
        description: "For large volume sellers",
        priceMonthly: 49.00,
        priceAnnually: 379.99,
        selectColor: "default",
        selectShadow: false,
        features: [
            {
                icon: CHECKMARK_ICON,
                title: "5 TikTok Shops"
            },
            {
                icon: CHECKMARK_ICON,
                title: "5,000 orders per month"
            },
            {
                icon: CHECKMARK_ICON,
                title: "500 products"
            },
        ],
    },
    {
        id: "super_seller",
        title: "Super Seller",
        description: "For large volume sellers",
        priceMonthly: 150.00,
        priceAnnually: 999.99,
        selectColor: "default",
        selectShadow: false,
        features: [
            {
                icon: CHECKMARK_ICON,
                title: "Unlimited TikTok Shops"
            },
            {
                icon: CHECKMARK_ICON,
                title: "20,000 orders per month"
            },
            {
                icon: CHECKMARK_ICON,
                title: "5,000 products"
            },
        ],
    }
]