import {Alert, LoadingOverlay} from "@mantine/core";
import {Button, Pagination, Table, Text} from "@nextui-org/react";
import {
    CHECK_CIRCLE,
    CLOCK_ICON,
    CLOSE_CIRCLE_ICON, CLOSE_ICON, DATABASE_ICON,
    EDIT_ICON_SMALL, INFO_CIRCLE_ICON,
    PLUS_ICON,
    TICKET_ICON,
    TRASH_ICON
} from "../../../Icons/FontAwesomeIcons";
import {useExpenseLines, useExpenses} from "../../../Hooks/Expenses";
import {useContext, useState} from "react";
import moment from "moment";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {AppContext} from "../../../Contexts/App";
import {useQueryClient} from "@tanstack/react-query";
import {CreateExpense} from "../../Modals/Expenses/CreateExpense";
import {UpdateExpense} from "../../Modals/Expenses/UpdateExpense";

export const ExpensesHistoryList = ({openCreateExpense, setOpenCreateExpense, setOpenUpdateExpense, setUpdatingExpense}) => {
    const queryClient = useQueryClient()
    const {clearAppContext} = useContext(AppContext)
    const [isDeleting, setIsDeleting] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(15)
    const {isLoading, total: totalExpenses, totalPages, expenses} = useExpenseLines(page, limit)

    const handleError = (response) => {
        let message = "Unable to process operation";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const deleteExpense = (expenseId) => {
        setIsDeleting(true)
        request({
            url: `/expense-lines/orchestrators/delete?expenseLineId=${expenseId}`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['listExpenseLines'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listExpenses'] }).finally(() => {})
                    showNotification({
                        title: "Deleted!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsDeleting(false))
    }

    return (
        <>
            <LoadingOverlay visible={isLoading || isDeleting} />
            <div className={"bg-white"}>
                <Alert icon={INFO_CIRCLE_ICON} title="How does this work?">
                    This page is used to detail a history of expenses added to your ShopFlow account. You can use the "Setup Expenses" page to setup scheduled or one-off expenses - they will appear here once billed.
                </Alert>
            </div>
            <div className={"pt-4 pb-4 px-4 border-t border-b bg-white w-full flex items-center"}>
                <div className={"mr-2 flex"}>
                    <div>
                        <Pagination
                            size={"sm"}
                            disabled={isDeleting}
                            color={"primary"}
                            page={page}
                            onChange={(page) => setPage(page)}
                            total={totalPages}
                        />
                    </div>
                </div>
            </div>
            <div className={"bg-white"}>
                {(!isLoading && expenses && expenses.length <= 0) && (
                    <div className={"p-20 text-xl text-center w-full flex items-center justify-center"}>
                        <div>
                            <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                            <Text className={"text-2xl font-bold my-4"}>No expense lines</Text>
                            <Text className={"text-sm opacity-50"}>Create an expense to record in-direct costs associated with the running of your business.</Text>
                            <div className={"w-full flex items-center justify-center"}>
                                <Button
                                    auto={true}
                                    className={"mt-4"}
                                    color={"success"}
                                    icon={PLUS_ICON}
                                    disabled={isDeleting || openCreateExpense}
                                    onClick={() => setOpenCreateExpense(true)}
                                >Add Expense</Button>
                            </div>
                        </div>
                    </div>
                )}
                {(!isLoading && expenses && expenses.length > 0) && (
                    <div className={"-mx-4"}>
                        <Table
                            lined
                            headerLined
                            shadow={false}
                            aria-label="Example table with static content"
                            css={{
                                height: "auto",
                                width: "100% !important",
                                marginTop: "0px",
                                borderRadius: "0px",
                                margin: "0px !important",
                                padding: "0px !important"
                            }}
                        >
                            <Table.Header>

                                <Table.Column><div className={"pl-4"}>NAME</div></Table.Column>
                                <Table.Column>DATE ADDED</Table.Column>
                                <Table.Column>AMOUNT</Table.Column>
                                <Table.Column>ACTIONS</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {expenses.map((expense, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            <div className={"pl-4"}>
                                                <Text className={"font-bold text-sm"}>
                                                    {expense.expense_name}
                                                </Text>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Text className={"opacity-50 text-sm"}>
                                                {moment(expense.date_created).format("LL")}
                                            </Text>
                                        </Table.Cell>
                                        <Table.Cell>
                                            £ {expense.amount.toFixed(2)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className={"flex gap-2 z-999"}>
                                                <Button
                                                    disabled={isDeleting}
                                                    auto={true}
                                                    color={"error"}
                                                    flat
                                                    icon={TRASH_ICON}
                                                    onClick={() => {
                                                        deleteExpense(expense.id)
                                                    }}
                                                />
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                )}
            </div>
        </>
    )
}