import {useContext, useEffect, useState} from "react";
import {LoadingOverlay, NumberInput, Text} from "@mantine/core";
import moment from "moment";
import {useTikTokDetailedProductStats, useTikTokProducts, useTikTokProductStats} from "../../Hooks/TiktokProducts";
import {
    ARROW_DOWN_ICON,
    ARROW_RIGHT_ICON_XS, CHECK_CIRCLE,
    CHECKMARK_ICON,
    CLOSE_CIRCLE_ICON, CLOSE_ICON,
    EDIT_ICON_SMALL, LINK_ICON, PLUS_ICON, REFRESH_ICON_SPIN, TRASH_ICON, WARNING_ICON
} from "../../Icons/FontAwesomeIcons";
import {Button, Pagination, Table} from "@nextui-org/react";
import {getCurrentMonthStartDate, getTodayPlusOne} from "../../Utils/Dates";
import {request} from "../../Interceptors/Request";
import {showNotification} from "@mantine/notifications";
import {AppContext} from "../../Contexts/App";
import {useQueryClient} from "@tanstack/react-query";
import {calcMargin, calcNetProfit, cutString, getCogsByProduct, getShippingChargesByProduct} from "../../Utils/Figures";
import {useNavigate} from "react-router-dom";
import {SingleLineChartImpl} from "../Charts/SingleLineChartImpl";
import {useDayByDayByProductIds} from "../../Hooks/Statistics";

export const ProductList = ({showDateRange, onCloseDateRange, activeOnly, showChart, from, to, selectedShopIds}) => {
    const {clearAppContext, settlementsOnly, calendarPeriod} = useContext(AppContext)
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [expandedProductId, setExpandedProductId] = useState(null)
    const [productIds, setProductIds] = useState([])
    const [updatingCogs, setUpdatingCogs] = useState(false)

    const {productIdToDateToTotalsMap} = useDayByDayByProductIds(from, to, selectedShopIds, productIds, showChart)
    const {
        isLoading: isLoadingProductStats,
        productIdToTotalsMap
    } = useTikTokProductStats(from, to, productIds, selectedShopIds)
    const {
        isLoading: isLoadingProducts,
        total: totalProducts,
        totalPages,
        products
    } = useTikTokProducts(calendarPeriod, activeOnly, page, limit, selectedShopIds)

    const [editProductId, setEditProductId] = useState(null)
    const [cogValue, setCogValue] = useState(null)

    useEffect(() => {
        if (products && products.length > 0) {
            setProductIds(products.map((product) => product.id))
        } else {
            setProductIds([])
        }
    }, [products])

    const handleError = (response) => {
        let message = "Unable to update cost of goods";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const updateCOGS = (productId, costOfGoods) => {
        setUpdatingCogs(true)
        request({
            url: `/tiktok-products/orchestrators/update-cogs`,
            method: "POST",
            data: {
                product_id: productId,
                cost_of_goods: costOfGoods
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({queryKey: ['listTikTokProducts']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listTikTokProductStats']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listTikTokProductDetailedStatsByProductId']}).finally(() => {
                    })
                    queryClient.invalidateQueries({queryKey: ['listAllStats']}).finally(() => {
                    })
                    showNotification({
                        title: "Success",
                        message: "We've updated the cost of goods for this product.",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setUpdatingCogs(false))

        setCogValue(null)
        setEditProductId(null)
    }

    const dateRangeSelection =
        <>
            {showDateRange && (
                <Button
                    auto={true}
                    flat
                    size={"sm"}
                    className={"ml-4"}
                    color={"primary"}
                    onClick={() => {
                        if (onCloseDateRange) onCloseDateRange()
                    }}
                >
                    Showing from <span
                    className="font-bold mx-2">{moment(from, "YYYY-MM-DD").format("LL")}</span> to <span
                    className="font-bold mx-2">{moment(to, "YYYY-MM-DD").format("LL")}</span>
                    <span className={"ml-6"}>
                                {CLOSE_ICON}
                            </span>
                </Button>
            )}
        </>

    const pagination = (
        <>
            <div className={"bg-white border-b pb-2 pt-2 px-4 w-full flex items-center justify-start text-lg"}>
                <div className={"lg:w-1/2 w-full flex items-center"}>
                    {products && products.length && (
                        <Text className={"m-0 opacity-50 text-xs"}>{products.length} / {totalProducts} total</Text>
                    )}
                    {dateRangeSelection}
                </div>
                <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                    {totalPages > 1 && (
                        <div className={"pb-1"}>
                            <Pagination
                                size={"xs"}
                                color={"primary"}
                                page={page}
                                onChange={(page) => setPage(page)}
                                total={totalPages}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )

    return (
        <div className={"w-full"}>

            <LoadingOverlay visible={isLoadingProducts || ((products && products.length > 0) && isLoadingProductStats)}
                            blur={4}/>
            {!activeOnly && products && products.length === 0 && (
                <>
                    <div className={"pt-4"}> {dateRangeSelection} </div>
                    <div className={"mt-20 p-20 text-xl opacity-50 text-center w-full"}>
                        <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                        No products setup. Create and approve a product in TikTok Seller Shop, and we'll add it here.
                    </div>
                </>
            )}
            {activeOnly && products && products.length === 0 && (
                <>
                    <div className={"pt-4"}> {dateRangeSelection} </div>
                    <div className={"mt-20 p-20 opacity-50 text-center w-full"}>
                        <div className={"mr-2 text-5xl"}>{CLOSE_CIRCLE_ICON}</div>
                       <div className={"mt-4 text-3xl"}>
                           No active products found in this date range.
                       </div>
                        <div className={"mt-4 opacity-50 text-sm"}>
                            Head over to the Products page to view your product
                            portfolio.
                        </div>
                    </div>
                </>
            )}
            {!isLoadingProductStats && !isLoadingProducts && products && products.length > 0 && (
                <>
                    <div className={"border-t"}></div>
                    {pagination}
                    <div className={"-mx-4"}>
                        <Table
                            lined
                            headerLined
                            aria-label="Example table with static content"
                            css={{
                                height: "auto",
                                width: "100% !important",
                                marginTop: "0px",
                                borderRadius: "0px",
                                margin: "0px !important",
                                padding: "0px !important"
                            }}
                        >
                            <Table.Header>
                                <Table.Column width="100px">
                                    <div className={"pl-4"}>PRODUCT</div>
                                </Table.Column>
                                <Table.Column>
                                    {showChart && ("LAST 30 DAYS")}
                                </Table.Column>
                                <Table.Column>UNITS</Table.Column>
                                <Table.Column>CANCELS</Table.Column>
                                <Table.Column>SALES</Table.Column>
                                <Table.Column>PLATFORM</Table.Column>
                                <Table.Column>VAT</Table.Column>
                                <Table.Column>
                                    AFFILIATES
                                    <br/>
                                    {!settlementsOnly && (
                                        <div className={""}>
                                            <Text className={"text-xs"} color={"orange"}>~ estimated</Text>
                                        </div>
                                    )}
                                </Table.Column>
                                <Table.Column>SHIPPING</Table.Column>
                                <Table.Column>COGS</Table.Column>
                                <Table.Column>NET PROFIT</Table.Column>
                                <Table.Column>MARGIN</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {products.map((product, index) => (
                                    <Table.Row
                                        key={product.id}>
                                        <Table.Cell>
                                            <div className={"flex justify-start pr-10"} style={{width: "300px"}}>
                                                {product.image_url && (
                                                    <img src={product.image_url} alt={"image-" + product.id}
                                                         style={{maxWidth: "40px"}}
                                                         className={"h-8 rounded-md shadow ml-4 mr-4"}/>
                                                )}
                                                {!product.image_url && (
                                                    <div
                                                        className={"bg-gray-50 h-8 rounded-md shadow ml-4 mr-4 px-4"}></div>
                                                )}
                                                <div className={"cursor-pointer"}>
                                                    <div
                                                        className={"font-medium text-black text-xs opacity-25"}>{product.id}</div>
                                                    <div
                                                        className={"font-medium text-black whitespace-pre-line text-sm text-blue-500 hover:text-blue-600"}
                                                        onClick={() => navigate(`/products/view/${product.id}`)}>
                                                        {cutString(product.name, 32)}
                                                        <span className={"ml-2"}>{LINK_ICON}</span>
                                                    </div>
                                                    <div className={"mt-4"}>
                                                        <Text
                                                            onClick={($event) => {
                                                                $event.stopPropagation()
                                                                navigate(`/products/configure/${product.id}?tab=cogs`)
                                                            }}
                                                            className={"hover:text-blue-500 cursor-pointer text-xs"}>
                                                            <span className={"mr-2 opacity-25"}>COGS: </span>
                                                            {(product
                                                                && product.edges
                                                                && product.edges.product_cost_of_goods_ranges
                                                                && product.edges.product_cost_of_goods_ranges.length <= 1) && (
                                                                <>
                                                                    £ {getCogsByProduct(product).toFixed(2)} / unit
                                                                    <span
                                                                        className={"ml-4 opacity-25"}>{EDIT_ICON_SMALL} Update</span>
                                                                </>
                                                            )}
                                                            {(product
                                                                && product.edges
                                                                && product.edges.product_cost_of_goods_ranges
                                                                && product.edges.product_cost_of_goods_ranges.length > 1) && (
                                                                <>
                                                                    ... / unit
                                                                    <span
                                                                        className={"ml-4 opacity-25"}>{EDIT_ICON_SMALL} Update</span>
                                                                </>
                                                            )}
                                                            {(product
                                                                && product.edges
                                                                && !product.edges.product_cost_of_goods_ranges) && (
                                                                <>
                                                                    <span className={"text-yellow-500"}>
                                                                        <span className={"mr-2"}>{WARNING_ICON}</span>
                                                                        Required
                                                                    </span>
                                                                    <span
                                                                        className={"ml-4 opacity-25"}>{EDIT_ICON_SMALL} Update</span>
                                                                </>
                                                            )}
                                                        </Text>
                                                    </div>
                                                    <div className={"mt-0"}>
                                                        <Text
                                                            onClick={($event) => {
                                                                $event.stopPropagation()
                                                                navigate(`/products/configure/${product.id}?tab=shipping`)
                                                            }}
                                                            className={"hover:text-blue-500 cursor-pointer text-xs"}>
                                                            {(product
                                                                && !product.auto_calc_shipping
                                                                && product.edges
                                                                && product.edges.product_shipping_charge_ranges
                                                                && product.edges.product_shipping_charge_ranges.length <= 1) && (
                                                                <>
                                                                    <span
                                                                        className={"mr-2 opacity-25"}>Shipping: </span>
                                                                    <span
                                                                        className={"opacity-100"}>£ {getShippingChargesByProduct(product).toFixed(2)} / unit</span>
                                                                    <span
                                                                        className={"ml-4 opacity-25"}>{EDIT_ICON_SMALL} Update</span>
                                                                </>
                                                            )}
                                                            {(product
                                                                && !product.auto_calc_shipping
                                                                && product.edges
                                                                && product.edges.product_shipping_charge_ranges
                                                                && product.edges.product_shipping_charge_ranges.length > 1) && (
                                                                <>
                                                                    <span className={"mr-2 opacity-25"}>Shipping: ... / unit</span>
                                                                    <span
                                                                        className={"ml-4 opacity-25"}>{EDIT_ICON_SMALL} Update</span>
                                                                </>
                                                            )}
                                                            {(product
                                                                && !product.auto_calc_shipping
                                                                && product.edges
                                                                && !product.edges.product_shipping_charge_ranges) && (
                                                                <>
                                                                    <span
                                                                        className={"mr-2 opacity-25"}>Shipping: </span>
                                                                    <span className={"text-yellow-500"}>
                                                                        <span className={"mr-2"}>{WARNING_ICON}</span>
                                                                        Required
                                                                    </span>
                                                                    <span
                                                                        className={"ml-4 opacity-25"}>{EDIT_ICON_SMALL} Update</span>
                                                                </>
                                                            )}
                                                            {(product && product.auto_calc_shipping) && (
                                                                <>
                                                                    <span
                                                                        className={"mr-2 opacity-25"}>Shipping: </span>
                                                                    <span className={"text-green-500"}>
                                                                            <span
                                                                                className={"mr-2"}>{CHECK_CIRCLE}</span>
                                                                            Auto-calculated
                                                                        </span>
                                                                </>
                                                            )}
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {showChart && (
                                                <>
                                                    {productIdToDateToTotalsMap && productIdToDateToTotalsMap[product.id] && (
                                                        <div style={{width: "200px", marginLeft: "-10px"}}>
                                                            <SingleLineChartImpl
                                                                height={"130px"}
                                                                dateToTotalPointMap={productIdToDateToTotalsMap[product.id]}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                    <>
                                                        <Text className={"text-sm"}>{
                                                            (productIdToTotalsMap[product.id].TotalUnits - productIdToTotalsMap[product.id].TotalUnitsCancelled)
                                                        }</Text>
                                                    </>
                                                )}
                                                {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                    <>
                                                        <Text className={"text-sm"}>0</Text>
                                                    </>
                                                )}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                    <div className={""}>
                                                        <Text className={"text-sm"}>{
                                                            (productIdToTotalsMap[product.id].TotalUnitsCancelled)
                                                        }</Text>
                                                    </div>
                                                )}
                                                {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                    <>
                                                        <Text className={"text-sm"}>0</Text>
                                                    </>
                                                )}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                    <>
                                                        <Text className={"text-sm"}>£ {
                                                            (productIdToTotalsMap[product.id].TotalSales).toFixed(2)
                                                        }</Text>
                                                    </>
                                                )}
                                                {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                    <>
                                                        <Text className={"text-sm"}>£ 0.00</Text>
                                                    </>
                                                )}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                    <>
                                                        <Text className={"text-sm"}>£ {
                                                            (productIdToTotalsMap[product.id].TotalPlatformFee).toFixed(2)
                                                        }</Text>
                                                    </>
                                                )}
                                                {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                    <>
                                                        <Text className={"text-sm"}>£ 0.00</Text>
                                                    </>
                                                )}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                    <>
                                                        <Text className={"text-sm"}>£ {
                                                            (productIdToTotalsMap[product.id].TotalVAT).toFixed(2)
                                                        }</Text>
                                                    </>
                                                )}
                                                {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                    <>
                                                        <Text className={"text-sm"}>£ 0.00</Text>
                                                    </>
                                                )}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                    <>
                                                        <Text className={"text-sm"}>£ {
                                                            (productIdToTotalsMap[product.id].TotalAffiliateCommission).toFixed(2)
                                                        }</Text>
                                                    </>
                                                )}
                                                {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                    <>
                                                        <Text className={"text-sm"}>£ 0.00</Text>
                                                    </>
                                                )}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                    <>
                                                        <Text className={"text-sm"}>£ {
                                                            (productIdToTotalsMap[product.id].TotalShippingFee).toFixed(2)
                                                        }</Text>
                                                    </>
                                                )}
                                                {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                    <>
                                                        <Text className={"text-sm"}>£ 0.00</Text>
                                                    </>
                                                )}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                <div>
                                                    {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                        <Text
                                                            className={"text-sm"}>
                                                            £ {(productIdToTotalsMap[product.id].TotalCogs.toFixed(2))}
                                                        </Text>
                                                    )}
                                                    {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                        <Text className={"text-sm"}>£ 0.00</Text>
                                                    )}
                                                </div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                    <Text
                                                        className={"text-sm"}>£ {calcNetProfit(productIdToTotalsMap[product.id]).toFixed(2)}
                                                    </Text>
                                                )}
                                                {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                    <Text className={"text-sm"}>£ 0.00</Text>
                                                )}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>
                                                {productIdToTotalsMap && productIdToTotalsMap[product.id] && (
                                                    <Text className={"text-sm"}>
                                                        {calcMargin(
                                                            calcNetProfit(productIdToTotalsMap[product.id]),
                                                            productIdToTotalsMap[product.id].TotalSales,
                                                            productIdToTotalsMap[product.id].TotalVAT).toFixed(2)}%
                                                    </Text>
                                                )}
                                                {(!productIdToTotalsMap || !productIdToTotalsMap[product.id]) && (
                                                    <Text className={"text-sm"}>£ 0.00</Text>
                                                )}
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                    {pagination}
                </>
            )}
            {((!isLoadingProductStats && !isLoadingProducts && !products) ||
                (!isLoadingProductStats && !isLoadingProducts && products && products.length == 0)) && (
                <div className={"text-black text-opacity-20 font-medium bg-white p-10 rounded-xl"}>
                    <Text c={"dimmed"}>
                        <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                        No products found.
                    </Text>
                </div>
            )}
        </div>
    )
}

