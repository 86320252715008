import {useContext} from "react";
import {AppContext} from "../Contexts/App";
import {useQuery} from "@tanstack/react-query";
import {request} from "../Interceptors/Request";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";



export const useShippingChargesByProductId = (product) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listShippingChargesByProductId', product, user],
        () => request({
            url: `/tiktok-shipping-charges/orchestrators/retrieve-by-product-id?productId=${product ? product.id : ""}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME, enabled: (product ? true : false)}
    )

    return {
        shippingCharges: data ? data.data : [],
        isLoading,
        error
    }
}

export const usePresentShippingChargesByProductId = (product) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listPresentShippingChargesByProductId', product, user],
        () => request({
            url: `/tiktok-shipping-charges/orchestrators/retrieve-present-by-product-id?productId=${product ? product.id : ""}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME, enabled: (product ? true : false)}
    )

    return {
        presentShippingCharge: data ? data.data : [],
        isLoading,
        error
    }
}

export const useShippingChargesValidate = (product) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['validateShippingChargesByProductId', product, user],
        () => request({
            url: `/tiktok-shipping-charges/orchestrators/validate?productId=${product ? product.id : ""}`,
            method: "POST"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME, enabled: (product ? true : false)}
    )

    return {
        issues: data ? data.data : [],
        isLoading,
        error
    }
}