import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../Contexts/App";
import {Button, Text} from "@nextui-org/react";
import {
    CHECKMARK_ICON, CLOCK_ICON,
    CLOSE_CIRCLE_ICON, CLOSE_ICON,
    CREDIT_CARD_ICON,
    LINK_ICON,
    SETTINGS_ICON,
    WARNING_ICON
} from "../../../Icons/FontAwesomeIcons";
import {PaymentsList} from "./PaymentsList";
import moment from "moment/moment";
import {UpgradeAccount} from "../../Modals/UpgradeAccount/UpgradeAccount";
import {useCurrentUser, useRetrieveStripeSubscriptionDetails} from "../../../Hooks/User";
import {pricingItems} from "../../../Constants/Constants";
import {CancelSubscription} from "../../Modals/CancelSubscription/CancelSubscription";
import {LoadingOverlay} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {useQueryClient} from "@tanstack/react-query";

export const Billing = () => {
    const {userAccount} = useCurrentUser()
    const {subscriptionDetails} = useRetrieveStripeSubscriptionDetails()
    const {subscriptionInactive, subscriptionPaymentFailed, subscription, clearAppContext} = useContext(AppContext)
    const [openUpgrade, setOpenUpgrade] = useState(false)
    const [openCancel, setOpenCancel] = useState(false)
    const [pricingItem, setPricingItem] = useState(null)
    const [isAnnual, setIsAnnual] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const queryClient = useQueryClient()

    const handleError = (response) => {
        let message = "Unable to perform action";
        if (response && response.data) {
            message = response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const stopCancellation = () => {
        setIsLoading(true)
        request({
            url: `/subscriptions/orchestrators/stop-cancellation`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    showNotification({
                        title: "Success! We've stopped your cancellation, and resumed your subscription.",
                        icon: CHECKMARK_ICON,
                        color: "green"
                    })

                    queryClient.clear()
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        for(const pricingItem of pricingItems) {
            if (pricingItem.id === subscription.plan.replaceAll("_annual", "")) {
                setPricingItem(pricingItem)
            }
        }

        if (subscription.plan.includes("annual")) {
            setIsAnnual(true)
        } else {
            setIsAnnual(false)
        }
    }, [subscription])

    return (
        <>
            <UpgradeAccount open={openUpgrade} setOpen={setOpenUpgrade} />
            <CancelSubscription details={subscriptionDetails} open={openCancel} setOpen={setOpenCancel} />
            <LoadingOverlay visible={isLoading} />
            <div className={"bg-white"}>
                <div className={"p-4 shadow-3xl border-b border-t"}>
                    <div className={"flex items-center gap-4 mb-2"}>
                        <div className={"text-xl font-bold"}>
                            <span className={"mr-2"}>{CREDIT_CARD_ICON}</span>
                            Billing & Subscription
                        </div>
                    </div>
                    <div className={""}>
                        <Text className={"text-xs opacity-50"}>
                            Manage your ShopFlow subscription & payment history.
                        </Text>
                    </div>
                </div>

                <div className={"mt-10 pb-32 bg-white p-4 lg:w-2/3 w-full mx-auto block"}>
                    <Text className={"mb-4 text-lg font-bold"}>
                        Your Subscription
                    </Text>
                    {subscription && (
                        <>
                            {subscription.plan === "free" && (
                                <div className={"mb-10 flex flex-wrap bg-gray-50 shadow p-10 rounded-xl"}>
                                    <div className={"lg:w-1/2 w-full"}>
                                        <Text className={"text-xl font-bold"}>Free</Text>
                                        <Text className={"text-md opacity-50"}>Unlimited access for free</Text>
                                    </div>
                                </div>
                            )}
                            {!subscriptionPaymentFailed && pricingItem && (
                                <div className={"mb-10 flex flex-wrap bg-gray-50 shadow p-10 rounded-xl"}>
                                    <div className={"lg:w-1/2 w-full"}>
                                        {subscriptionDetails && subscriptionDetails.cancel_at_period_end && (
                                            <div className={"mb-10 text-red-500"}>
                                                <span className={"mr-2"}>{CLOCK_ICON}</span>
                                                This subscription was cancelled, and will expire on {moment(subscriptionDetails.cancel_at).format("LL")}.
                                            </div>
                                        )}
                                        {subscriptionDetails && subscriptionDetails.status === "trialing" && (
                                            <div className={"mb-10 text-yellow-500"}>
                                                <span className={"mr-2"}>{CLOCK_ICON}</span>
                                                Your trial will end on {moment(subscriptionDetails.trial_end).format("LL")}.
                                            </div>
                                        )}
                                        <Text className={"text-xl font-bold"}>{pricingItem.title}</Text>
                                        <Text className={"text-md opacity-50"}>{pricingItem.description}</Text>

                                        <div className={"my-8"}>
                                            {pricingItem.features.map((feature, featureIndex) => (
                                                <div className={"pb-2"} key={featureIndex}>
                                                    <Text className={"text-md opacity-50"}>
                                                        <span className={"mr-2"}>{feature.icon}</span>
                                                        {feature.title}
                                                    </Text>
                                                </div>
                                            ))}
                                        </div>

                                        <div className={"flex gap-4"}>
                                            {subscriptionDetails && subscriptionDetails.cancel_at_period_end && (
                                                <Button auto={true} shadow color={"success"} icon={CLOSE_ICON} onClick={() => stopCancellation()}>
                                                    Stop Cancellation
                                                </Button>
                                            )}
                                            {(!subscriptionDetails || subscriptionDetails && !subscriptionDetails.cancel_at_period_end) && (
                                                <Button auto={true} shadow color={"primary"} icon={LINK_ICON} onClick={() => setOpenUpgrade(true)}>
                                                    Upgrade / Downgrade
                                                </Button>
                                            )}
                                            <div>
                                                <Button
                                                    disabled={subscriptionDetails && subscriptionDetails.cancel_at_period_end}
                                                    bordered
                                                    color={"error"}
                                                    icon={CLOSE_ICON}
                                                    onClick={() => setOpenCancel(true)}>
                                                    Cancel
                                                </Button>
                                                {subscriptionDetails && subscriptionDetails.cancel_at_period_end && (
                                                    <div>
                                                        <Text className={"mt-1 opacity-50 text-xs"}>You've already cancelled this subscription.</Text>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"lg:w-1/2 w-full flex justify-end"}>
                                        {isAnnual && (
                                            <div>
                                                <Text className={"mb-2 opacity-75"}>£ {pricingItem.priceAnnually.toFixed(2)} / year</Text>
                                                <Text className={"mb-4 text-green-500"}>34% off per month</Text>
                                            </div>
                                        )}
                                        {!isAnnual && (
                                            <Text className={"mb-4 opacity-75"}>£ {pricingItem.priceMonthly.toFixed(2)} / month</Text>
                                        )}
                                    </div>
                                </div>
                            )}
                            {subscriptionPaymentFailed && (
                                <div className={"mb-10 bg-red-50 rounded-xl"}>
                                    <div className={"p-4 py-20 text-center"}>
                                        <div className={"mr-2 text-3xl mb-6"}>{CLOSE_CIRCLE_ICON}</div>

                                        <Text className={"text-3xl font-bold"}>Your most recent payment failed!</Text>
                                        <Text className={"text-xl opacity-50"}>
                                            Please re-subscribe to enable access to ShopFlow again.
                                        </Text>
                                    </div>
                                    <Button
                                        shadow
                                        color={"success"}
                                        style={{width: "100%"}}
                                        className={"mt-2"}
                                        size={"xl"}
                                        onClick={() => setOpenUpgrade(true)}
                                        icon={LINK_ICON}>
                                        Subscribe
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                    {subscriptionInactive && (
                        <div className={"mb-10 bg-red-50 rounded-xl"}>
                            <div className={"p-4 py-20 text-center"}>
                                <div className={"mr-2 text-3xl mb-6"}>{CLOSE_CIRCLE_ICON}</div>

                                <Text className={"text-3xl font-bold"}>Your 14-day trial has ended.</Text>
                                <Text className={"text-xl opacity-50"}>
                                    You won't be able to use ShopFlow until you've upgraded your account to a paid subscription.
                                </Text>
                            </div>
                            <Button
                                shadow
                                color={"success"}
                                style={{width: "100%"}}
                                className={"mt-2"}
                                size={"xl"}
                                onClick={() => setOpenUpgrade(true)}
                                icon={LINK_ICON}>
                                Upgrade Account
                            </Button>
                        </div>
                    )}
                    <Text className={"mt-20 mb-4 text-lg font-bold"}>
                        Payment History
                    </Text>
                    <PaymentsList />
                </div>
            </div>
        </>
    )
}