import {Alert, Checkbox, LoadingOverlay, Modal, NumberInput, Select, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {
    CALENDAR_ICON, CHECK_CIRCLE,
    CHECKMARK_ICON,
    CLOCK_ICON,
    CLOSE_ICON,
    INFO_CIRCLE_ICON,
    PLUS_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Button} from "@nextui-org/react";
import {DatePickerInput} from "@mantine/dates";
import moment from "moment";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {useContext, useState} from "react";
import {AppContext} from "../../../Contexts/App";
import {useQueryClient} from "@tanstack/react-query";

export const CreateExpense = ({open, setOpen}) => {
    const queryClient = useQueryClient()
    const {clearAppContext} = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const form = useForm({
        initialValues: {
            label: "",
            type: "monthly",
            effective_date: new Date(),
            next_trigger_date: null,
            bill_now: false,
            amount: 10,
        },

        validate: {
            label: (value) => (value && value !== "" ? null : 'Invalid label'),
            type: (value) => (value && value !== "" ? null : 'Invalid type'),
            effective_date: (value) => (value && value !== "" ? null : 'Invalid effective from date'),
            amount: (value) => (value && value !== "" && value > 0 ? null : 'Invalid amount.')
        },
    });

    const handleError = (response) => {
        let message = "Unable to create expense";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const createExpense = () => {
        setIsSaving(true)
        request({
            url: `/expenses/orchestrators/create`,
            method: "POST",
            data: {
                ...form.values
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['listExpenseLines'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listExpenses'] }).finally(() => {})
                    showNotification({
                        title: "Saved!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    setOpen(false)
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    const getDescription = () => {
        const date = new Date(form.values.effective_date.toDateString())
        switch (form.values.type) {
            case "one_time":
                return "You'll be billed immediately"
            case "monthly":
                date.setMonth(date.getMonth() + 1);
                break;
            case "weekly":
                date.setDate(date.getDate() + 7);
                break;
            case "daily":
                date.setDate(date.getDate() + 1);
                break;
        }

        if (form.values.bill_now) {
            return "You'll be billed immediately, then on " + moment(date).format("LL") + " (continuing " + form.values.type + ")"
        }

        return "You'll be billed next on " + moment(date).format("LL")
    }

    return (
        <Modal zIndex={999}
               opened={open}
               closeButton
               fullScreen={true}
               onClose={() => setOpen(false)}
        >
            <Modal.Body>
                <LoadingOverlay visible={isSaving} />
                <form onSubmit={form.onSubmit((values) => createExpense())}>
                    <div className={"font-medium text-black text-xl"}>
                        <span className={"mr-4 opacity-50"}>{PLUS_ICON}</span>
                        Create Expense
                    </div>

                    <Alert className={"mt-8"} icon={INFO_CIRCLE_ICON} title="How does this work?">
                        Expenses are used to record indirect costs which we can't retrieve from your order channels. Create an expense and every time its due we'll record it
                        and include in your sales, and profit figures.
                    </Alert>

                    <div className={"mt-4"}>
                        <TextInput size={"lg"} label={"Label"} placeholder={"Warehouse Rent"} {...form.getInputProps('label')} />
                    </div>

                    <div className={"mt-4 w-full flex gap-4"}>
                        <div className={"lg:w-1/2 w-full"}>
                            <DatePickerInput
                                icon={CALENDAR_ICON}
                                dropdownType={"popover"}
                                             size={"lg"}
                                             label={"Effective From"}
                                             style={{width: "100%"}}
                                             valueFormat="YYYY-MM-DD"
                                             {...form.getInputProps('effective_date')}
                            />
                            <div className={"opacity-50"}>{getDescription()}</div>
                        </div>
                        <div className={"lg:w-1/2 w-full"}>
                            <Select
                                label={"Billing Frequency"}
                                size={"lg"}
                                icon={CLOCK_ICON}
                                data={[
                                    {
                                        label: "Monthly",
                                        value: "monthly"
                                    },
                                    {
                                        label: "Weekly",
                                        value: "weekly"
                                    },
                                    {
                                        label: "Daily",
                                        value: "daily"
                                    },
                                    {
                                        label: "One Time",
                                        value: "one_time"
                                    }
                                ]}
                                {...form.getInputProps('type')}/>
                        </div>
                    </div>

                    <NumberInput
                        size={"lg"}
                        label={"Amount"}
                        style={{width: "100%"}}
                        className={"mt-4"}
                        {...form.getInputProps('amount')}
                    />

                    {form.values.type !== "one_time" && (
                        <Checkbox
                            size={"lg"}
                            className={"mt-8"}
                            label={"Bill immediately? (today)"}
                            {...form.getInputProps('bill_now')} />
                    )}
                    <div className={"mt-8 flex justify-end"}>
                        <Button type={"submit"} shadow color={"success"} size={"lg"} icon={CHECKMARK_ICON}>Create</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}