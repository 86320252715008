import {useCurrentUser} from "../../Hooks/User";
import {useContext, useEffect} from "react";
import {AppContext} from "../../Contexts/App";
import {useNavigate} from "react-router-dom";

export const UserLoader = () => {
    const {} = useCurrentUser()
    const {hasTrialEnded} = useContext(AppContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (hasTrialEnded) {
            navigate("/billing")
        }
    }, [hasTrialEnded])

    return (<></>)
}