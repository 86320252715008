import {LoadingOverlay} from "@mantine/core";
import {Button, Text} from "@nextui-org/react";
import {ARROW_LEFT_ICON, ARROW_RIGHT_ICON_XS, CLOSE_ICON, QUESTION_MARK_ICON} from "../../../../Icons/FontAwesomeIcons";
import {useContext, useState} from "react";
import {AppContext} from "../../../../Contexts/App";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../../Interceptors/Request";
import {useQueryClient} from "@tanstack/react-query";

export const Finish = () => {
    const {clearAppContext} = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const queryClient = useQueryClient()

    const handleError = (response) => {
        let message = "Unable to perform action";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const finishOnboarding = () => {
        setIsSaving(true)
        request({
            url: `/user/orchestrators/finish-onboarding`,
            method: "POST",
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['currentUser'] }).finally(() => {})
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    return (
        <>
            <div
                className="h-full flex items-center justify-center w-3/4 mx-auto"
                style={{minHeight: "100vh"}}>
                <LoadingOverlay visible={isSaving} />
                <div className="my-10 flex items-center items-stretch justify-center h-full w-full">
                    <div>
                        <img src="/assets/shopflow_logo.png" width={"100px"} className={"mb-4 block mx-auto"}/>
                        <Button
                            auto={true}
                            light
                            color={"default"}
                            icon={ARROW_LEFT_ICON}
                            onClick={() => clearAppContext()}
                            className={"block mx-auto mb-4"}
                        >
                            Logout
                        </Button>
                        <div className={"w-full text-center mb-10"}>
                            <Text className={"font-bold text-3xl"}>5. Let's finish up!</Text>
                        </div>
                        <div className={"w-3/4 mx-auto block bg-white shadow rounded-xl p-4 flex justify-center items-center"}>
                            <div className={"w-full"}>
                                <Text className={"mb-8 text-xl pb-4 border-b"}>
                                    <b className={"underline"}>Great job</b>. We want to tell you about a few things before we send you into ShopFlow.
                                </Text>
                                <div className={"mb-20 flex gap-4"}>
                                    <div className={"w-8 h-8 rounded-full bg-gray-100 text-center flex justify-center items-center font-bold"}>1</div>
                                    <div>
                                        <Text className={"mb-8 text-xl"}>
                                            There are two modes: <b>"live"</b>, and <b>"completed"</b>. Locate the toggle shown below on your sidebar (once you've logged in) to switch between the two modes.
                                        </Text>
                                        <img src={"/assets/toggle.png"} alt={"toggle"} />
                                        <div className={"mt-8 mb-2 text-xl text-green-600 font-semibold"}>
                                            <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                            What's the difference?
                                        </div>
                                        <Text className={"mb-2 text-lg"}>
                                            Live mode is <b className={"underline"}>real-time</b> order data. This includes all order types.
                                        </Text>
                                        <Text className={"text-lg"}>
                                            Completed mode is <b className={"underline"}>completed</b> order data only. This includes accurate commission figures, platform fees, and more. Orders can take up to 30 days to complete.
                                        </Text>
                                        <div className={"mt-8 bg-gray-50 rounded-xl p-4"}>
                                            <Text className={"mb-2 text-sm font-bold"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                FREQUENTLY ANSWERED QUESTIONS
                                            </Text>
                                            <Text className={"mt-8 mb-2 text-sm opacity-50"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                How do I view accurate affiliate commission data?
                                            </Text>
                                            <Text className={"text-sm font-semibold"}>
                                                The most accurate mode to view order data from will always be <b className={"underline"}>completed</b> mode. This is because we only receive accurate info when the order has been marked as completed, and settled.
                                            </Text>
                                            <Text className={"mt-8 mb-2 text-sm opacity-50"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                Do you estimate affiliate commission figures in live mode?
                                            </Text>
                                            <Text className={"text-sm font-semibold"}>
                                                Yes, after we've imported your orders, we'll estimate your affiliate commission figures. Remember to switch to "completed" mode to view accurate affiliate commission data.
                                            </Text>
                                            <Text className={"mt-8 mb-2 text-sm opacity-50"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                Why is there no sales data in "completed" mode?
                                            </Text>
                                            <Text className={"text-sm font-semibold"}>
                                                Orders take between 2 - 30 days to complete, and settle. As a result, your sales figures in "completed" mode will have a lag time before they appear, however, once settled we are able to accurately report affiliate commission figures, platform fees, refunds, and more.
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mb-20 flex gap-4"}>
                                    <div className={"w-8 h-8 rounded-full bg-gray-100 text-center flex justify-center items-center font-bold"}>2</div>
                                    <div>
                                        <Text className={"mb-4 text-xl"}>
                                            <b className={"underline"}>Don't forget</b> to configure cost of goods and shipping information for each product in your portfolio by clicking "update" on each product.
                                        </Text>
                                        <img src={"/assets/cogsv2.png"} alt={"cost_of_goods"} width={"300px"} />
                                        <div className={"mt-8 bg-gray-50 rounded-xl p-4"}>
                                            <Text className={"mb-2 text-sm font-bold"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                FREQUENTLY ANSWERED QUESTIONS
                                            </Text>
                                            <Text className={"mt-8 mb-2 text-sm opacity-50"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                Can we have different cost of goods/shipping info for different periods?
                                            </Text>
                                            <Text className={"text-sm font-semibold"}>
                                                Yes, you'll be able to setup a "default" figure, as well as adjustment figures for any date periods in which your cost of goods, or shipping charges change.
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mb-20 flex gap-4"}>
                                    <div className={"w-8 h-8 rounded-full bg-gray-100 text-center flex justify-center items-center font-bold"}>3</div>
                                    <div>
                                        <Text className={"mb-4 text-xl"}>
                                            <b className={"underline"}>Some products</b> do not require shipping information. We'll show the following text when shipping is auto-calculated at time of order.
                                        </Text>
                                        <img src={"/assets/shipping_auto_calc.png"} alt={"shipping_auto_calc"} />
                                        <div className={"mt-8 bg-gray-50 rounded-xl p-4"}>
                                            <Text className={"mb-2 text-sm font-bold"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                FREQUENTLY ANSWERED QUESTIONS
                                            </Text>
                                            <Text className={"mt-8 mb-2 text-sm opacity-50"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                What about if I change it?
                                            </Text>
                                            <Text className={"text-sm font-semibold"}>
                                                If you change a product from automatic fulfilment to seller-fulfilled then we'll pick this up within 60 minutes, and mark the product for shipping charge info collection.
                                            </Text>
                                            <Text className={"mt-8 mb-2 text-sm opacity-50"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                What if I have two different delivery options, one that is fulfilled by my company, and one which is auto fulfilled?
                                            </Text>
                                            <Text className={"text-sm font-semibold"}>
                                                If we find any products with multiple delivery options we will always ask you to update shipping charges in ShopFlow so that we can be as accurate as possible when reporting profit figures.
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mb-4 flex gap-4"}>
                                    <div className={"w-8 h-8 rounded-full bg-gray-100 text-center flex justify-center items-center font-bold"}>4</div>
                                    <div>
                                        <Text className={"mb-4 text-xl"}>
                                            Create <span className={"bg-yellow-100 underline"}>scheduled</span> or <span className={"bg-gray-100 underline"}>one-off</span> <b className={"underline"}>expenses</b> to record in-direct costs associated with running your TikTok Shop.
                                        </Text>
                                        <img src={"/assets/expenses.png"} alt={"expenses"} width={"300px"} />
                                        <div className={"mt-8 bg-gray-50 rounded-xl p-4"}>
                                            <Text className={"mb-2 text-sm font-bold"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                FREQUENTLY ANSWERED QUESTIONS
                                            </Text>
                                            <Text className={"mt-8 mb-2 text-sm opacity-50"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                                What scheduling periods are available?
                                            </Text>
                                            <Text className={"text-sm font-semibold"}>
                                                You are able to schedule Daily, Weekly, and Monthly expenses, as well as one off charges.
                                            </Text>
                                            <Text className={"mt-8 mb-2 text-sm opacity-50"}>
                                                <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                                               Are these shown on my dashboards?
                                            </Text>
                                            <Text className={"text-sm font-semibold"}>
                                                Yes. We'll deduct expenses from your profit figures.
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    className={"mt-8"}
                                    style={{width: "100%"}}
                                    disabled={isSaving}
                                    onClick={() => finishOnboarding()}
                                    size={"xl"}
                                    color={"success"}
                                    type={"submit"}
                                    iconRight={ARROW_RIGHT_ICON_XS}>
                                    Ok, got it! I've read everything, and I'm ready for the ShopFlow experience.
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}