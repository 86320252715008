import {Alert, Checkbox, LoadingOverlay, Modal, NumberInput, Select, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {
    CALENDAR_ICON, CHECK_CIRCLE,
    CHECKMARK_ICON,
    CLOCK_ICON,
    CLOSE_ICON,
    INFO_CIRCLE_ICON,
    PLUS_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Button} from "@nextui-org/react";
import {DatePickerInput} from "@mantine/dates";
import moment from "moment";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {useContext, useState} from "react";
import {AppContext} from "../../../Contexts/App";
import {useQueryClient} from "@tanstack/react-query";

export const UpdateExpense = ({open, setOpen, expense}) => {
    const queryClient = useQueryClient()
    const {clearAppContext} = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const form = useForm({
        initialValues: expense ? expense : {
            label: "",
            amount: 0,
        },

        validate: {
            label: (value) => (value && value !== "" ? null : 'Invalid label.'),
            amount: (value) => (value && value !== "" && value > 0 ? null : 'Invalid amount.')
        },
    });

    const handleError = (response) => {
        let message = "Unable to update expense";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const updateExpense = () => {
        setIsSaving(true)
        request({
            url: `/expenses/orchestrators/update`,
            method: "POST",
            data: {
                id: expense.id,
                label: form.values.label,
                effective_date: expense.effective_date,
                type: expense.type,
                amount: form.values.amount,
            }
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['listExpenseLines'] }).finally(() => {})
                    queryClient.invalidateQueries({ queryKey: ['listExpenses'] }).finally(() => {})
                    showNotification({
                        title: "Saved!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    setOpen(false)
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    return (
        <Modal zIndex={999}
               opened={open}
               closeButton
               fullScreen={true}
               onClose={() => setOpen(false)}
        >
            <Modal.Body>
                <LoadingOverlay visible={isSaving} />
                <form onSubmit={form.onSubmit((values) => updateExpense())}>
                    <div className={"font-medium text-black text-xl"}>
                        <span className={"mr-4 opacity-50"}>{PLUS_ICON}</span>
                        Update Expense
                    </div>

                    <div className={"mt-4"}>
                        <TextInput size={"lg"} label={"Label"} placeholder={"Warehouse Rent"} {...form.getInputProps('label')} />
                    </div>

                    {/*<div className={"mt-4 w-full flex gap-4"}>*/}
                    {/*    <div className={"lg:w-1/2 w-full"}>*/}
                    {/*        <DatePickerInput*/}
                    {/*            icon={CALENDAR_ICON}*/}
                    {/*            dropdownType={"popover"}*/}
                    {/*                         size={"lg"}*/}
                    {/*                         label={"Effective From"}*/}
                    {/*                         style={{width: "100%"}}*/}
                    {/*                         valueFormat="YYYY-MM-DD"*/}
                    {/*                         {...form.getInputProps('effective_date')}*/}
                    {/*        />*/}
                    {/*        <div className={"opacity-50"}>{getDescription()}</div>*/}
                    {/*    </div>*/}
                    {/*    <div className={"lg:w-1/2 w-full"}>*/}
                    {/*        <Select*/}
                    {/*            label={"Billing Frequency"}*/}
                    {/*            size={"lg"}*/}
                    {/*            icon={CLOCK_ICON}*/}
                    {/*            data={[*/}
                    {/*                {*/}
                    {/*                    label: "Monthly",*/}
                    {/*                    value: "monthly"*/}
                    {/*                },*/}
                    {/*                {*/}
                    {/*                    label: "Weekly",*/}
                    {/*                    value: "weekly"*/}
                    {/*                },*/}
                    {/*                {*/}
                    {/*                    label: "Daily",*/}
                    {/*                    value: "daily"*/}
                    {/*                },*/}
                    {/*                {*/}
                    {/*                    label: "One Time",*/}
                    {/*                    value: "one_time"*/}
                    {/*                }*/}
                    {/*            ]}*/}
                    {/*            {...form.getInputProps('type')}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <NumberInput
                        size={"lg"}
                        label={"Amount"}
                        style={{width: "100%"}}
                        className={"mt-4"}
                        {...form.getInputProps('amount')}
                    />

                    <div className={"mt-8 flex justify-end"}>
                        <Button type={"submit"} shadow color={"success"} size={"lg"} icon={CHECKMARK_ICON}>Save Changes</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}