export const LiveOrdersMode = () => {
    return (
        <>
            <div>
                <div className={"text-3xl font-bold"}>Live Orders Mode</div>
                <div className={"opacity-50 text-md"}>What's live orders mode?</div>

                <div className={"mt-8 mb-8 opacity-50 text-md"}>
                    TikTok provides us with two data channels for orders: unsettled & settled orders. <br/><br/>
                    In live orders mode we will provide you with the most up to date, real-time order data from your shop(s) from unsettled (uncompleted, but placed) orders. TikTok only sends us some of the order data for unsettled orders, and so, as a result, we do our best to estimate figures like affiliate commission (based on your past order data) so that you can get an idea of real-time costs.
                </div>

                <div className={"mb-8 opacity-50 text-md"}>
                    You can switch to <b>Completed Orders Mode</b> to get 100% accurate settled order data, which includes figures like affiliate commissions.
                </div>
            </div>
        </>
    )
}