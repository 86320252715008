import {Button, Checkbox, Text} from "@nextui-org/react";
import {ARROW_DOWN_ICON, ARROW_RIGHT_ICON_XS, ARROW_UP_ICON, CLOSE_ICON} from "../../../Icons/FontAwesomeIcons";
import {requestEmpty} from "../../../Interceptors/Request";
import {showNotification} from "@mantine/notifications";
import {useState} from "react";
import {Container, LoadingOverlay} from "@mantine/core";


export const LoginWithTikTok = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isUs, setIsUs] = useState(false)
    const [isSandbox, setIsSandbox] = useState(false)

    const handleError = (response) => {
        let message = "Unable to login with Tiktok";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const loginWithTikTok = () => {
        setIsLoading(true)
        requestEmpty({
            url: `/tiktok/oauth/authorize/uris`,
            method: "GET"
        })
            .then((response) => {
                if (response && response.status === 200) {
                    let url;
                    if (isSandbox && isUs) {
                        url = response.data["sandbox_us"];
                    } else if (isSandbox && !isUs) {
                        url = response.data["sandbox_non_us"];
                    } else if (!isSandbox && !isUs) {
                        url = response.data["live_non_us"];
                    }  else if (!isSandbox && isUs) {
                        url = response.data["live_us"];
                    }

                    url = url + "&state=" + isUs + "," + isSandbox

                    window.location.href = url
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <LoadingOverlay visible={isLoading} />
            <Text className={"opacity-50 w-full mb-4 text-center font-bold"}>
                No account? <span className={"ml-2 mr-2"}>{ARROW_DOWN_ICON}</span> Click this button to get started
            </Text>
            <div className="border w-full">
                <Button
                    style={{width: "100%"}}
                    disabled={isLoading}
                    size={"xl"}
                    color={"success"}
                    iconRight={ARROW_RIGHT_ICON_XS}
                    onClick={() => loginWithTikTok()}>
                    <div className={"flex gap-4 text-white"}>
                        <div className="text-white">
                           Authorize with
                        </div>
                        <div className={"flex items-center"}>
                            <img src={"/assets/tiktoklogo.png"} alt={"tiktok logo"} width={"24px"} />
                        </div>
                        <div>
                            TikTok
                        </div>
                    </div>
                </Button>
            </div>
        </>
    )
}