import {useSet} from "@uidotdev/usehooks";
import {useSettingItems} from "../../../Hooks/SettingItems";
import {useForm} from "@mantine/form";
import {Checkbox, LoadingOverlay, NumberInput} from "@mantine/core";
import {CALENDAR_ICON, CHECK_CIRCLE, CLOSE_ICON, SETTINGS_ICON} from "../../../Icons/FontAwesomeIcons";
import {DatePickerInput} from "@mantine/dates";
import {useContext, useEffect, useState} from "react";
import {Button, Text} from "@nextui-org/react";
import {showNotification} from "@mantine/notifications";
import {request} from "../../../Interceptors/Request";
import {AppContext} from "../../../Contexts/App";
import {useQueryClient} from "@tanstack/react-query";

export const Shipping = () => {
    const queryClient = useQueryClient()
    const {settingItems, isLoading} = useSettingItems(["subtract_shipping_in_gross_profits"])
    const [isSaving, setIsSaving] = useState(false)
    const {clearAppContext} = useContext(AppContext)

    const form = useForm({
        initialValues: {
            subtract_shipping_in_gross_profits: true
        },

        validate: {
        },
    });

    const handleError = (response) => {
        let message = "Unable to save changes";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const save = () => {
        setIsSaving(true)
        request({
            url: `/setting-items/orchestrators/create-or-update-bulk`,
            method: "POST",
            data: [
                {
                    key: "subtract_shipping_in_gross_profits",
                    value: form.values.subtract_shipping_in_gross_profits.toString(),
                }
            ]
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    queryClient.invalidateQueries({ queryKey: ['getSettingItemsByKeysCache'] }).finally(() => {})
                    showNotification({
                        title: "Saved!",
                        icon: CHECK_CIRCLE,
                        color: "green",
                        autoClose: 15000,
                    })
                    return
                }

                handleError(response)
            })
            .catch((error) => {
                if (error) handleError()
            })
            .finally(() => setIsSaving(false))
    }

    useEffect(() => {
        if (settingItems) {
            if (settingItems.subtract_shipping_in_gross_profits) {
                const value = settingItems.subtract_shipping_in_gross_profits.value === "false" ? false : true
                form.setValues({subtract_shipping_in_gross_profits: value})
            }
        }
    }, [settingItems])

    return (
        <>
            <LoadingOverlay visible={isLoading} />
            <form onSubmit={form.onSubmit((values) => save())}>
                <div className={"px-4 py-4 w-full flex items-center"}>
                    <div className={"lg:w-1/2 w-full flex items-center"}>
                        <Text className={"text-xl font-bold"}>
                            Shipping Configuration
                        </Text>
                    </div>
                    <div className={"lg:w-1/2 w-full flex items-center justify-end"}>
                        <Button disabled={isSaving} shadow color={"success"} icon={CHECK_CIRCLE} type={"submit"} auto={true}>Save Changes</Button>
                    </div>
                </div>
                <div className={"p-4"}>
                    <Checkbox
                        label={"Subtract shipping in gross profits"}
                        {...form.getInputProps('subtract_shipping_in_gross_profits', { type: 'checkbox' })}
                    />
                </div>
            </form>
        </>
    )
}