import {useCurrentUser} from "../../../../Hooks/User";
import {Route, Routes} from "react-router-dom";
import {Import} from "./Import";
import {PlatformFeeConfiguration} from "./PlatformFeeConfiguration";
import {VAT, VatConfiguration} from "./VatConfiguration";
import {Finish} from "./Finish";
import {SubscriptionSetup} from "./SubscriptionSetup";

export const OnboardingWrapper = () => {
    const {userAccount} = useCurrentUser()
    return (
        <>
            {userAccount && userAccount.user && (
                <>
                    {userAccount.user.onboarding_status === "subscription_setup" && (
                        <SubscriptionSetup/>
                    )}
                    {userAccount.user.onboarding_status === "importing" && (
                        <Import/>
                    )}
                    {userAccount.user.onboarding_status === "fees_configuration" && (
                        <PlatformFeeConfiguration/>
                    )}
                    {userAccount.user.onboarding_status === "vat_configuration" && (
                        <VatConfiguration/>
                    )}
                    {userAccount.user.onboarding_status === "finish" && (
                        <Finish/>
                    )}
                </>
            )}
        </>
    )
}