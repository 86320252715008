import {useContext, useEffect, useState} from "react";
import {AppContext} from "../Contexts/App";
import {useQuery} from "@tanstack/react-query";
import {request} from "../Interceptors/Request";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";

export const useJobByLabel = (label) => {
    const {user, clearAppContext} = useContext(AppContext)
    const [job, setJob] = useState(null)
    const {isLoading, error, data} = useQuery(
        ['getJobByLabel', label, user],
        () => request({
            url: `/jobs/orchestrators/retrieve-by-label?label=${label}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {refetchInterval: 10000}
    )

    useEffect(() => {
        if (data && data.data) {
            let issues = []
            try {
                issues = JSON.parse(data.data.issues)
            } catch(e) {
                console.log("failed to parse")
            }

            setJob({
                ...data.data,
                issues: issues
            })
        } else {
            setJob(null)
        }
    }, [data])

    return {
        job,
        isLoading,
        error
    }
}