import {ARROW_RIGHT_ICON_XS} from "../../../Icons/FontAwesomeIcons";

export const AffiliateEstimation = () => {
    return (
        <>
            <div>
                <div className={"text-3xl font-bold"}>Setup Affiliate Commissions</div>
                <div className={"opacity-50 text-md"}>For live orders only. In completed mode we show accurate affiliate figures.</div>

                <div className={"mt-8 mb-8 opacity-50 text-md"}>
                    TikTok provides us with two data channels: unsettled, and settled orders. For unsettled orders TikTok does not send us the affiliate commission figures until the order is completed, and finalised (this is because the figures regularly change from order inception to order completion). As a result, for unsettled orders ("live mode") we have to estimate your affiliate commissions.
                </div>

                <div className={"mt-8 mb-8 text-md"}>
                    You can find the affiliate commission setup page by heading to <b>Settings</b> <span className={"mx-4"}>{ARROW_RIGHT_ICON_XS}</span> <b>Affiliate Commission.</b>
                </div>

                <div className={"mt-8 mb-8 opacity-50 text-md"}>
                    For live orders (those placed within the last 30 days), we ask that you select the "Average affiliate commission %" option. This is necessary because TikTok does not provide us with affiliate data until the order is completed, which can take up to 15-30 days. While this is an estimate, it is based on the percentage you've paid out to affiliates in the past 30 days. You can switch to completed orders mode at any time to view your actual affiliate payout amount.
                </div>

                <div className={"mt-8 opacity-50 text-md font-bold"}>
                    * Note that we only offer one option for estimating affiliate commissions for unsettled orders. We are working on new and innovative ways to estimate your affiliate commission figures, and will be adding new estimation techniques over the next couple of updates to ShopFlow.
                </div>
            </div>
        </>
    )
}