import {useContext, useEffect} from "react";
import {useQuery} from "@tanstack/react-query";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";
import {request} from "../Interceptors/Request";
import {AppContext} from "../Contexts/App";
import moment from "moment/moment";

export const useRetrieveStripeSubscriptionDetails = () => {
    const {user, subscription, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['retrieveStripeSubDetails', user],
        () => request({url: "/user/orchestrators/retrieve-stripe-subscription-details", method: "GET"}, clearAppContext)
            .then((data) => data.data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME, enabled: user && subscription && subscription.plan !== "inactive" && subscription.status !== "payment_failed"}
    )

    return {
        subscriptionDetails: data,
        isLoading,
        error
    }
}


export const useCurrentUser = () => {
    const {user, setSubscriptionPaymentFailed, setSubscription, setSubscriptionInactive, clearAppContext, setSettlementsOnly, setOnboardingStatus, setIsOnboardingMode, setOnboardingState, setIsLoginEnabled} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['currentUser', user],
        () => request({url: "/user", method: "GET"}, clearAppContext)
            .then((data) => data.data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME, enabled: (!!user)}
    )

    useEffect(() => {
        if (data && data.user) {
            if (data.user.subscription) {
                const sub = data.user.subscription
                setSubscription(sub)

                if (sub.status === "payment_failed") {
                    setSubscriptionPaymentFailed(true)
                } else {
                    setSubscriptionPaymentFailed(false)
                }

                if (sub.status === "missing" && sub.plan === "inactive") {
                    setSubscriptionInactive(true)
                } else {
                    setSubscriptionInactive(false)
                }
            }

            localStorage.setItem("SHOPA-settled-mode", data.user.settled_mode)
            setSettlementsOnly(data.user.settled_mode)

            setIsLoginEnabled(data.user.login_enabled)
            setOnboardingStatus(data.user.onboarding_status)
            setIsOnboardingMode(data.user.onboarding_mode)

            try {
                const stateAsJson = JSON.parse(data.user.onboarding_state)
                setOnboardingState(stateAsJson)
            } catch (exception) {
                setOnboardingState(null)
            }
        }
    }, [data])

    return {
        userAccount: data,
        isLoading,
        error
    }
}
