import {LoadingOverlay, Select, Textarea, TextInput} from "@mantine/core";
import {
    ARROW_DOWN_ICON,
    ARROW_LEFT_ICON, CALENDAR_ICON,
    CHECK_CIRCLE, CLOCK_ICON,
    CLOSE_CIRCLE_ICON,
    EMAIL_ICON,
    FILE_ICON,
    PLUS_ICON,
    TRASH_ICON
} from "../../../Icons/FontAwesomeIcons";
import {Button, Pagination, Table, Text} from "@nextui-org/react";
import moment from "moment";
import {useForm} from "@mantine/form";
import {CirclePicker} from "react-color";
import {useNavigate} from "react-router-dom";
import {DatePickerInput} from "@mantine/dates";

const defaultColor = {
    "hsl": {
        "h": 4.105263157894738,
        "s": 0.8962264150943399,
        "l": 0.5843137254901961,
        "a": 1
    },
    "hex": "#f44336",
    "rgb": {
        "r": 244,
        "g": 67,
        "b": 54,
        "a": 1
    },
    "hsv": {
        "h": 4.105263157894738,
        "s": 0.7786885245901639,
        "v": 0.9568627450980393,
        "a": 1
    },
    "oldHue": 174.4,
    "source": "hex"
}

const scheduleDatePeriodTypeOptions = [
    {
        label: "Day",
        value: "current_day",
        description: "We'll filter down data by the day that the report was scheduled in."
    },
    {
        label: "Week",
        value: "current_week",
        description: "We'll filter down data by the week that the report was scheduled in."
    },
    {
        label: "Month",
        value: "current_month",
        description: "We'll filter down data by the month that the report was scheduled in."
    },
    {
        label: "Quarter",
        value: "current_quarter",
        description: "We'll filter down data by the quarter that the report was scheduled in."
    },
    {
        label: "Year",
        value: "current_year",
        description: "We'll filter down data by the year that the report was scheduled in."
    },
    {
        label: "Fixed Date Period",
        value: "one_time_fixed_date_period",
        description: "We'll filter down data by a fixed date range."
    }
]

const scheduleTypeOptions = [
    {
        label: "One Time",
        value: "one_time",
        description: "Generates report instantly"
    },
    {
        label: "Daily",
        value: "Daily",
        description: "End of every day @ midnight"
    },
    {
        label: "Weekly",
        value: "weekly",
        description: "End of every week @ midnight"
    },
    {
        label: "Monthly",
        value: "monthly",
        description: "End of every month @ midnight"
    },
    {
        label: "Quarterly",
        value: "quarterly",
        description: "End of every quarter @ midnight"
    },
    {
        label: "Yearly",
        value: "yearly",
        description: "End of every year @ midnight"
    },
]

export const CreateEditReport = () => {
    const navigate = useNavigate()
    const form = useForm({
        initialValues: {
            color: defaultColor,
            label: "",
            description: "",
            schedule_type: "one_time",
            schedule_date_range: "one_time_fixed_date_period",
            level: "order",
            config: {
                from: null,
                to: null,
            }
        },

        validate: {
            config: {
                from: (value) => form.values.schedule_date_range === "one_time_fixed_date_period" ? (value && value !== "" ? null : 'Invalid from date') : null,
                to: (value) => form.values.schedule_date_range === "one_time_fixed_date_period" ? (value && value !== "" ? null : 'Invalid to date') : null,
            },
            label: (value) => (value && value !== "" ? null : "Please enter a label."),
            description: (value) => (value && value !== "" ? null : "Please enter a description.")
        },
    });

    const save = () => {
        console.log(form.values)
    }

    const setColor = (color) => {
        form.setValues({color: color})
    }

    return (
        <>
            <form onSubmit={form.onSubmit((values) => save())}>
                <div className={"bg-white pl-0 p-4 py-1"}>
                    <Button light auto={true} onClick={() => navigate(-1)}>
                        <span className={"mr-2"}>
                            {ARROW_LEFT_ICON}
                        </span>
                        Back
                    </Button>
                </div>
                <div className={"bg-white p-4 shadow-3xl border-b border-t flex"}>
                    <div className={"lg:w-1/2 w-full"}>
                        <div className={"flex items-center"}>
                            <div className={"text-xl font-bold"}>
                                <span className={"mr-2"}>{PLUS_ICON}</span>
                                Create Report
                            </div>
                        </div>
                        <Text className={"text-xs opacity-50"}>
                            Configure a new report
                        </Text>
                    </div>
                    <div className={"lg:w-1/2 w-full flex justify-end items-center"}>
                        <Button
                            type={"submit"}
                            color={"success"}
                            shadow={true}
                            icon={CHECK_CIRCLE}
                        >Save</Button>
                    </div>
                </div>
                <div className={"bg-white px-4 pb-10 pt-8 border-b"}>
                    <div className={"mb-4 text-xs opacity-50 text-uppercase font-bold"}>GENERAL</div>
                    <div className={"flex flex-wrap"}>
                        <div className={"lg:w-1/2 w-full lg:pr-8 pr-0 lg:pt-0 pt-4"}>
                            <TextInput
                                radius={"md"}
                                size={"lg"}
                                label={"Report Name"}
                                placeholder={"e.g Quarterly Settlement Report"}
                                {...form.getInputProps('label')}
                            />

                            <Textarea rows={5}
                                      radius={"md"}
                                      className={"mt-4"}
                                      size={"lg"}
                                      label={"Description"}
                                      placeholder={"Used to report at product level quarterly finalised sales"}
                                      {...form.getInputProps('description')}
                            />
                        </div>
                        <div className={"lg:w-1/2 w-full lg:pl-8 pl-0 lg:pt-0 pt-4"}>
                            <label className="mb-2 mantine-InputWrapper-label mantine-TextInput-label mantine-1cwg1aj"
                                   htmlFor="mantine-6c418dehd" id="mantine-6c418dehd-label">Color</label>
                            <CirclePicker width={"100%"}
                                          color={form.values.color}
                                          onChangeComplete={(value) => setColor(value)}
                            />
                        </div>
                    </div>
                </div>
                <div className={"bg-white px-4 pb-10 pt-8 border-b"}>
                    <div className={"mb-4 text-xs opacity-50 text-uppercase font-bold"}>SCHEDULING</div>
                    <label className="mb-2 mantine-InputWrapper-label mantine-TextInput-label mantine-1cwg1aj"
                           htmlFor="mantine-6c418dehd" id="mantine-6c418dehd-label">
                        <span className={"mr-2"}>{CLOCK_ICON}</span>
                        How often should we create this report?
                    </label>
                    <div className={"flex flex-wrap gap-2"}>
                        {scheduleTypeOptions.map((scheduleType, index) => (
                            <div
                                key={index}
                                onClick={() => form.setValues({schedule_type: scheduleType.value})}
                                className={"w-1/5 mb-2 flex items-center border cursor-pointer px-10 p-4 rounded-xl hover:bg-blue-50 hover:scale-105 " +
                                    (form.values.schedule_type === scheduleType.value ? " bg-blue-100" : "")}>
                                <div>
                                    <div className={"text-sm font-semibold uppercase"}>{scheduleType.label}</div>
                                    {scheduleType.description && (
                                        <div className={"text-xs opacity-50"}>{scheduleType.description}</div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={"mt-10"}></div>
                    <label className="mb-2 mantine-InputWrapper-label mantine-TextInput-label mantine-1cwg1aj"
                           htmlFor="mantine-6c418dehd" id="mantine-6c418dehd-label">
                        <span className={"mr-2"}>{CLOCK_ICON}</span>
                        What date range should we use for this report when processing?
                    </label>
                    <div className={""}>
                        {scheduleDatePeriodTypeOptions.map((dateOption, index) => (
                            <div
                                key={index}
                                onClick={() => form.setValues({schedule_date_range: dateOption.value})}
                                className={"flex items-center mb-2 border cursor-pointer p-4 rounded-xl hover:bg-blue-50 " +
                                    (form.values.schedule_date_range === dateOption.value ? " bg-blue-100" : "")}>
                                <div className={"text-sm font-semibold"}>
                                    <span className={"mr-2 opacity-25"}>{CALENDAR_ICON}</span>
                                    {dateOption.label}
                                </div>
                                {dateOption.description && (
                                    <div className={"ml-4 text-xs opacity-50"}>{dateOption.description}</div>
                                )}
                            </div>
                        ))}
                    </div>
                    {form.values.schedule_date_range === "one_time_fixed_date_period" && (
                        <div className={"bg-yellow-50 rounded-xl mt-4 p-4"}>
                            <label className="mb-2 mantine-InputWrapper-label mantine-TextInput-label mantine-1cwg1aj"
                                   htmlFor="mantine-6c418dehd" id="mantine-6c418dehd-label">
                                <span className={"mr-2"}>{CLOCK_ICON}</span>
                                You've selected a fixed date range, please select the report's date range below:
                            </label>
                            <div className={"flex gap-4"}>
                                <DatePickerInput
                                    label={"From"}
                                    radius={"md"}
                                    size={"lg"}
                                    style={{width: "100%"}}
                                    placeholder="From date"
                                    valueFormat="YYYY-MM-DD"
                                    className={"mr-4"}
                                    {...form.getInputProps('config.from')}
                                />
                                <DatePickerInput
                                    label={"To"}
                                    radius={"md"}
                                    size={"lg"}
                                    style={{width: "100%"}}
                                    placeholder="To date"
                                    valueFormat="YYYY-MM-DD"
                                    {...form.getInputProps('config.to')}
                                />
                            </div>
                        </div>
                        )}
                </div>
            </form>
        </>
    )
}