import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {BACKEND_URL} from "../Constants/Constants";
import {CLOSE_ICON} from "../Icons/FontAwesomeIcons";

const axiosClient = axios.create({baseURL: BACKEND_URL, timeout: 1000 * 60 * 15})
export const request = (options, clearAppContext) => {
    const settledMode = localStorage.getItem("SHOPA-settled-mode") === "true"
    if (settledMode) {
        axiosClient.defaults.headers.common.Settled = "true"
    } else {
        axiosClient.defaults.headers.common.Settled = "false"
    }

    const localStorageUserToken = localStorage.getItem("SHOPA-token");
    if (localStorageUserToken) {
        axiosClient.defaults.headers.common.Authorization = "Bearer " + localStorageUserToken
    }
    const onSuccess = (response) => {
        return response
    }
    const onError = (error) => {
        const localStorageUser = localStorage.getItem("SHOPA-user");
        if (error &&
            error.response &&
            error.response.status === 401 &&
            localStorageUser &&
            localStorage.getItem("SHOPA-session-expired") !== "true") {
            showNotification({
                title: "Session expired",
                color: "red",
                icon: CLOSE_ICON,
                message: "Please log back in to continue."
            })
            localStorage.setItem("SHOPA-session-expired", "true")
            clearAppContext()
        } else if(error && error.response.data.toString().includes("PERMISSION_DENIED")) {
            showNotification({
                title: "Operation failed",
                message: "Permission denied. You may not have authority to perform this action.",
                icon: CLOSE_ICON,
                color: "red"
            })
        }
        return error
    }
    
    return axiosClient
        .request(options)
        .then(onSuccess)
        .catch(onError)
}

export const requestStats = (options, clearAppContext, settledMode) => {
    if (settledMode) {
        axiosClient.defaults.headers.common.Settled = "true"
    } else {
        axiosClient.defaults.headers.common.Settled = "false"
    }

    const localStorageUserToken = localStorage.getItem("SHOPA-token");
    if (localStorageUserToken) {
        axiosClient.defaults.headers.common.Authorization = "Bearer " + localStorageUserToken
    }
    const onSuccess = (response) => {
        return response
    }
    const onError = (error) => {
        const localStorageUser = localStorage.getItem("SHOPA-user");
        if (error &&
            error.response &&
            error.response.status === 401 &&
            localStorageUser &&
            localStorage.getItem("SHOPA-session-expired") !== "true") {
            showNotification({
                title: "Session expired",
                color: "red",
                icon: CLOSE_ICON,
                message: "Please log back in to continue."
            })
            localStorage.setItem("SHOPA-session-expired", "true")
            clearAppContext()
            return error
        } else if(error && error.response.data.toString().includes("PERMISSION_DENIED")) {
            showNotification({
                title: "Operation failed",
                message: "Permission denied. You may not have authority to perform this action.",
                icon: CLOSE_ICON,
                color: "red"
            })
        }
        return error
    }

    return axiosClient
        .request(options)
        .then(onSuccess)
        .catch(onError)
}

export const requestEmpty = (options) => {
    const localStorageUserToken = localStorage.getItem("SHOPA-token");
    if (localStorageUserToken) {
        axiosClient.defaults.headers.common.Authorization = "Bearer " + localStorageUserToken
    }
    const onSuccess = (response) => {
        return response
    }
    const onError = (error) => {
        const localStorageUser = localStorage.getItem("SHOPA-user");
        if (error &&
            error.response &&
            error.response.status === 401 &&
            localStorageUser &&
            localStorage.getItem("SHOPA-session-expired") !== "true") {
            showNotification({
                title: "Session expired",
                color: "red",
                icon: CLOSE_ICON,
                message: "Please log back in to continue."
            })
            localStorage.setItem("SHOPA-session-expired", "true")
        } else if(error && error.response.data.toString().includes("PERMISSION_DENIED")) {
            showNotification({
                title: "Operation failed",
                message: "Permission denied. You may not have authority to perform this action.",
                icon: CLOSE_ICON,
                color: "red"
            })
        }
        return error
    }

    return axiosClient
        .request(options)
        .then(onSuccess)
        .catch(onError)
}

