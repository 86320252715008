import {useContext} from "react";
import {AppContext} from "../Contexts/App";
import {useQuery} from "@tanstack/react-query";
import {request} from "../Interceptors/Request";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";



export const usePlatformFees = () => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listPlatformFees', user],
        () => request({
            url: `/tiktok-platform-fees/orchestrators/retrieve`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )

    return {
        platformFees: data ? data.data : [],
        isLoading,
        error
    }
}

export const usePlatformFeesValidate = () => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['validatePlatformFees', user],
        () => request({
            url: `/tiktok-platform-fees/orchestrators/validate`,
            method: "POST"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )

    return {
        issues: data ? data.data : [],
        isLoading,
        error
    }
}