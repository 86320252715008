import {useContext} from "react";
import {AppContext} from "../Contexts/App";
import {useQuery} from "@tanstack/react-query";
import {request} from "../Interceptors/Request";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";

export const useCogsValidate = (product) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['validateCogsByProductId', product, user],
        () => request({
            url: `/tiktok-products-cogs/orchestrators/validate?productId=${product ? product.id : ""}`,
            method: "POST"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME, enabled: (product ? true : false)}
    )

    return {
        issues: data ? data.data : [],
        isLoading,
        error
    }
}

export const usePresentCogsByProductId = (product) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listPresentCogsByProductId', product, user],
        () => request({
            url: `/tiktok-products-cogs/orchestrators/retrieve-present-by-product-id?productId=${product ? product.id : ""}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME, enabled: (product ? true : false)}
    )

    return {
        presentCostOfGoods: data ? data.data : [],
        isLoading,
        error
    }
}

export const useCogsByProductId = (product) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listCogsByProductId', product, user],
        () => request({
            url: `/tiktok-products-cogs/orchestrators/retrieve-by-product-id?productId=${product ? product.id : ""}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME, enabled: (product ? true : false)}
    )

    return {
        cogs: data ? data.data : [],
        isLoading,
        error
    }
}