import {showNotification} from "@mantine/notifications";
import {CHECK_CIRCLE, CLOSE_ICON, QUESTION_MARK_ICON} from "../../Icons/FontAwesomeIcons";
import {request} from "../../Interceptors/Request";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../Contexts/App";
import {useQueryClient} from "@tanstack/react-query";
import {Switch, Tooltip} from "@nextui-org/react";
import {Checkbox, LoadingOverlay, Text} from "@mantine/core";
import {useCurrentUser} from "../../Hooks/User";

export const ProductVatExemptToggle = ({productId, value}) => {
    const {clearAppContext} = useContext(AppContext)
    const [updating, setUpdating] = useState(false)

    const handleError = (response) => {
        let message = "Unable to switch VAT exemption";
        if (response && response.response.data) {
            message = response.response.data.error
        }

        showNotification({
            title: "Operation failed",
            message: message,
            icon: CLOSE_ICON,
            color: "red",
            autoClose: 15000,
        })
    }

    const updateVatExempt = (newValue) => {
        setUpdating(true)
        request({
            url: `/tiktok-products/orchestrators/update-vat-exempt?productId=${productId}&exempt=${newValue}`,
            method: "POST"
        }, clearAppContext)
            .then((response) => {
                if (response && response.status === 200) {
                    setTimeout(() => {
                        window.location.reload()
                        setUpdating(false)
                    }, 2000)
                    return
                }

                handleError(response)
                setUpdating(false)
            })
            .catch((error) => {
                if (error) handleError()
                setUpdating(false)
            })
    }

    return (
        <>
            <LoadingOverlay visible={updating} />
            <div>
                <Tooltip placement="right" color="primary"
                         content={"Mark this product as VAT exempt, and we won't add VAT charges when calculating your sales figures."}>
                    <Text className={"mb-2 opacity-50 text-xs"}>
                        <span className={"mr-2"}>{QUESTION_MARK_ICON}</span>
                        What is this?
                    </Text>
                </Tooltip>
                <div>
                    <Checkbox
                        label={"VAT Exempt"}
                        checked={value}
                        onChange={(value) => {
                            updateVatExempt(value.target.checked)
                        }}
                    />
                </div>
            </div>
        </>
    )
}