import {Text} from "@nextui-org/react";
import {CLOSE_CIRCLE_ICON} from "../../Icons/FontAwesomeIcons";
import {calcMargin, calcNetProfit} from "../../Utils/Figures";
import {useTikTokDetailedProductStats} from "../../Hooks/TiktokProducts";
import {LoadingOverlay} from "@mantine/core";
import {AppContext} from "../../Contexts/App";
import {useContext, useEffect, useState} from "react";

export const SkuProductList = ({from, to, selectedShopIds, product}) => {
    const {settlementsOnly} = useContext(AppContext)
    const {isLoading: isLoadingProductDetailedStats, skuToTotalsMap, rawData: rawSkuData} = useTikTokDetailedProductStats(from, to, [product.id], selectedShopIds)

    return (
        <>
            <LoadingOverlay visible={isLoadingProductDetailedStats} />
            <div className={"flex flex-wrap w-full"}>
                <div className={"lg:w-2/5 w-full flex items-center justify-start text-lg"}>
                    <div className={"pl-20 opacity-25 text-black text-xs"}>
                        SKU Codes
                        {rawSkuData && rawSkuData.length && (
                            <span className={"ml-2 opacity-50"}>
                                                                ({rawSkuData.length})
                                                               </span>
                        )}
                    </div>
                </div>
                <div className={"lg:w-3/5 w-full text-lg flex items-center py-2"}>
                    <div className={"lg:w-1/6 w-full items-center flex"}>
                        <Text className={"opacity-50 text-xs"}>UNITS</Text>
                    </div>
                    <div className={"lg:w-1/6 w-full"}>
                        <Text className={"opacity-50 text-xs"}>CANCELS</Text>
                    </div>
                    <div className={"lg:w-1/6 w-full"}>
                        <Text className={"opacity-50 text-xs"}>SALES</Text>
                    </div>
                    <div className={"lg:w-1/6 w-full"}>
                        <Text className={"opacity-50 text-xs"}>Net VAT</Text>
                        <div className={""}>
                            <Text className={"text-xs"} color={"orange"}>~ estimated</Text>
                        </div>
                    </div>
                    <div className={"lg:w-1/6 w-full"}>
                        <Text className={"opacity-50 text-xs"}>AFFILIATES</Text>
                        {!settlementsOnly && (
                            <div className={""}>
                                <Text className={"text-xs"} color={"orange"}>~ estimated</Text>
                            </div>
                        )}
                    </div>
                    <div className={"lg:w-1/6 w-full"}>
                        <Text className={"opacity-50 text-xs"}>SHIPPING</Text>
                    </div>
                    {/*<div className={"lg:w-1/6 w-full"}>*/}
                    {/*    <Text className={"opacity-50 text-xs pt-2"}>CANCELLATIONS (£)</Text>*/}
                    {/*</div>*/}
                    <div className={"lg:w-1/6 w-full"}>
                        <Text className={"opacity-50 text-xs"}>COGS</Text>
                    </div>
                    <div className={"lg:w-1/6 w-full flex"}>
                        <Text className={"opacity-50 text-xs"}>NET PROFIT</Text>
                    </div>
                    <div className={"lg:w-1/6 w-full flex"}>
                        <Text className={"opacity-50 text-xs"}>MARGIN</Text>
                    </div>
                </div>
            </div>
            {[product].map((product, index) => (
                <div key={index} className={"shadow-3xl"}>
                    <div
                        className={"border-t cursor-pointer bg-white hover:bg-gray-50"}>
                        {!isLoadingProductDetailedStats && (
                            <>
                                {!rawSkuData && (
                                    <div className={"pl-20 pb-4 mt-8 flex flex-wrap w-full"}>
                                        <div className={"lg:w-1/5 w-full flex items-center justify-start text-lg"}>
                                            <div>
                                                <div className={"opacity-25 text-black text-xs"}>
                                                    <span className={"mr-2"}>{CLOSE_CIRCLE_ICON}</span>
                                                    No SKU data recorded in our system for this period
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(rawSkuData && rawSkuData.length > 0 && skuToTotalsMap) && (
                                    <div>
                                        {rawSkuData.map((skuDetails, index) => (
                                            <div key={index} className={(index === 1 ? "border-b " : "border-b border-t ") + "flex flex-wrap w-full pb-2 pt-2 bg-blue-50"}>
                                                <div className={"pl-20 lg:w-1/5 w-full flex items-center justify-start text-lg"}>
                                                    <div>
                                                        <div className={"opacity-50 text-black text-xs"}>{skuDetails.SKU ? skuDetails.SKU : "Default"}</div>
                                                    </div>
                                                </div>
                                                <div className={"lg:w-1/5 w-full flex items-center justify-start text-lg"}>
                                                </div>
                                                <div className={"lg:w-3/5 w-full text-lg flex items-center"}>
                                                    <div className={"flex w-full items-center"}>
                                                        <div className={"lg:w-1/6 w-full"}>
                                                            <div>
                                                                <Text className={"text-xs"}>{skuDetails.TotalUnits - skuDetails.TotalUnitsCancelled}</Text>
                                                            </div>
                                                        </div>
                                                        <div className={"lg:w-1/6 w-full"}>
                                                            <div>
                                                                <Text className={"text-xs"}>{skuDetails.TotalUnitsCancelled}</Text>
                                                            </div>
                                                        </div>
                                                        <div className={"lg:w-1/6 w-full"}>
                                                            <div>
                                                                <Text className={"text-xs"}>£ {(skuDetails.TotalSales).toFixed(2)}</Text>
                                                            </div>
                                                        </div>
                                                        <div className={"lg:w-1/6 w-full"}>
                                                            <div>
                                                                <Text className={"text-xs"}>£ {(skuDetails.TotalVAT - ((skuDetails.TotalAffiliateCommission / 6) + (skuDetails.TotalShippingFee / 6))).toFixed(2)}</Text>
                                                            </div>
                                                        </div>
                                                        <div className={"lg:w-1/6 w-full"}>
                                                            <div>
                                                                <Text className={"text-xs"}>£ {(skuDetails.TotalAffiliateCommission).toFixed(2)}</Text>
                                                            </div>
                                                        </div>
                                                        <div className={"lg:w-1/6 w-full"}>
                                                            <div>
                                                                <Text className={"text-xs"}>£ {(skuDetails.TotalShippingFee).toFixed(2)}</Text>
                                                            </div>
                                                        </div>
                                                        {/*<div className={"lg:w-1/6 w-full"}>*/}
                                                        {/*    <div>*/}
                                                        {/*        <Text className={"text-xs"}>£ {skuDetails.TotalSalesCancelled.toFixed(2)}</Text>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className={"lg:w-1/6 w-full"}>
                                                            <Text
                                                                className={"text-xs"}>
                                                                £ {skuDetails.TotalCogs.toFixed(2)}
                                                            </Text>
                                                        </div>
                                                        <div className={"lg:w-1/6 w-full"}>
                                                            <div>
                                                                <Text className={"text-xs"}>£ {
                                                                    calcNetProfit(skuDetails).toFixed(2)
                                                                }
                                                                </Text>
                                                            </div>
                                                        </div>
                                                        <div className={"lg:w-1/6 w-full flex items-center"}>
                                                            <div>
                                                                <Text className={"text-xs"}>
                                                                    {calcMargin(
                                                                        calcNetProfit(skuDetails),
                                                                        skuDetails.TotalSales,
                                                                        skuDetails.TotalVAT).toFixed(2)}%
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            ))}
        </>
    )
}