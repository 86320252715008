import {AppContext} from "../Contexts/App";
import {useQuery} from "@tanstack/react-query";
import {request, requestStats} from "../Interceptors/Request";
import {DEFAULT_STALE_TIME} from "../Constants/Constants";
import {useContext, useEffect, useState} from "react";
import {datesEqualOrBaseIsGreater, formatDate, getStartDayOfNextMonth} from "../Utils/Dates";
import {orderStatuses} from "../Components/Pages/Dashboard/DashboardChartsSection";
import {useSettingItems, useSettingItemsCache} from "./SettingItems";
import moment from "moment/moment";

const isDifferenceMoreThanXDays = (x, date1, date2) => {
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    var diffDays = Math.abs((date2.getTime() - date1.getTime()) / oneDay);

    return diffDays > x;
}

const shouldOverride = (calendarPeriod) => {
    let override = ""
    if (calendarPeriod === "this_month_by_day") {
        return "&overridePeriodType=day"
    } else if (calendarPeriod === "this_quarter_by_week") {
        return "&overridePeriodType=week"
    } else if (calendarPeriod === "this_year_by_month") {
        return "&overridePeriodType=month"
    }
    return override
}

export const useAggregatedStatsByProductId = (calendarPeriod, from, to, selectedShopIds, selectedOrderStatuses, productId) => {
    const {user, clearAppContext, settlementsOnly} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listAllStatsByProductId', calendarPeriod, settlementsOnly, user, from, to, selectedShopIds, selectedOrderStatuses, productId],
        () => requestStats({
            url: `/period-by-period-stats-by-product-id?from=${from}&to=${to}` +
                `${shouldOverride(calendarPeriod)}` +
                `${selectedShopIds && selectedShopIds.length > 0 ? "&shopIds=" + selectedShopIds.join(",") : ""}` +
                `${selectedOrderStatuses && selectedOrderStatuses.length > 0 ? "&orderStatuses=" + selectedOrderStatuses.join(",") : "&orderStatuses=" + orderStatuses.join(",")}` +
                `${productId && productId !== "" ? "&productId=" + productId : ""}`,
            method: "GET"
        }, clearAppContext, settlementsOnly)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )
    const [dateToTotalsMap, setDateToTotalsMap] = useState(null)

    useEffect(() => {
        let date = new Date(from)
        const endDate = new Date(to)

        let step = 1
        if (isDifferenceMoreThanXDays(180, date, endDate)) {
            step = null
        } else if (isDifferenceMoreThanXDays(40, date, endDate)) {
            step = 7
        }

        if (data && data.data) {
            const newMap = {}

            while (date.getTime() < endDate.getTime()) {
                const formatted = formatDate(date)
                if (newMap[formatted] == null) {
                    newMap[formatted] = {
                        DateCaptured: formatted,
                        TotalSales: 0,
                        TotalNetProfits: 0,
                        TotalUnits: 0,
                        TotalAffiliateCommission: 0,
                    }
                }

                if (!step) {
                    date = getStartDayOfNextMonth(date)
                } else {
                    date.setDate(date.getDate() + step);
                }
            }

            for (const day of data.data) {
                newMap[formatDate(new Date(day["DateCaptured"]))] = day
            }

            setDateToTotalsMap(newMap)
        } else {
            const newMap = {}

            while (date.getTime() < endDate.getTime()) {
                const formatted = formatDate(date)
                if (newMap[formatted] == null) {
                    newMap[formatted] = {
                        DateCaptured: formatted,
                        TotalSales: 0,
                        TotalUnits: 0,
                        TotalNetProfits: 0,
                        TotalAffiliateCommission: 0,
                    }
                }

                if (!step) {
                    date = getStartDayOfNextMonth(date)
                } else {
                    date.setDate(date.getDate() + step);
                }
            }

            setDateToTotalsMap(newMap)
        }

    }, [data])

    return {
        dateToTotalsMap,
        rawData: data ? data.data : [],
        isLoading,
        error
    }
}

export const useAggregatedStats = (calendarPeriod, from, to, selectedShopIds, selectedOrderStatuses) => {
    const {user, clearAppContext, settlementsOnly} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listAllStats', calendarPeriod, settlementsOnly, user, from, to, selectedShopIds, selectedOrderStatuses],
        () => requestStats({
            url: `/period-by-period-stats?from=${from}&to=${to}` +
                `${shouldOverride(calendarPeriod)}` +
                `${selectedShopIds && selectedShopIds.length > 0 ? "&shopIds=" + selectedShopIds.join(",") : ""}` +
                `${selectedOrderStatuses && selectedOrderStatuses.length > 0 ? "&orderStatuses=" + selectedOrderStatuses.join(",") : "&orderStatuses=" + orderStatuses.join(",")}`,
            method: "GET"
        }, clearAppContext, settlementsOnly)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )
    const [dateToTotalsMap, setDateToTotalsMap] = useState(null)

    useEffect(() => {
        let date = new Date(from)
        const endDate = new Date(to)

        let step = 1
        if (isDifferenceMoreThanXDays(180, date, endDate)) {
            step = null
        } else if (isDifferenceMoreThanXDays(40, date, endDate)) {
            step = 7
        }

        if (data && data.data) {
            const newMap = {}

            while (date.getTime() < endDate.getTime()) {
                const formatted = formatDate(date)
                if (newMap[formatted] == null) {
                    newMap[formatted] = {
                        DateCaptured: formatted,
                        actual_date: moment(date),
                        TotalSales: 0,
                        TotalPlatformFee: 0,
                        TotalCogs: 0,
                        TotalVAT: 0,
                        TotalNetProfits: 0,
                        TotalUnits: 0,
                        TotalSalesCancelled: 0,
                        TotalShippingFee: 0,
                        TotalUnitsCancelled: 0,
                        TotalAffiliateCommission: 0,
                    }
                }

                if (!step) {
                    date = getStartDayOfNextMonth(date)
                } else {
                    date.setDate(date.getDate() + step);
                }
            }

            for (const day of data.data) {
                newMap[formatDate(new Date(day["DateCaptured"]))] = day
            }

            setDateToTotalsMap(newMap)
        } else {
            const newMap = {}

            while (date.getTime() < endDate.getTime()) {
                const formatted = formatDate(date)
                if (newMap[formatted] == null) {
                    newMap[formatted] = {
                        DateCaptured: formatted,
                        actual_date: moment(date),
                        TotalSales: 0,
                        TotalPlatformFee: 0,
                        TotalCogs: 0,
                        TotalVAT: 0,
                        TotalNetProfits: 0,
                        TotalUnits: 0,
                        TotalSalesCancelled: 0,
                        TotalShippingFee: 0,
                        TotalUnitsCancelled: 0,
                        TotalAffiliateCommission: 0,
                    }
                }

                if (!step) {
                    date = getStartDayOfNextMonth(date)
                } else {
                    date.setDate(date.getDate() + step);
                }
            }

            setDateToTotalsMap(newMap)
        }

    }, [data])

    return {
        dateToTotalsMap,
        rawData: data ? data.data : [],
        isLoading,
        error
    }
}

export const useDayByDayByProductIds = (from, to, selectedShopIds, productIds, enabled = true) => {
    const {user, clearAppContext, settlementsOnly} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listAllStats', settlementsOnly, user, from, to, selectedShopIds, productIds],
        () => requestStats({
            url: `/day-by-day-stats-by-product-ids?from=${from}&to=${to}` +
                `${selectedShopIds && selectedShopIds.length > 0 ? "&shopIds=" + selectedShopIds.join(",") : ""}` +
                `${productIds && productIds.length > 0 ? "&productIds=" + productIds.join(",") : ""}`,
            method: "GET"
        }, clearAppContext, settlementsOnly)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME,  enabled: (productIds && productIds.length > 0 && enabled ? true : false)}
    )
    const [productIdToDateToTotalsMap, setProductIdToDateToTotalsMap] = useState(null)

    useEffect(() => {
        if (data && data.data) {
            const newMap = {}

            for (const productId of productIds) {
                let date = new Date(from)
                const endDate = new Date(to)

                let step = 1
                if (isDifferenceMoreThanXDays(180, date, endDate)) {
                    step = null
                } else if (isDifferenceMoreThanXDays(40, date, endDate)) {
                    step = 7
                }

                while (date.getTime() < endDate.getTime()) {
                    const formatted = formatDate(date)
                    if (!newMap[productId]){
                        newMap[productId] = {
                            [formatted]: {
                                DateCaptured: formatted,
                                TotalSales: 0,
                                TotalUnits: 0,
                                TotalAffiliateCommission: 0,
                            }
                        }
                    } else {
                        if (newMap[productId][formatted] == null) {
                            newMap[productId][formatted] = {
                                DateCaptured: formatted,
                                TotalSales: 0,
                                TotalUnits: 0,
                                TotalAffiliateCommission: 0,
                            }
                        }
                    }

                    if (!step) {
                        date = getStartDayOfNextMonth(date)
                    } else {
                        date.setDate(date.getDate() + step);
                    }
                }
            }

            for (const day of data.data) {
                if (newMap[day["ProductId"]]) {
                    newMap[day["ProductId"]][formatDate(new Date(day["DateCaptured"]))] = day
                }
            }

            setProductIdToDateToTotalsMap(newMap)
        }
    }, [data])

    return {
        productIdToDateToTotalsMap,
        rawData: data ? data.data : [],
        isLoading,
        error
    }
}

export const useExpenseStats = (from, to) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['listExpenseStats', from, to, user],
        () => request({
            url: `/expense-lines/orchestrators/stats?from=${from}&to=${to}`,
            method: "GET"
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )

    return {
        rawData: data ? data.data : null,
        isLoading,
        error
    }
}

export const useBulkRangeStats = (selectedShopIds, dates, selectedOrderStatuses, includeShippingAsCost, shippingPercentage) => {
    const {user, clearAppContext, settlementsOnly} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['bulkRangeStats', selectedShopIds, settlementsOnly, selectedOrderStatuses, dates, user],
        () => request({
            url: `/bulk-range-stats` +
                `${selectedOrderStatuses && selectedOrderStatuses.length > 0 ? "?orderStatuses=" + selectedOrderStatuses.join(",") : "?orderStatuses=" + orderStatuses.join(",")}` +
                `${selectedShopIds && selectedShopIds.length > 0 ? "&shopIds=" + selectedShopIds.join(",") : ""}`,
            method: "POST",
            data: dates
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )
    const [formattedData, setFormattedData] = useState([])

    useEffect(() => {
        if (data && data.data) {
            const newData = []
            for (const dataItem of data.data) {
                const totalVat = dataItem.TotalVAT
                const totalSales = dataItem.TotalSales
                const totalCOGS = dataItem.TotalCogs
                const affiliateComiss = dataItem.TotalAffiliateCommission ? dataItem.TotalAffiliateCommission : 0
                const platformCommiss = dataItem.TotalPlatformCommission ? dataItem.TotalPlatformCommission : 0
                const totalShippingFees = dataItem.TotalShippingFee ? dataItem.TotalShippingFee : 0
                const adjustedShippingFee = totalShippingFees - (shippingPercentage ? (shippingPercentage / 100) * totalShippingFees : 0)
                const totalExpenses = dataItem.TotalExpenses ? dataItem.TotalExpenses : 0
                const netVAT = totalVat > 0 ? (totalVat - ((totalShippingFees / 6) + (platformCommiss / 6) + (affiliateComiss / 6))) : 0
                let grossProfits = 0
                if (includeShippingAsCost) {
                    grossProfits = ((totalSales - netVAT) - (totalCOGS + adjustedShippingFee) - totalExpenses - affiliateComiss - platformCommiss)
                } else {
                    grossProfits = ((totalSales - netVAT) - (totalCOGS) - totalExpenses - affiliateComiss - platformCommiss)
                }

                const expenses = 0
                const totalNetProfits = grossProfits - expenses
                const totalRoi = totalNetProfits ? (totalNetProfits / (totalCOGS ? totalCOGS : 1) * 100) : 0

                newData.push({
                    ...dataItem,
                    TotalGrossProfits: grossProfits,
                    TotalNetProfits: totalNetProfits,
                    TotalRoi: totalRoi
                })
            }
            setFormattedData(newData)
        } else {
            setFormattedData([])
        }
    }, [data, includeShippingAsCost, shippingPercentage])

    return {
        rawData: formattedData,
        isLoading,
        error
    }
}

export const useReimbursementReportRangeStats = (includeExported, from, to) => {
    const {user, clearAppContext} = useContext(AppContext)
    const {isLoading, error, data} = useQuery(
        ['reimbursementReportStats', includeExported, from, to, user],
        () => request({
            url: `/reimbursement-report-range-stats?includeExported=${includeExported}&from=${from}&to=${to}`,
            method: "POST",
            data: null
        }, clearAppContext)
            .then((data) => data)
            .catch((error) => error),
        {staleTime: DEFAULT_STALE_TIME}
    )

    return {
        rawData: data ? data.data : {},
        isLoading,
        error
    }
}