
import moment from "moment/moment";

export const getDaysLeftByDate = (d) => {
    const currentDay = d.get("date");
    const daysInMonth = d.daysInMonth();
    const remainingDays = daysInMonth - currentDay;
    return remainingDays
}

export const datesEqualOrBaseIsGreater = (base, comparison) => {
    return (base.getFullYear() >= comparison.getFullYear() &&
            base.getDate() >= comparison.getDate() &&
            base.getMonth() >= comparison.getMonth())
}

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const getStartDayOfNextMonth = (date) => {
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    date.setHours(0, 0, 0, 0); // Optional, sets the time to 00:00:00
    return date;
}



export const getDaysInMonthByDate = (d) => {
    const daysInMonth = d.daysInMonth();
    return daysInMonth
}

export const getDaysLeft = () => {
    const d = moment();
    const currentDay = d.get("date");
    const daysInMonth = d.daysInMonth();
    const remainingDays = daysInMonth - currentDay;
    return remainingDays
}

export const getDaysInMonth = () => {
    const d = moment();
    const daysInMonth = d.daysInMonth();
    return daysInMonth
}

export const getPreviousMonthStartDate = () => {
    return moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
}

export const getCurrentMonthStartDate = () => {
    return moment().startOf('month').format("YYYY-MM-DD");
}

export const getCurrentQuarterStartDate = () => {
    return moment().startOf('quarter').format("YYYY-MM-DD");
}

export const getCurrentMonthStartDateRaw = () => {
    return moment().startOf('month');
}

export const getThreeMonthsAgoDate = () => {
    return moment().subtract(3, 'months').format("YYYY-MM-DD");
}

export const getFourYearAgoDate = () => {
    return moment().subtract(4, 'years').format("YYYY-MM-DD");
}

export const getYearAgoDate = () => {
    return moment().subtract(12, 'months').format("YYYY-MM-DD");
}

export const getCurrentYearStartDate = () => {
    return moment().startOf('year').format("YYYY-MM-DD");
}

export const getYesterday = () => {
    return moment().subtract(1, 'days').format("YYYY-MM-DD");
}

export const getPreviousMonthDate = () => {
    return moment().subtract(1, 'months').format("YYYY-MM-DD");
}

export const getMonthAgoStartDate = () => {
    return moment().subtract(1, 'months').startOf("month").format("YYYY-MM-DD");
}

export const getMonthStartDate = () => {
    return moment().startOf("month").format("YYYY-MM-DD");
}

export const getWeekAgoStartDate = () => {
    return moment().subtract(2, 'weeks').format("YYYY-MM-DD");
}

export const getTodayPlusOne = () => {
    return moment().add(1, "days").format("YYYY-MM-DD");
}

export const getStartOfToday = () => {
    return moment().startOf('day').format("YYYY-MM-DD");
}

export const getStartOfYesterday = () => {
    return moment().subtract(1, "days").startOf('day').format("YYYY-MM-DD");
}

export const getEndOfYesterday = () => {
    return moment().startOf('day').format("YYYY-MM-DD");
}

export const getStartOfWeek = () => {
    return moment().startOf('week').format("YYYY-MM-DD");
}

export const getStartOfLastWeek = () => {
    return moment().subtract(1, "weeks").startOf('week').format("YYYY-MM-DD");
}

export const getEndOfLastWeek = () => {
    return moment().subtract(1, "weeks").endOf('week').format("YYYY-MM-DD");
}

export const getTodayRaw = () => {
    return moment();
}
